import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../../../classes/utils';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-buy-payments',
  templateUrl: './buy-payments.component.html',
  styleUrls: ['./buy-payments.component.less']
})
export class BuyPaymentsComponent implements OnInit {

  id:number = 0;
  lang:string = '';
  isBrowser:boolean;
  isLoggedIn:boolean;
  orders = [];
  payments = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private userService: UserService) {
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();   
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;
        if(!_isLoggedIn){
          this.lang = this.utils.getLanguage();     
          this.router.navigate([`/${this.lang}/home`]);
        }
      });      
      this.activatedRoute.queryParams.subscribe(async params => {
        if(params['id']){
           this.id = +params['id'];
           if(this.isBrowser) this.getData();
        } else {
         this.router.navigate([`/${this.lang}/home`]);
        }
       });      
   }

  ngOnInit(): void {
   
  }

  getData(){
    this.userService.getBuyPayments(this.id).then(resp => {
      this.orders = resp.data.orders;
      this.payments = resp.data.payments;
    });
  }
}
