<div class="container" *ngIf="webPage.PageTitle">
    <div class="row">
        <div class="col-12">
            <h1 class="web-page-title">{{webPage.PageTitle}}</h1>
            <div class="web-page-content clang-{{lang}}" *ngIf="webPage.PageContent" [innerHTML]="htmlContent"></div>
            <div>

            </div>
        </div>
    </div>
</div>