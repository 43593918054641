import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../classes/utils';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';
import { config } from '../../config';
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  lang:string = '';
  isBrowser:boolean;
  settings:any = {};
  isLoggedIn:boolean;
  notificatonsCount:number = 0;
  favCount:number = 0;
  isProduction:boolean = true;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private metaTagService: Meta,
    private pageTitle: Title,
    private userService: UserService,
    private service: SharedService,
    private sanitizer: DomSanitizer) {
      this.isProduction = config.isProduction;
      this.isBrowser = this.utils.isBrowser();
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;
        if(_isLoggedIn) this.getNotificationsCount();
      });
      this.router.events.subscribe((url: NavigationEnd) => {
        if (url && url.urlAfterRedirects) {
          this.lang = this.utils.getLanguage();       
        }
        return;
      });     
   }

  ngOnInit(): void {
   if(this.isBrowser){
    $(document).click(function(event) { 
      var $target = $(event.target);
      if((!$target.parents('header').length || $target.parents('.more-services').length) && 
      $('.more-services').is(":visible")) {
        $('.more-services').hide();
      }         
    });
    let self = this;
    window['getNotificationsCount'] = function(){
      self.getNotificationsCount();
    };
    window['favChanged'] = function(){
      self.favChanged();
    }
    this.favChanged();
   }
  }

  favChanged(){
    if(this.isBrowser) this.favCount = this.utils.getFavs().length;
  }

  toggleMobileMenu(){
    if(this.isBrowser){
      // $('body').addClass('mobile-menu-expanded');
      // $('body').addClass('menu-lang-' + this.lang);
    }
  }

  changeLanguage(){
    if(this.isBrowser){
      localStorage.lang = (this.lang == 'ar' ? 'en' : 'ar');
    }
  }

  showLoginPopup(){
    if(this.isBrowser){
      window['showLoginPopup']();
    }
  }  

  getNotificationsCount(){
    if(this.isBrowser) {
      this.userService.getNotificationsCount().then(resp => {
        this.notificatonsCount = resp;
        setTimeout(() => {
          if(this.isLoggedIn) this.getNotificationsCount();
        }, 1000 * 30);
      });   
    }
  }

  toggleMoreServices(){
    if(this.isBrowser){
      $('.more-services').toggle();
    }
  }
}
