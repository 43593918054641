import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../../classes/utils';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-recieved-qoutes',
  templateUrl: './recieved-qoutes.component.html',
  styleUrls: ['./recieved-qoutes.component.less']
})
export class RecievedQoutesComponent implements OnInit {

  lang:string = '';
  isBrowser:boolean;
  isLoggedIn:boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private userService: UserService) {
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();   
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;
        if(!_isLoggedIn){
          this.lang = this.utils.getLanguage();     
          this.router.navigate([`/${this.lang}/home`]);
        }
      });
   }

  ngOnInit(): void {
    if(this.isBrowser) this.getData();
  }

  getData(){

  }
}
