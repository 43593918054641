<div class="skeleton">
    <figure class="sk-effect"></figure>
    <div class="sk-info">
        <div class="sk-title-wrapper">
            <div class="sk-title m-r-20">
                <div class="sk-title-line-1 sk-effect"></div>
                <div class="sk-title-line-2 sk-effect"></div>
            </div>
            <div class="sk-price sk-effect"></div>
        </div>
        <div class="sk-extra-info">
            <div class="sk-km sk-effect m-r-20"></div>
            <div class="sk-monthly sk-effect"></div>
        </div>
    </div>
</div>