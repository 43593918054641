<div class="main-slider">
    <div class="container">
        <div class="row">
            <div class="col-12" *ngIf="slides.length > 0">
                <div class="swiper main-swiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of slides; trackBy: trackByLoop">
                            <div class="slide-item">
                                <div class="slide-content">
                                    <div class="slide-title">
                                        {{item['SectionTitle_' + lang]}}
                                    </div>
                                    <a href="{{item['SectionLink_' + lang]}}">{{item['SectionSubTitle_' + lang]}}</a>
                                </div>
                                <div class="slide-photo">
                                    <img src="{{utils.getResizeImage(item.SectionIcon, 800)}}" alt="{{item['SectionTitle_' + lang]}}" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>   
                <div class="slider-pagination">
                    <div class="mainslider-pagination swiper-pagination"></div>   
                </div>           
            </div>
        </div>
    </div>   
</div>