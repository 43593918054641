<section id="topLux">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="top-cars-title">{{utils.getString('top_lux')}}</h1>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngFor="let car of topLux">
                        <app-lux-item [car]="car"></app-lux-item>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>