import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../classes/utils';
import { UserService } from '../../services/user.service';
import { config } from '../../config';
import * as $ from 'jquery';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.less']
})
export class AccountComponent implements OnInit {

  lang:string = '';
  isBrowser:boolean;
  settings:any = {};
  isLoggedIn:boolean;
  notificatonsCount:number = 0;
  favsCount:number = 0;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private userService: UserService) {
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();   
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;
        if(!_isLoggedIn){
          this.lang = this.utils.getLanguage();     
          this.router.navigate([`/${this.lang}/home`]);
        } else {
          this.getNotificationsCount();
          this.favsCount = this.utils.getFavs().length;
        }
      });  
   }

  ngOnInit(): void {
    let self = this;
    window['getNotificationsCount'] = function(){
      self.getNotificationsCount();
    };
  }

  toggleAccountNav(){
    if(this.isBrowser) $('.account-nav').slideToggle(300);
  }

  getNotificationsCount(){
    if(this.isBrowser) {
      this.userService.getNotificationsCount().then(resp => {        
        this.notificatonsCount = resp;        
      });   
    }
  }
}
