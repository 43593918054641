<div class="car-view-page">
    <div class="container" *ngIf="car.car.length == 1">
        <div class="row">
            <div class="col-12">
                <div class="car-breadcrumbs">
                    <a href="#" routerLink="/{{lang}}/home">
                        {{utils.getString('cars_home')}}
                    </a>
                    <i class="fal" [class.fa-chevron-right]="lang == 'en'" [class.fa-chevron-left]="lang == 'ar'"></i>
                    <a href="#" routerLink="/{{lang}}/used-cars">
                        {{utils.getString('buy_used_cars')}}
                    </a>
                    <i class="fal" [class.fa-chevron-right]="lang == 'en'" [class.fa-chevron-left]="lang == 'ar'"></i>
                    <a href="#" routerLink="/{{lang}}/used-cars" [queryParams]="{CITY: car.car[0].CityID}">
                        {{car.car[0]['CityName_' + lang]}}
                    </a>
                    <i class="fal" [class.fa-chevron-right]="lang == 'en'" [class.fa-chevron-left]="lang == 'ar'"></i>
                    <a href="#" routerLink="/{{lang}}/used-cars" [queryParams]="{MMID: car.car[0].MKID}">
                        {{car.car[0]['MakeName_' + lang]}}
                    </a>
                    <i class="fal" [class.fa-chevron-right]="lang == 'en'" [class.fa-chevron-left]="lang == 'ar'"></i>
                    <a href="#" routerLink="/{{lang}}/used-cars" [queryParams]="{MMID: car.car[0].MMID}">
                        {{car.car[0]['ModelName_' + lang]}}
                    </a>
                </div>
                <h1 class="car-ad-title">{{car.car[0]['AdTitle_' + lang]}}</h1>        
                <div class="posted-on">
                    {{utils.getString('vc_posted')}}: {{car.car[0].AdDateDesc}}
                </div>
            </div>
            <div class="col-lg-8 col-12">                       
                <div class="car-photos-slider">
                    <div class="swiper car-photos-swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let item of car.photos; let i = index">
                                <div class="car-slide-photo">
                                    <div class="sold-car" *ngIf="isSold">{{utils.getString('sold')}}</div>
                                    <img [class.sold-img]="isSold" [attr.data-src]="item.photo" alt="{{car.car[0]['AdTitle_' + lang]}}" class="swiper-lazy" src="../../../assets/images/lazy-car.png" (click)="openOriginalSlider(i)" />
                                    <div class="view-360" *ngIf="item.is360 && !isSold" (click)="play360()">
                                        <button aria-label="show 360 photo">
                                            <i class="fas fa-play-circle"></i>
                                            <span>360&#176;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>   
                </div>     
                <div class="car-photos-thumbs">
                    <div class="swiper car-thumbs-swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let item of car.photos">
                                <div class="car-thumn-photo">
                                    <img [class.sold-img]="isSold" [attr.data-src]="item.photo" alt="{{car.car[0]['AdTitle_' + lang]}}" class="swiper-lazy" src="../../../assets/images/lazy-car.png" />
                                    <div class="view-360" *ngIf="item.is360 && !isSold">
                                        <button aria-label="show 360 photo" (click)="play360()">
                                            <i class="fas fa-play-circle"></i>
                                            <span>360&#176;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>      
                <div class="car-key-information">
                    <div class="key-info">
                        <i class="fal fa-calendar-alt"></i><span class="m-l-5">{{car.car[0].CYear}}</span>
                    </div>
                    <i class="fas fa-circle"></i>
                    <div class="key-info">
                        <i class="fal fa-tachometer-alt-fast"></i><span class="m-l-5">{{car.car[0].Milage | number}} {{utils.getString('km')}}</span>
                    </div>
                    <div class="ad-id m-l-auto">ID #{{car.car[0].CarID}}</div>                                        
                </div>
                <div class="car-location-info">
                    <i class="fal fa-map-marker-check"></i><span class="m-l-5"><span *ngIf="car.car[0]['AreaName_' + lang]">{{car.car[0]['AreaName_' + lang]}}, </span>{{car.car[0]['CityName_' + lang]}}</span>
                </div>
                <div class="car-pricing">
                    <div class="car-the-price">
                        <div class="car-item-price">
                            <span *ngIf="lang == 'en'">{{utils.getString('cur')}} {{car.car[0].Price | number}}</span>
                            <span *ngIf="lang == 'ar'">{{car.car[0].Price | number}} {{utils.getString('cur')}}</span>
                            <div style="line-height: 1;" *ngIf="car.car[0].PriceDropped">
                                <div class="price-drop"><b>{{car.car[0].DropRate}}%</b> {{utils.getString('price_drop')}}</div>
                            </div>
                        </div>
                        <button aria-label="Track Car Price Drop" *ngIf="!isSold" class="track-price m-l-auto" [class.tracked]="this.car.trackPrice.length == 1" (click)="startPriceTracking()"><i class="fal fa-bells"></i><span class="m-l-5">{{utils.getString('vc_track_price')}}</span></button>
                    </div>
                    <div class="car-month-price" *ngIf="car.car[0].MonthlyPrice > 0">
                        <span *ngIf="lang == 'en'">{{utils.getString('cur')}} {{car.car[0].MonthlyPrice | number}} / {{utils.getString('month')}}</span>
                        <span *ngIf="lang == 'ar'">{{car.car[0].MonthlyPrice | number}} {{utils.getString('cur')}} / {{utils.getString('month')}}</span>                        
                        <div class="down-payment" *ngIf="car.car[0].DownPayment > 0">{{utils.getString('vc_down_paymeny')}}: {{utils.getString('cur')}} {{car.car[0].DownPayment | number}}</div>
                    </div>
                </div>                
            </div>
            <div class="col-lg-4 col-12 large-column">
                <div class="fav-share-actions">
                    <a href="{{car.shareLink}}" target="_blank" class="share-car m-r-20">
                        <i class="fal fa-share-square m-r-5"></i>
                        <span class="fs-label" *ngIf="lang == 'en'">
                            <span class="lrg">Share</span>
                            <span class="sml">on WhatsApp</span>
                        </span>
                        <span class="fs-label" *ngIf="lang == 'ar'">
                            <span class="lrg">مشاركة</span>
                            <span class="sml">على واتساب</span>
                        </span>
                    </a>
                    <button class="share-car" (click)="addToFavourites()">
                        <i class="fal fa-heart m-r-5" [class.fas]="car.fav"></i>
                        <span class="fs-label" *ngIf="lang == 'en'">
                            <span class="sml">add to</span>
                            <span class="lrg">Favourites</span>
                        </span>
                        <span class="fs-label" *ngIf="lang == 'ar'">
                            <span class="sml">اضافة الى</span>
                            <span class="lrg">المفضلة</span>
                        </span>
                    </button>
                </div>
                <div class="am-interested" *ngIf="!isSold">
                    <h3 class="interested">{{utils.getString('vc_interested')}}</h3>
                    <div class="car-actions">
                        <button aria-label="Show Phone Number" class="showphone" *ngIf="car.car[0].MobileNumber && !car.ShowPhone" (click)="car.ShowPhone = true">{{utils.getString('vc_show_phone_number')}}</button>
                        <a href="tel:{{car.car[0].MobileNumber}}" target="_blank" *ngIf="car.car[0].MobileNumber && car.ShowPhone">
                            <span class="plain-text" *ngIf="car.car[0].MobType == 'mob'">{{car.car[0].MobileNumber}}</span>
                            <span class="plain-text" *ngIf="car.car[0].MobType == 'dis'">{{car.car[0].MobileToDisplay}}</span>
                        </a>
                        <button aria-label="Test Drive" (click)="testDrive()" [disabled]="isLoading" class="enquire" *ngIf="car.car[0].SellBy == 1">{{utils.getString('vc_test_drive')}}</button>
                        <button aria-label="Reserve Car" (click)="showDepositDialog = true" [disabled]="isLoading" class="reserve" *ngIf="car.car[0].SellBy == 1">{{utils.getString('vc_reserve_car')}} 
                            <span class="reserve-price">
                                (
                                <span *ngIf="lang == 'en'">{{utils.getString('cur')}} {{car.car[0].ReservePrice | number}}</span>
                                <span *ngIf="lang == 'ar'">{{car.car[0].ReservePrice | number}} {{utils.getString('cur')}}</span>
                                )
                            </span>
                        </button>
                        <button *ngIf="car.car[0].SellBy == 1" (click)="startOffer()" aria-label="Make an Offer" [disabled]="isLoading" class="offer">{{utils.getString('vc_make_an_offer')}}</button>                        
                        <button *ngIf="car.car[0].CanBeSoldOnline" (click)="buyCarOnline()" aria-label="Buy Car Online" [disabled]="isLoading" class="offer buy-online">{{utils.getString('vc_buy_online')}}</button>                        
                    </div>
                </div>
                <div class="am-interested">
                    <h3 class="interested">{{utils.getString('need_help')}}</h3>
                    <div class="car-actions">                        
                        <a href="#" routerLink="/{{lang}}/hire-an-expert" target="_blank">{{utils.getString('hire_an_expert')}}</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-12 push-down">
                <div class="additional-info">
                    <h4>{{utils.getString('vc_additional_info')}}</h4>
                    <div class="add-info" *ngIf="car.car[0].Class">
                        <b *ngIf="lang == 'en'">Trim</b>
                        <b *ngIf="lang == 'ar'">الفئة</b>
                        <span>{{car.car[0].Class}}</span>
                    </div>
                    <div class="add-info">
                        <b *ngIf="lang == 'en'">Seller</b>
                        <b *ngIf="lang == 'ar'">البائع</b>
                        <span>{{car.car[0]['SellerType_' + lang]}}</span>
                    </div>
                    <div class="add-info" *ngIf="car._Specs.length == 1">
                        <b *ngIf="lang == 'en'">Regional Specs</b>
                        <b *ngIf="lang == 'ar'">المواصفات الإقليمية</b>
                        <span>{{car._Specs[0]['LookItem_' + lang]}}</span>
                    </div>
                    <div class="add-info" *ngIf="car._BodyType.length == 1">
                        <b *ngIf="lang == 'en'">Body Type</b>
                        <b *ngIf="lang == 'ar'">نوع الجسم</b>
                        <span>{{car._BodyType[0]['LookItem_' + lang]}}</span>
                    </div>
                    <div class="add-info" *ngIf="car.car[0].Engine && car.car[0].Engine != ' '">
                        <b *ngIf="lang == 'en'">Engine</b>
                        <b *ngIf="lang == 'ar'">حجم المحرك</b>
                        <span>{{car.car[0].Engine}}</span>
                    </div>
                    <div class="add-info" *ngIf="car.car[0].Cylinders > 0">
                        <b *ngIf="lang == 'en'">Cylinders</b>
                        <b *ngIf="lang == 'ar'">عدد الإسطوانات</b>
                        <span>{{car.car[0].Cylinders}}</span>
                    </div>
                    <div class="add-info" *ngIf="car._Fuel.length == 1">
                        <b *ngIf="lang == 'en'">Fuel</b>
                        <b *ngIf="lang == 'ar'">نوع الوقود</b>
                        <span>{{car._Fuel[0]['LookItem_' + lang]}}</span>
                    </div>
                    <div class="add-info" *ngIf="car._Transmission.length == 1">
                        <b *ngIf="lang == 'en'">Transmission</b>
                        <b *ngIf="lang == 'ar'">ناقل الحركة</b>
                        <span>{{car._Transmission[0]['LookItem_' + lang]}}</span>                        
                    </div>
                    <div class="add-info" *ngIf="car.car[0].VIN">
                        <b *ngIf="lang == 'en'">VIN / Chassis Number</b>
                        <b *ngIf="lang == 'ar'">رقم القاعدة</b>
                        <span>{{car.car[0].VIN}}<button class="copy-info m-l-5" (click)="copyCode()"><i class="fal fa-copy"></i></button></span>                        
                        <!-- <span *ngIf="car.car[0].Specs != 2">{{car.car[0].VIN}}</span>
                        <span *ngIf="car.car[0].Specs == 2">
                            <a href="https://en.bidfax.info/?do=search&subaction=search&story=+{{car.car[0].VIN}}" target="_blank">{{car.car[0].VIN}}</a>
                        </span> -->
                    </div>
                    <div class="add-info" *ngIf="car._RoofType.length == 1 && car.ShowAllInfo">
                        <b *ngIf="lang == 'en'">Roof Type</b>
                        <b *ngIf="lang == 'ar'">نوع السقف</b>
                        <span>{{car._RoofType[0]['LookItem_' + lang]}}</span>
                    </div>
                    <div class="add-info" *ngIf="car._Seats.length == 1 && car.ShowAllInfo">
                        <b *ngIf="lang == 'en'">Seats</b>
                        <b *ngIf="lang == 'ar'">المقاعد</b>
                        <span>{{car._Seats[0]['LookItem_' + lang]}}</span>
                    </div>
                    <div class="add-info" *ngIf="car.car[0].Doors > 0 && car.ShowAllInfo">
                        <b *ngIf="lang == 'en'">Doors</b>
                        <b *ngIf="lang == 'ar'">الأبواب</b>
                        <span>{{car.car[0].Doors}}</span>
                    </div>
                    <div class="add-info" *ngIf="car._DriveType.length == 1 && car.ShowAllInfo">
                        <b *ngIf="lang == 'en'">Drive Type</b>
                        <b *ngIf="lang == 'ar'">نوع الدفع</b>
                        <span>{{car._DriveType[0]['LookItem_' + lang]}}</span>
                    </div>
                    <div class="add-info" *ngIf="car._ServiceHistory.length == 1 && car.ShowAllInfo">
                        <b *ngIf="lang == 'en'">Service History</b>
                        <b *ngIf="lang == 'ar'">سجّل الصيانة</b>
                        <span>{{car._ServiceHistory[0]['LookItem_' + lang]}}</span>
                    </div>
                    <div class="add-info" *ngIf="car._Warranty.length == 1 && car.ShowAllInfo">
                        <b *ngIf="lang == 'en'">Warranty</b>
                        <b *ngIf="lang == 'ar'">الضمان</b>
                        <span>{{car._Warranty[0]['LookItem_' + lang]}}</span>
                    </div>
                    <div class="add-info" *ngIf="car.car[0].RimSize > 0 && car.ShowAllInfo">
                        <b *ngIf="lang == 'en'">Rim Size</b>
                        <b *ngIf="lang == 'ar'">مقاس الجنط</b>
                        <span>{{car.car[0].RimSize}}</span>
                    </div>
                    <div class="add-info" *ngIf="car.car[0].FirstOwner && car.ShowAllInfo">
                        <b *ngIf="lang == 'en'">First Owner</b>
                        <b *ngIf="lang == 'ar'">مالك أول</b>
                        <span><i class="fas fa-check-circle"></i></span>
                    </div>
                    <div class="add-info" *ngIf="car.car[0].NeedMaintenance && car.ShowAllInfo">
                        <b>{{utils.getString('fl_need_maintenance')}}</b>
                        <span *ngIf="lang == 'en'">YES</span>
                        <span *ngIf="lang == 'ar'">نعم</span>
                    </div>
                    <div class="add-info" *ngIf="car.ShowAllInfo">
                        <b *ngIf="lang == 'en'">Negotiable</b>
                        <b *ngIf="lang == 'ar'">قابل للتفاوض</b>
                        <span *ngIf="car.car[0].Negotiable && lang == 'en'">YES</span>
                        <span *ngIf="car.car[0].Negotiable && lang == 'ar'">نعم</span>
                        <span *ngIf="!car.car[0].Negotiable && lang == 'en'">NO</span>
                        <span *ngIf="!car.car[0].Negotiable && lang == 'ar'">لا</span>
                    </div>
                    <div class="add-info" *ngIf="car.car[0].CurrentlyFinanced && car.ShowAllInfo">
                        <b *ngIf="lang == 'en'">Currently Financed</b>
                        <b *ngIf="lang == 'ar'">خاضعة للتمويل حاليا</b>
                        <span><i class="fas fa-check-circle"></i></span>
                    </div>
                    <div class="add-info" *ngIf="car.car[0].NotVerifiedAccidents > 0 && car.ShowAllInfo">
                        <b *ngIf="lang == 'en'">Not Verified Accidents</b>
                        <b *ngIf="lang == 'ar'">حوادث غير موثقة</b>
                        <span>{{car.car[0].NotVerifiedAccidents}}</span>
                    </div>
                    <div class="add-info" *ngIf="car.car[0].NoOfKeys > 0 && car.ShowAllInfo">
                        <b *ngIf="lang == 'en'">No Of Keys</b>
                        <b *ngIf="lang == 'ar'">عدد المفاتيح</b>
                        <span>{{car.car[0].NoOfKeys}}</span>
                    </div>
                    <button aria-label="Show Car Information" class="view-all-add-info" *ngIf="!car.ShowAllInfo" (click)="car.ShowAllInfo = true">{{utils.getString('vc_view_all_additional_info')}}</button>
                </div>                
            </div>
            <div class="col-lg-8 col-12 push-down" *ngIf="
            (car._features_Driving | kzFilter:{HasFeature: 1}).length
            +
            (car._features_Entertainment | kzFilter:{HasFeature: 1}).length
            +
            (car._features_Comfort | kzFilter:{HasFeature: 1}).length
            + 
            (car._features_Safety | kzFilter:{HasFeature: 1}).length > 0
            ">
                <h4 class="features-title">{{utils.getString('vc_features')}}</h4>                
                <div class="car-features">
                    <div class="car-feature" *ngFor="let item of car._features_Driving | kzFilter:{HasFeature: 1}">
                        <img class="feature-icon" src="{{utils.getImage(item.LookIcon, 64, 64)}}" alt="{{item['LookItem_' + lang]}}" />
                        <div class="feature-name">{{item['LookItem_' + lang]}}</div>
                    </div>
                    <div class="car-feature" *ngFor="let item of car._features_Entertainment | kzFilter:{HasFeature: 1}">
                        <img class="feature-icon" src="{{utils.getImage(item.LookIcon, 64, 64)}}" alt="{{item['LookItem_' + lang]}}" />
                        <div class="feature-name">{{item['LookItem_' + lang]}}</div>
                    </div>
                    <div class="car-feature" *ngFor="let item of car._features_Comfort | kzFilter:{HasFeature: 1}">
                        <img class="feature-icon" src="{{utils.getImage(item.LookIcon, 64, 64)}}" alt="{{item['LookItem_' + lang]}}" />
                        <div class="feature-name">{{item['LookItem_' + lang]}}</div>
                    </div>
                    <div class="car-feature" *ngFor="let item of car._features_Safety | kzFilter:{HasFeature: 1}">
                        <img class="feature-icon" src="{{utils.getImage(item.LookIcon, 64, 64)}}" alt="{{item['LookItem_' + lang]}}" />
                        <div class="feature-name">{{item['LookItem_' + lang]}}</div>
                    </div>                    
                </div>
                <button aria-label="Show Car Features" class="view-all-add-info" (click)="car.ShowAllFeatures = true">{{utils.getString('vc_view_all_features')}}</button>
            </div>
            <div class="col-lg-8 col-12" *ngIf="car.car[0].IsInspected && ((car._CarInspection | kzFilter: {TestResult: 0}).length > 0 || (car._CarInspection | kzFilter: {TestResult: 1}).length > 0)">
                <div class="inspection-report">
                    <div class="row">
                        <div class="col-12">
                            <div class="inspection-title">{{utils.getString('inspection_report')}}</div>
                            <div class="ins-guide">
                                <span>
                                    <i class="fas fa-check-circle"></i>
                                    <span *ngIf="lang == 'en'">Passed</span>
                                    <span *ngIf="lang == 'ar'">نجح</span>
                                </span>
                                <span class="m-r-10 m-l-10">
                                    <i class="fas fa-exclamation-circle"></i>
                                    <span *ngIf="lang == 'en'">Not Passed</span>
                                    <span *ngIf="lang == 'ar'">لم ينجح</span>
                                </span>
                                <span>
                                    <i class="fas fa-minus-circle"></i>
                                    <span *ngIf="lang == 'en'">Not Checked</span>
                                    <span *ngIf="lang == 'ar'">لم يتم الفحص</span>
                                </span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="inspection-categories">
                                <div class="ins-cat" *ngFor="let item of car._InspectionCategories">
                                    <div class="ins-cat-title">
                                        <div>
                                            <div class="the-title">
                                                {{item['InspectCatName_' + lang]}}
                                            </div>
                                            <div class="ins-summary">
                                                <span class="ins-i">
                                                    <i class="fas fa-check-circle"></i>
                                                    <span class="m-l-3">
                                                        {{(car._CarInspection | kzFilter: {InspectionType: item.InspectionType, TestResult: 1}).length}}
                                                    </span>
                                                </span>
                                                <span class="ins-i m-r-5 m-l-5">
                                                    <i class="fas fa-exclamation-circle"></i>
                                                    <span class="m-l-3">
                                                        {{(car._CarInspection | kzFilter: {InspectionType: item.InspectionType, TestResult: 0}).length}}
                                                    </span>
                                                </span>
                                                <span class="ins-i">
                                                    <i class="fas fa-minus-circle"></i>
                                                    <span class="m-l-3">
                                                        {{(car._CarInspection | kzFilter: {InspectionType: item.InspectionType, TestResult: -1}).length}}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <button class="expand-report m-l-auto" (click)="item.expanded = !item.expanded">
                                            <i class="fal" [class.fa-chevron-double-down]="!item.expanded" [class.fa-chevron-double-up]="item.expanded"></i>
                                        </button>
                                    </div>   
                                    <div *ngIf="item.expanded">
                                        <div class="report-desc">
                                            {{item['InspectCatDesc_' + lang]}}
                                        </div>
                                        <div class="ins-item" 
                                        (click)="viewIns(ins)"
                                        *ngFor="let ins of car._CarInspection | kzFilter: {InspectionType: item.InspectionType}">
                                            <div class="insitem-label">
                                                {{ins['InspectItem_' + lang]}}
                                            </div>
                                            <i class="fas m-l-auto" 
                                            [class.fa-check-circle]="ins.TestResult == 1"
                                            [class.fa-minus-circle]="ins.TestResult == -1"
                                            [class.fa-exclamation-circle]="ins.TestResult == 0"></i>
                                        </div>
                                    </div>                                 
                                </div>
                                <div class="ins-cat" *ngIf="car._CarScratches.length > 0">
                                    <div class="ins-cat-title">
                                        <div>
                                            <div class="the-title">
                                                <span *ngIf="lang == 'en'">Scratches and Defects</span>
                                                <span *ngIf="lang == 'ar'">الخدوش والصدمات</span>      
                                            </div>
                                            <div class="ins-summary">                                                
                                                <span class="ins-i">
                                                    <i class="fas fa-claw-marks"></i>
                                                    <span class="m-l-3">
                                                        {{car._CarScratches.length}}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <button class="expand-report m-l-auto" (click)="showScratches = !showScratches">
                                            <i class="fal" [class.fa-chevron-double-down]="!showScratches" [class.fa-chevron-double-up]="showScratches"></i>
                                        </button>
                                    </div>   
                                    <div *ngIf="showScratches">
                                        <div class="car-scratches" *ngIf="(car._CarScratches | kzFilter: {ScratchType: 1}).length > 0">
                                            <div class="scratch-type">
                                                <span *ngIf="lang == 'en'">Interior</span>
                                                <span *ngIf="lang == 'ar'">داخلي</span> 
                                            </div>
                                            <div class="scratches-list">
                                                <div *ngFor="let sc of car._CarScratches | kzFilter: {ScratchType: 1}" class="the-scratches">
                                                    <img src="{{scratchImage(sc.ScratchPhoto)}}" alt="Scratches and Defects" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="car-scratches" *ngIf="(car._CarScratches | kzFilter: {ScratchType: 2}).length > 0">
                                            <div class="scratch-type">
                                                <span *ngIf="lang == 'en'">Front & Rear</span>
                                                <span *ngIf="lang == 'ar'">الجزء الأمامى والخلفى</span> 
                                            </div>
                                            <div class="scratches-list">
                                                <div *ngFor="let sc of car._CarScratches | kzFilter: {ScratchType: 2}" class="the-scratches">
                                                    <img src="{{scratchImage(sc.ScratchPhoto)}}" alt="Scratches and Defects" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="car-scratches" *ngIf="(car._CarScratches | kzFilter: {ScratchType: 3}).length > 0">
                                            <div class="scratch-type">
                                                <span *ngIf="lang == 'en'">Sides</span>
                                                <span *ngIf="lang == 'ar'">الجوانب</span> 
                                            </div>
                                            <div class="scratches-list">
                                                <div *ngFor="let sc of car._CarScratches | kzFilter: {ScratchType: 3}" class="the-scratches">
                                                    <img src="{{scratchImage(sc.ScratchPhoto)}}" alt="Scratches and Defects" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="car-scratches" *ngIf="(car._CarScratches | kzFilter: {ScratchType: 4}).length > 0">
                                            <div class="scratch-type">
                                                <span *ngIf="lang == 'en'">Tires & Rims</span>
                                                <span *ngIf="lang == 'ar'">الجنوط والإطارات</span> 
                                            </div>
                                            <div class="scratches-list">
                                                <div *ngFor="let sc of car._CarScratches | kzFilter: {ScratchType: 4}" class="the-scratches">
                                                    <img src="{{scratchImage(sc.ScratchPhoto)}}" alt="Scratches and Defects" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                 
                                </div>
                            </div>                        
                        </div>                    
                    </div>
                </div>                
            </div>
            <div class="col-lg-8 col-12" *ngIf="car.blog.length > 0">
                <div class="row">
                    <div class="col-12">
                        <div class="blog-title">{{utils.getString('about_this_car')}}</div>
                    </div>
                    <div class="col-12">
                        <ul>
                            <li *ngFor="let item of car.blog" class="blog-link">
                                <a href="#" routerLink="/{{lang}}/blog/article/{{item.ArticleID}}">{{item['ArticleTitle_' + lang]}}</a>
                            </li>
                        </ul>                        
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-12" *ngIf="car.related && car.related.length > 0">
                <div class="row">
                    <div class="col-12">
                        <div class="similar-title">{{utils.getString('similar_cars')}}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 col-12" *ngFor="let item of car.related">
                        <app-car-item [car]="item"></app-car-item>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="featuresModal" class="kz-modal" *ngIf="car.ShowAllFeatures">
    <div class="kz-modal-overlay" (click)="car.ShowAllFeatures = false"></div>
    <div class="kz-modal-dialog">
      <div class="kz-modal-header">   
        <div class="kz-modal-title">{{utils.getString('vc_view_all_features')}}</div>   
        <button class="kz-close-modal m-l-auto" (click)="car.ShowAllFeatures = false"><i class="fal fa-times"></i></button>
      </div>
      <div class="kz-modal-body">
           <div class="features-category">
                <div class="fc-title">
                    <span *ngIf="lang == 'en'">Driving</span>
                    <span *ngIf="lang == 'ar'">مزايا القيادة</span>
                    <span class="m-l-5">
                        ({{(car._features_Driving | kzFilter:{HasFeature: 1}).length}} / {{car._features_Driving.length}})
                    </span>
                </div>
                <div class="car-features">
                    <div class="car-feature" *ngFor="let item of car._features_Driving" [class.no-feature]="!item.HasFeature">
                        <img class="feature-icon" src="{{utils.getImage(item.LookIcon, 64, 64)}}" alt="{{item['LookItem_' + lang]}}" />
                        <div class="feature-name">{{item['LookItem_' + lang]}}</div>
                    </div>
                </div>
           </div>
           <div class="features-category">
                <div class="fc-title">
                    <span *ngIf="lang == 'en'">Entertainment</span>
                    <span *ngIf="lang == 'ar'">الترفيه</span>
                    <span class="m-l-5">
                        ({{(car._features_Entertainment | kzFilter:{HasFeature: 1}).length}} / {{car._features_Entertainment.length}})
                    </span>
                </div>
                <div class="car-features">
                    <div class="car-feature" *ngFor="let item of car._features_Entertainment" [class.no-feature]="!item.HasFeature">
                        <img class="feature-icon" src="{{utils.getImage(item.LookIcon, 64, 64)}}" alt="{{item['LookItem_' + lang]}}" />
                        <div class="feature-name">{{item['LookItem_' + lang]}}</div>
                    </div>
                </div>
            </div>
           <div class="features-category">
            <div class="fc-title">
                <span *ngIf="lang == 'en'">Confort</span>
                <span *ngIf="lang == 'ar'">وسائل الراحة</span>
                <span class="m-l-5">
                    ({{(car._features_Comfort | kzFilter:{HasFeature: 1}).length}} / {{car._features_Comfort.length}})
                </span>
            </div>
            <div class="car-features">
                <div class="car-feature" *ngFor="let item of car._features_Comfort" [class.no-feature]="!item.HasFeature">
                    <img class="feature-icon" src="{{utils.getImage(item.LookIcon, 64, 64)}}" alt="{{item['LookItem_' + lang]}}" />
                    <div class="feature-name">{{item['LookItem_' + lang]}}</div>
                </div>
            </div>
        </div>
        <div class="features-category">
            <div class="fc-title">
                <span *ngIf="lang == 'en'">Safety</span>
                <span *ngIf="lang == 'ar'">مزايا الأمان</span>
                <span class="m-l-5">
                    ({{(car._features_Comfort | kzFilter:{HasFeature: 1}).length}} / {{car._features_Comfort.length}})
                </span>
            </div>
            <div class="car-features">
                <div class="car-feature" *ngFor="let item of car._features_Comfort" [class.no-feature]="!item.HasFeature">
                    <img class="feature-icon" src="{{utils.getImage(item.LookIcon, 64, 64)}}" alt="{{item['LookItem_' + lang]}}" />
                    <div class="feature-name">{{item['LookItem_' + lang]}}</div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>

  <div id="panoModal" class="kz-modal" *ngIf="show360">
    <div class="kz-modal-overlay" (click)="show360 = false"></div>
    <div class="kz-modal-dialog">
      <div class="kz-modal-header">   
        <div class="kz-modal-title">360&#176;</div>   
        <button class="kz-close-modal m-l-auto" (click)="show360 = false"><i class="fal fa-times"></i></button>
      </div>
      <div class="kz-modal-body no-padding">
           <iframe class="pano" [src]="photo360" [frameBorder]="0" [scrolling]="1"></iframe>
      </div>
    </div>
  </div>  

  <div id="priceTrackModal" class="kz-modal" *ngIf="showPriceTrack">
    <div class="kz-modal-overlay" (click)="showPriceTrack = false"></div>
    <div class="kz-modal-dialog">
      <div class="kz-modal-header">   
        <div class="kz-modal-title">{{utils.getString('vc_track_price')}}</div>   
        <button class="kz-close-modal m-l-auto" (click)="showPriceTrack = false"><i class="fal fa-times"></i></button>
      </div>
      <div class="kz-modal-body">
           <div class="kz-modal-form">
            <div class="car-card">
                <img src="{{utils.getImage(car.car[0].MainPhoto, 600, 450)}}" alt="{{car.car[0]['AdTitle_' + lang]}}" />
                <div class="car-card-info">
                    <div class="the-cc-title">{{car.car[0]['AdTitle_' + lang]}}</div>
                    <div class="card-location"><i class="fal fa-map-marker-check"></i><span class="m-l-5"><span *ngIf="car.car[0]['AreaName_' + lang]">{{car.car[0]['AreaName_' + lang]}}, </span>{{car.car[0]['CityName_' + lang]}}</span></div>
                    <div class="the-cc-price">
                        <span *ngIf="lang == 'en'">{{utils.getString('cur')}} {{car.car[0].Price | number}}</span>
                        <span *ngIf="lang == 'ar'">{{car.car[0].Price | number}} {{utils.getString('cur')}}</span>
                    </div>
                </div>
            </div>
            <div class="notify-me">
                <span *ngIf="lang == 'en'">Notify me for</span>
                <span *ngIf="lang == 'ar'">أرسل لي تنبيه لـ</span>
            </div>
            <div class="drop-options">
                <div class="drop-opt m-r-15" [class.active]="targetPrice == 0" (click)="targetPrice = 0">
                    <span *ngIf="lang == 'en'">Any price drop</span>
                    <span *ngIf="lang == 'ar'">أي انخفاض في السعر</span>
                </div>
                <div class="drop-opt" [class.active]="targetPrice > 0" (click)="targetPrice = defaultTarget">
                    <span *ngIf="lang == 'en'">Price drop below</span>
                    <span *ngIf="lang == 'ar'">انخفاض السعر لأقل من</span>
                </div>
            </div>
            <div class="gc-field" *ngIf="targetPrice > 0">
                <label class="gc-label">{{utils.getString('enter_amount')}}</label>
                <div class="gc-input">
                  <input type="number" placeholder="{{utils.getString('enter_amount')}}" [(ngModel)]="targetPrice" [min]="minPrice" [max]="maxPrice" />
                </div>
            </div>  
            <button class="save-action" (click)="trackPrice()" [disabled]="isLoading">{{utils.getString('notify_me')}}</button>
           </div>
      </div>
    </div>
  </div>  

  <div id="reserveModal" class="kz-modal" *ngIf="showDepositDialog">
    <div class="kz-modal-overlay" (click)="showDepositDialog = false"></div>
    <div class="kz-modal-dialog">
      <div class="kz-modal-header">   
        <div class="kz-modal-title">{{utils.getString('vc_reserve_car')}}</div>   
        <button class="kz-close-modal m-l-auto" (click)="showDepositDialog = false"><i class="fal fa-times"></i></button>
      </div>
      <div class="kz-modal-body">
           <div class="kz-modal-form">
            <div class="car-card">
                <img src="{{utils.getImage(car.car[0].MainPhoto, 600, 450)}}" alt="{{car.car[0]['AdTitle_' + lang]}}" />
                <div class="car-card-info">
                    <div class="the-cc-title">{{car.car[0]['AdTitle_' + lang]}}</div>
                    <div class="card-location"><i class="fal fa-map-marker-check"></i><span class="m-l-5"><span *ngIf="car.car[0]['AreaName_' + lang]">{{car.car[0]['AreaName_' + lang]}}, </span>{{car.car[0]['CityName_' + lang]}}</span></div>
                    <div class="the-cc-price">
                        <span *ngIf="lang == 'en'">{{utils.getString('cur')}} {{car.car[0].Price | number}}</span>
                        <span *ngIf="lang == 'ar'">{{car.car[0].Price | number}} {{utils.getString('cur')}}</span>
                    </div>
                </div>
            </div>
            <div class="deposit-info">
                <div *ngIf="lang == 'en'">
                    Pay <b>{{utils.getString('cur')}} {{car.car[0].ReservePrice | number}}</b> deposit to reserve this car for 7 days 
                </div>
                <div *ngIf="lang == 'ar'">
                    إدفع <b>{{car.car[0].ReservePrice | number}} {{utils.getString('cur')}} </b> لحجز هذه السيارة لمدة 7 أيام 
                </div>
            </div>  
            <div class="deposit-refund">
                <div class="dep-ref-title" *ngIf="lang == 'en'">
                    The deposit is fully refundable ONLY if
                </div>
                <div class="dep-ref-title" *ngIf="lang == 'ar'">
                    الوديعة قابلة للاسترداد بالكامل فقط إذا
                </div>
                <ul>
                    <li *ngFor="let ref of depositRefundPolicy">
                        {{ref['SectionTitle_' + lang]}}
                    </li>
                </ul>
            </div>           
            <button class="save-action" (click)="reserveCar()" [disabled]="isLoading">{{utils.getString('confirm_and_pay')}}</button>
           </div>
      </div>
    </div>
  </div> 

  <div id="testDriveModal" class="kz-modal" *ngIf="showTestDrive">
    <div class="kz-modal-overlay" (click)="showTestDrive = false"></div>
    <div class="kz-modal-dialog">
      <div class="kz-modal-header">   
        <div class="kz-modal-title">{{utils.getString('vc_test_drive')}}</div>   
        <button class="kz-close-modal m-l-auto" (click)="showTestDrive = false"><i class="fal fa-times"></i></button>
      </div>
      <div class="kz-modal-body">
           <div class="kz-modal-form">
            <div class="car-card">
                <img src="{{utils.getImage(car.car[0].MainPhoto, 600, 450)}}" alt="{{car.car[0]['AdTitle_' + lang]}}" />
                <div class="car-card-info">
                    <div class="the-cc-title">{{car.car[0]['AdTitle_' + lang]}}</div>
                    <div class="card-location"><i class="fal fa-map-marker-check"></i><span class="m-l-5"><span *ngIf="car.car[0]['AreaName_' + lang]">{{car.car[0]['AreaName_' + lang]}}, </span>{{car.car[0]['CityName_' + lang]}}</span></div>
                    <div class="the-cc-price">
                        <span *ngIf="lang == 'en'">{{utils.getString('cur')}} {{car.car[0].Price | number}}</span>
                        <span *ngIf="lang == 'ar'">{{car.car[0].Price | number}} {{utils.getString('cur')}}</span>
                    </div>
                </div>
            </div>
            <div class="test-data">
                <div class="test-label">{{utils.getString('vc_test_drive_date')}}</div>
                <div class="test-hint">{{utils.getString('vc_test_drive_date_hint')}}</div>
                <div class="test-options">
                    <div class="test-option m-r-10" [class.active]="testDriveOrder.TestDate == item" *ngFor="let item of testDriveOrder.availableDays" (click)="testDriveOrder.TestDate = item">
                        {{item | date: 'EEE, dd-LLL'}}
                    </div>
                </div>
            </div>   
            <div class="test-data">
                <div class="test-label">{{utils.getString('vc_test_drive_time')}}</div>
                <div class="test-options">
                    <div class="test-option m-r-10" [class.active]="testDriveOrder.TestTime == item.SectionTitle_en" *ngFor="let item of testTimeSlots" (click)="testDriveOrder.TestTime = item.SectionTitle_en">
                        {{item['SectionTitle_' + lang]}}
                    </div>
                </div>
            </div>                   
            <button class="save-action" (click)="submitTestDriveRequest()" [disabled]="isLoading || !testDriveOrder.TestDate || !testDriveOrder.TestTime">{{utils.getString('vc_test_drive_order')}}</button>
           </div>
      </div>
    </div>
  </div> 

  <div id="offerModal" class="kz-modal" *ngIf="showOfferDialog">
    <div class="kz-modal-overlay" (click)="showOfferDialog = false"></div>
    <div class="kz-modal-dialog">
      <div class="kz-modal-header">   
        <div class="kz-modal-title">{{utils.getString('vc_make_an_offer')}}</div>   
        <button class="kz-close-modal m-l-auto" (click)="showOfferDialog = false"><i class="fal fa-times"></i></button>
      </div>
      <div class="kz-modal-body">
           <div class="kz-modal-form">
            <div class="car-card">
                <img src="{{utils.getImage(car.car[0].MainPhoto, 600, 450)}}" alt="{{car.car[0]['AdTitle_' + lang]}}" />
                <div class="car-card-info">
                    <div class="the-cc-title">{{car.car[0]['AdTitle_' + lang]}}</div>
                    <div class="card-location"><i class="fal fa-map-marker-check"></i><span class="m-l-5"><span *ngIf="car.car[0]['AreaName_' + lang]">{{car.car[0]['AreaName_' + lang]}}, </span>{{car.car[0]['CityName_' + lang]}}</span></div>
                    <div class="the-cc-price">
                        <span *ngIf="lang == 'en'">{{utils.getString('cur')}} {{car.car[0].Price | number}}</span>
                        <span *ngIf="lang == 'ar'">{{car.car[0].Price | number}} {{utils.getString('cur')}}</span>
                    </div>
                </div>
            </div>
            <div class="offer-amount">
                <div class="the-offered-price">
                    <span *ngIf="lang == 'en'">{{utils.getString('cur')}} {{offerAmount | number}}</span>
                    <span *ngIf="lang == 'ar'">{{offerAmount | number}} {{utils.getString('cur')}}</span>
                </div>
                <div id="priceSlider"></div>
                <div class="drag-to-change">{{utils.getString('drag_to_change')}}</div>
            </div>           
            <button class="save-action" (click)="makeAnOffer()" [disabled]="isLoading || offerAmount == 0">{{utils.getString('submit_price_offer')}}</button>
           </div>
      </div>
    </div>
  </div> 

<a href="#" routerLink="/{{lang}}/sell-car/sell-my-car" class="sell-my-car-now sell-{{lang}}">
    <i class="fas fa-car m-r-5"></i>
    <span>{{utils.getString('sell_my_car')}}</span>
</a>


<div id="originalsModal" class="org-modal" *ngIf="viewLargeSlider">
    <div class="org-modal-dialog">
      <div class="org-modal-header">   
        <button class="org-close-modal m-l-auto" (click)="viewLargeSlider = false"><i class="fal fa-times"></i></button>
      </div>
      <div class="org-modal-body">           
        <div class="swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of car.photos">
                    <img [class.sold-img]="isSold" [attr.data-src]="item.original" alt="{{car.car[0]['AdTitle_' + lang]}}" class="swiper-lazy" src="../../../assets/images/lazy-car.png" />
                </div>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
        </div> 
      </div>
      <div class="org-modal-footer">
        <div class="car-original-thumbs">
            <div class="swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let item of car.photos">
                        <div class="car-thumn-photo">
                            <img [class.sold-img]="isSold" [attr.data-src]="item.photo" alt="{{car.car[0]['AdTitle_' + lang]}}" class="swiper-lazy" src="../../../assets/images/lazy-car.png" />
                            <div class="view-360" *ngIf="item.is360 && !isSold">
                                <button aria-label="show 360 photo" (click)="play360()">
                                    <i class="fas fa-play-circle"></i>
                                    <span>360&#176;</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div> 
      </div>
    </div>
  </div>  