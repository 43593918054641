<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 class="web-page-title" style="text-align: center;">{{utils.getString('your_favourites')}}</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12" *ngIf="favsCount == 0">
            <div class="my-favs">
                <i class="fas fa-heart-broken"></i>
                <div class="nfv-label">{{utils.getString('no_favourites')}}</div>
                <a href="#" routerLink="/{{lang}}/used-cars">
                    {{utils.getString('browse_cars')}}
                </a>
            </div>
        </div>
        <div class="col-12">
            <div class="data-grid" *ngIf="cars.length > 0">
                <div class="data-card" *ngFor="let item of cars">
                    <app-car-item [car]="item" (onFavChanged)="onFavChanged($event)"></app-car-item>
                </div>
            </div>
        </div>
    </div>
</div>