import { Component, Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { UserService } from './services/user.service'
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import * as $ from 'jquery';
import { config } from './config';
import { countries } from './countries';
import { Utils } from './classes/utils';
import { ThrowStmt } from '@angular/compiler';
declare let addDataLayer: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

  lang:string = '';
  isBrowser: boolean = false;
  os:string = 'server';
  countriesList:Array<any> = [];

  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private userService: UserService,
    private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.countriesList = countries.data;
  }

  ngOnInit() {
    if(this.isBrowser){
      let userAgent = navigator.userAgent.toLowerCase();
      this.os = (userAgent.indexOf('ios') == -1 ? 'not-ios' : 'ios');
      let self = this;
      // window['_addDataLayer'] = function(obj){
      //   self._addDataLayer(obj);
      // }
      window['showLoginPopup'] = function(){
        self.step = 'mobile';
        self.showLogin = true;
      }
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      // if (this.isBrowser) {
      //   $('body').removeClass('mobile-menu-expanded'); 
      //   $('.account-nav').hide();
      //   $('.more-services').hide();
      //   window.scrollTo(0, 0);    
      //   $('html, body').animate({
      //     scrollTop: 0
      //   }, 200);  
      //   setTimeout(() => {
      //     window.scrollTo(0, 0);    
      //     $('html, body').animate({
      //       scrollTop: 0
      //     }, 200);  
      //   }, 100);
      // }
    });
    this.router.events.subscribe((url: NavigationEnd) => {
      if (!(url instanceof NavigationEnd)) {
        return;
      }
      if (url && url.urlAfterRedirects) {
        this.lang = this.utils.getLanguage();
        let canScrollToTop:boolean = true;
        if (this.isBrowser) {    
          if(url.urlAfterRedirects.indexOf('used-cars') > 0 && screen.width <= 768){
            canScrollToTop = false;
          }      
          $('body').removeClass('mobile-menu-expanded'); 
          $('.account-nav').hide();
          $('.more-services').hide();
          if(canScrollToTop){
            window.scrollTo(0, 0);    
          $('html, body').animate({
            scrollTop: 0
          }, 200);  
          setTimeout(() => {
            window.scrollTo(0, 0);    
            $('html, body').animate({
              scrollTop: 0
            }, 200);  
          }, 100);
          }
        }       
      }
      return;
    });
  }

  toggleMobileMenu(){
    if(this.isBrowser){
      //$('body').removeClass('mobile-menu-expanded');      
    }
  }

  _addDataLayer(obj){
    try {
        //if(this.isBrowser) addDataLayer(obj);
        console.log('event', obj);
    } catch {}
  }

  showLogin:boolean = false;
  isLoginLoading:boolean = false;
  step:string = 'mobile';
  mobileNumber:string = '';
  firstName:string = '';
  secondName:string = '';
  lastName:string = '';
  email:string = '';  
  nationality:string = '';
  phoneCode:string = '971';
  mobileToSendOTP:string = '';
  mobileToDisplayOTP:string = '';
  otpToken:string = '';
  otpInput:string = '';
  timer:number = 59;
  otpTimer:any = null;
  async sendOTP(){
    this.mobileNumber = this.utils.fixNumber(this.mobileNumber);
    this.mobileNumber = this.mobileNumber.replace(/ /g, '');
    this.mobileNumber = parseInt(this.mobileNumber).toString();
    const reg = new RegExp('^[0-9]+$');
    if(reg.test(this.mobileNumber) && this.mobileNumber && this.mobileNumber.length >= 8){
      this.mobileToSendOTP = `${this.phoneCode}` + this.mobileNumber;
      this.mobileToSendOTP = this.mobileToSendOTP.replace(`${this.phoneCode}0`, `${this.phoneCode}`);     

      this.mobileToDisplayOTP = `+${this.phoneCode} ` + this.mobileNumber;      
      this.mobileToDisplayOTP = this.mobileToDisplayOTP.replace(`+${this.phoneCode} 0`, `+${this.phoneCode} `);     
      this.generateOTP();
    } else{
      this.utils.alert(this.utils.getString('invalidMobile'), 'error');
    }
  }
  generateOTP(){
    this.isLoginLoading = true;
    this.userService.sendOTP(this.mobileToSendOTP).then(data => {
      this.otpToken = data;
      if(this.otpToken != '-'){
        this.step = 'otp';
        setTimeout(() => {
          if(this.isBrowser) (document.querySelector('#idx0') as any).focus();
        }, 100);
        this.resetTimer();
        this.startTimer();
      } else {
        this.utils.alert(this.utils.getString('tryagain'), 'error');
      }
      this.isLoginLoading = false;
    });    
  }
  resetTimer(){
    this.timer = 59;
    this.otpTimer = null;
  }
  startTimer(){
    if(this.isBrowser){
      this.timer = this.timer - 1;
      this.otpTimer = setTimeout(() => {
        if(this.timer <= 59 && this.timer > 0){
          this.startTimer();
        }
      }, 1000);
    }
  }
  getTimerString():string{
    let str:string = '';
    if(this.lang == 'en'){
      str = 'Resend code: ' + this.timer + ' seconds';
    } else {
      str = 'إعادة إرسال الرمز: ' + this.timer + ' ثانية';
    }
    return str;
  }
  verifyOTP(){    
    this.otpInput = this.utils.fixNumber(this.otpInput);
    this.isLoginLoading = true;
    this.userService.verifyOTP(this.mobileToSendOTP, this.otpInput).then(data => {
      this.isLoginLoading = false;
      try{
        if(data.Table[0].VERIFIED == 'VALID'){          
          if(data.Table1[0].UniqueID == '00000000-0000-0000-0000-000000000000'){ //not registered            
            this.step = 'reg';
            //(document.querySelector('.login-modal') as any).classList.add('registration');
          } else {        
            localStorage.user_id = data.Table1[0].UniqueID;
            localStorage.first_name = data.Table1[0].FirstName;
            localStorage.last_name = data.Table1[0].LastName;
            //this.notifications.initPush();
            this.isLoginLoading = false;            
            this.utils.doLogin(data.Table1[0].UniqueID);
            //FirebaseAnalytics.setUserId({userId: data.Table1[0].UniqueID});    
            this.dismiss();            
          }
        } else {
          this.utils.alert(this.utils.getString('invalidOTP'), 'error');
        }
      } catch {
        this.utils.alert(this.utils.getString('tryagain'), 'error');
      }
    });    
  }
  async signUp(){    
    this.mobileNumber = this.mobileNumber.replace(/ /g, '');
    if(this.firstName && this.lastName && this.email){
      let regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (regex.test(this.email)) {      
        let user = {
          MobileNumber: this.mobileNumber,
          EmailAddress: this.email,
          FirstName: this.firstName,
          LastName: this.lastName,
          PhoneCode: this.phoneCode
        }
        this.isLoginLoading = true;
        this.userService.register(user).then(async data => {
          this.isLoginLoading = false;
          let _data = data[0];
          if(_data.CODE == 200){
            this.mobileNumber = '';
            this.firstName = '';
            this.lastName = '';
            this.email = '';
            localStorage.user_id = _data.UniqueID;
            localStorage.first_name = _data.FirstName;
            localStorage.last_name = _data.LastName; 
            this.utils.doLogin(_data.UniqueID);  
            // FirebaseAnalytics.setUserId({userId: _data.UniqueID});      
            // this.utils.tagEvent('sign_up');
            // this.notifications.initPush();
            this.dismiss();           
          } else if(_data.CODE == 301){
            this.utils.alert(this.utils.getString('mobileExist'), 'error');
          } else if(_data.CODE == 302){
            this.utils.alert(this.utils.getString('emailExist'), 'error');
          }          
        });
      } else {
        this.utils.alert(this.utils.getString('invalidEmail'), 'error');
      }
    } else {
      this.utils.alert(this.utils.getString('requiredRegistrationFields'), 'error');
    }
  }
  dismiss(){
    this.showLogin = false;
  }
}
