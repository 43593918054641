<h3 class="acc-title">{{utils.getString('ac_my_dashboard')}}</h3>
<div class="row" *ngIf="isLoaded">
    <div class="col-md-6 col-12">
        <a href="#" class="dash-item notiications" routerLink="/{{lang}}/account/notifications">
            <i class="fal fa-bell dash-icon"></i>
            <span class="dash-info">
                <span class="dash-value">{{dashboard.NOTIFICATIONS_COUNT}}</span>
                <span class="dash-title" *ngIf="lang == 'en'">New Notifications</span>
                <span class="dash-title" *ngIf="lang == 'ar'">إشعارات جديدة</span>
            </span>
            <i class="fal fa-long-arrow-right dash-arrow" *ngIf="lang == 'en'"></i>
            <i class="fal fa-long-arrow-left dash-arrow" *ngIf="lang == 'ar'"></i>
        </a>  
    </div>
    <div class="col-md-6 col-12">
        <a href="#" class="dash-item cars" routerLink="/{{lang}}/account/my-cars">
            <i class="fal fa-cars dash-icon"></i>
            <span class="dash-info">
                <span class="dash-value">{{dashboard.APPROVED_CARS}}</span>
                <span class="dash-title" *ngIf="lang == 'en'">Approved Cars</span>
                <span class="dash-title" *ngIf="lang == 'ar'">سيارات منشورة</span>
            </span>
            <i class="fal fa-long-arrow-right dash-arrow" *ngIf="lang == 'en'"></i>
            <i class="fal fa-long-arrow-left dash-arrow" *ngIf="lang == 'ar'"></i>
        </a>  
    </div>
    <div class="col-md-6 col-12">
        <a href="#" class="dash-item apts" routerLink="/{{lang}}/account/expert-orders">
            <i class="fal fa-car-mechanic dash-icon"></i>
            <span class="dash-info">
                <span class="dash-value">{{dashboard.EXPERT_ORDERS}}</span>
                <span class="dash-title" *ngIf="lang == 'en'">Expert Appointments</span>
                <span class="dash-title" *ngIf="lang == 'ar'">موعد مع خبير</span>
            </span>
            <i class="fal fa-long-arrow-right dash-arrow" *ngIf="lang == 'en'"></i>
            <i class="fal fa-long-arrow-left dash-arrow" *ngIf="lang == 'ar'"></i>
        </a>  
    </div>
    <div class="col-md-6 col-12">
        <a href="#" class="dash-item spare" routerLink="/{{lang}}/account/spare-parts-orders">
            <i class="fal fa-tire dash-icon"></i>
            <span class="dash-info">
                <span class="dash-value">{{dashboard.SPARE_PART_ORDERS}}</span>
                <span class="dash-title" *ngIf="lang == 'en'">Spart Part Orders</span>
                <span class="dash-title" *ngIf="lang == 'ar'">طلب قطع غيار</span>
            </span>
            <i class="fal fa-long-arrow-right dash-arrow" *ngIf="lang == 'en'"></i>
            <i class="fal fa-long-arrow-left dash-arrow" *ngIf="lang == 'ar'"></i>
        </a> 
    </div>
</div>