<h3 class="acc-title">{{utils.getString('ac_my_profile')}}</h3>
<div class="acc-page">
    <div class="ac-type act-{{profile.AccountType}}">
      <span>{{utils.getString('ac_type_' + profile.AccountType)}}</span>
    </div>
    <div class="gc-field">
        <label class="gc-label">{{utils.getString('your_mobile')}}</label>
        <div class="gc-input">
          <span class="gc-input-group m-r-10">
            <i class="fal fa-mobile"></i><span class="m-l-5">+{{phoneCode}}</span>
          </span>
          <input type="tel" [disabled]="true" maxlength="9" placeholder="5xxxxxxxx" [(ngModel)]="profile.MobileNumber" />
        </div>
    </div> 
    <div class="gc-field">
        <label class="gc-label">{{utils.getString('your_fname')}}</label>
        <div class="gc-input">
          <span class="gc-input-group m-r-10">
            <i class="fal fa-user"></i>
          </span>
          <input type="text" placeholder="" [(ngModel)]="profile.FirstName" />
        </div>
    </div> 
    <div class="gc-field">
        <label class="gc-label">{{utils.getString('your_lname')}}</label>
        <div class="gc-input">
          <span class="gc-input-group m-r-10">
            <i class="fal fa-user"></i>
          </span>
          <input type="text" placeholder="" [(ngModel)]="profile.LastName" />
        </div>
    </div> 
    <div class="gc-field">
        <label class="gc-label">{{utils.getString('your_email')}}</label>
        <div class="gc-input">
          <span class="gc-input-group m-r-10">
            <i class="fal fa-envelope"></i>
          </span>
          <input type="email" placeholder="" [(ngModel)]="profile.EmailAddress" />
        </div>
    </div> 
    <div class="gc-field">
        <label class="gc-label">{{utils.getString('your_preferred_language')}}</label>
        <div class="gc-input">
          <span class="gc-input-group m-r-10">
            <i class="fal fa-globe"></i>
          </span>
          <select [(ngModel)]="profile.PreferredLanguage">
            <option value="en">English</option>
            <option value="ar">العربية</option>
          </select>
        </div>
    </div>   
    <label class="gc-hint">{{utils.getString('your_preferred_language_hint')}}</label>
    <button class="save-action" (click)="save()" [disabled]="isLoading">{{utils.getString('save')}}</button>
    <div class="logout">
        <button (click)="logout()"><i class="fal fa-power-off m-r-5"></i><span>{{utils.getString('logout')}}</span></button>
    </div>
</div>