import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../classes/utils';
import { SharedService } from '../../services/shared.service';
import { config } from '../../config';

@Component({
  selector: 'app-car-item',
  templateUrl: './car-item.component.html',
  styleUrls: ['./car-item.component.less']
})
export class CarItemComponent implements OnInit {

  @Input('car') car:any = {};
  @Input('priceType') priceType:string = 'total';
  @Output() onFavChanged:EventEmitter<any> = new EventEmitter();

  lang:string = '';
  isBrowser:boolean;
  isLoggedIn:boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private service: SharedService) {
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;
      });
      this.router.events.subscribe((url: NavigationEnd) => {
        if (url && url.urlAfterRedirects) {
          this.lang = this.utils.getLanguage();       
        }
        return;
      });     
   }

  ngOnInit(): void {
    this.car.fav = this.utils.isFav(this.car.CarID);
  }

  carLink(){
    return `${this.utils.getStrUrl(this.car.MakeName_en)}-${this.utils.getStrUrl(this.car.ModelName_en)}-${this.car.CYear}-for-sale`;
  }

  addToFavourites(){
    this.car.fav = this.utils.toggleFav(this.car.CarID);
    if(this.isBrowser) window['favChanged']();
    this.onFavChanged.emit(this.car);
  }
}
