<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="used-cars-title">
                <h1 class="used-title m-r-auto">
                    {{carsTitle}}
                </h1> 
                <!-- <span class="total-records" *ngIf="total > 0">
                    {{utils.getString('fl_total_records')}}
                    <b>({{total}})</b>
                    {{utils.getString('fl_total_records2')}}
                </span> -->
            </div>           
            <div class="download-app">
                <h3 class="lay-{{lang}}">{{utils.getString('get_app')}}</h3>
                <a href="https://play.google.com/store/apps/details?id=app.gocars.ae"><img src="../../../assets/images/app/play-store.png" alt="{{utils.getString('get_app')}}" class="m-r-10" /></a>
                <a href="https://apps.apple.com/us/app/go-cars-buy-sell-in-uae/id6447811168"><img src="../../../assets/images/app/app-store.png" alt="{{utils.getString('get_app')}}" /></a>
            </div>
            <div class="top-filters">
                <div class="makes-filter m-r-15">
                    <i class="fal fa-search m-r-10"></i> <app-makes-input [ui-class]="'used-cars-search'" (onMakeChanged)="onMakeChanged($event)"></app-makes-input>
                </div>    
                <button class="toggle-filters m-r-15" [class.expanded]="toggleFilters" (click)="toggleFilters = !toggleFilters">
                    <i class="fal fa-sliders-h"></i>
                    <span class="fl-counter layout-{{lang}}" *ngIf="filtersCount > 0">{{filtersCount}}</span>
                </button>
                <div class="sort-by layout-{{lang}} m-l-auto">
                    <span class="sort-label m-r-10">{{utils.getString('sort_by')}}</span>
                    <select class="form-control" [(ngModel)]="query.sort" (change)="redirect()">
                        <option value="latest">{{utils.getString('sort_by_latest')}}</option>
                        <option value="low-price">{{utils.getString('sort_by_lowprice')}}</option>
                        <option value="high-price">{{utils.getString('sort_by_highprice')}}</option>
                        <option value="low-milage">{{utils.getString('sort_by_lowmilage')}}</option>
                        <option value="high-milage">{{utils.getString('sort_by_highmilage')}}</option>
                        <option value="low-year">{{utils.getString('sort_by_lowyear')}}</option>
                        <option value="high-year">{{utils.getString('sort_by_highyear')}}</option>
                    </select> 
                </div>
            </div>
            <div class="selected-cars">
                <span class="selected-car m-r-10" *ngFor="let item of selectedCars">
                    <span class="cr-name m-r-10">{{item['mmname_' + lang]}}</span>
                    <span class="cr-remove" (click)="removeCar(item)"><i class="fas fa-times"></i></span>
                </span>
            </div>
        </div>
        <div class="col-12">
            <div class="data-wrapper">
                <div class="filters layout-{{lang}} m-r-20" [class.expanded]="toggleFilters">
                    <div class="clear-all-filters" *ngIf="filtersCount > 0">
                        <div class="clear-all-label m-r-auto">{{utils.getString('fl_filters')}} <b>({{filtersCount}})</b></div>
                        <div class="filter-tag" 
                        (click)="resetFilters()">
                        {{utils.getString('fl_clearall')}}
                    </div> 
                    </div>
                    <div class="filter-price">
                        <div class="filter-tag m-r-10" [class.selected-tag]="query.PRICE_TYPE == 'total'" (click)="changePriceType('total')">{{utils.getString('fl_full_price')}}</div>
                        <div class="filter-tag" [class.selected-tag]="query.PRICE_TYPE == 'monthly'" (click)="changePriceType('monthly')">{{utils.getString('fl_monthly_price')}}</div>
                    </div>
                    <div class="filter-field" *ngIf="query.PRICE_TYPE == 'total'">
                        <div class="filter-label">{{utils.getString('fl_range_price')}}</div>
                        <div class="range-numbers">
                            <div class="min m-r-auto">{{query.MIN_PRICE}}k</div>
                            <div class="max">{{query.MAX_PRICE}}k</div>
                        </div>
                        <div id="fullPriceSlider"></div>
                    </div>
                    <div class="filter-field" *ngIf="query.PRICE_TYPE == 'monthly'">
                        <div class="filter-label">{{utils.getString('fl_monthly_price')}}</div>
                        <div class="range-numbers">
                            <div class="min m-r-auto">{{query.MIN_PRICE}}k</div>
                            <div class="max">{{query.MAX_PRICE}}k</div>
                        </div>
                        <div id="monthlyPriceSlider"></div>
                    </div>
                    <div class="filter-field">
                        <div class="filter-label">{{utils.getString('fl_milage_price')}}</div>
                        <div class="range-numbers">
                            <div class="min m-r-auto">{{query.MIN_MILAGE}}k</div>
                            <div class="max">{{query.MAX_MILAGE}}k</div>
                        </div>
                        <div id="milageSlider"></div>
                    </div>
                    <div class="filter-field">
                        <div class="filter-label">{{utils.getString('fl_year_price')}}</div>
                        <div class="range-numbers">
                            <div class="min m-r-auto">{{query.MIN_YEAR}}</div>
                            <div class="max">{{query.MAX_YEAR}}</div>
                        </div>
                        <div id="yearSlider"></div>
                    </div>
                    <div class="filter-field">
                        <div class="filter-label">{{utils.getString('fl_city')}}</div>
                        <div class="filter-tags">
                            <div class="filter-tag m-r-10" 
                                (click)="arrFilter('CITY', item.CityID)"
                                [class.selected-tag]="arrStr('CITY').includes(item.CityID)"
                                *ngFor="let item of cities">
                                {{item['CityName_' + lang]}}
                            </div>                            
                        </div>
                    </div>
                    <div class="filter-field">
                        <div class="filter-label">{{utils.getString('fl_body_type')}}</div>
                        <div class="filter-tags">
                            <div class="filter-tag m-r-10" 
                                (click)="arrFilter('BODY_TYPE', item.LookItemID)"
                                [class.selected-tag]="arrStr('BODY_TYPE').includes(item.LookItemID)"
                                *ngFor="let item of bodyTypes">
                                {{item['LookItem_' + lang]}}
                            </div>                            
                        </div>
                    </div>
                    <div class="filter-field">
                        <div class="filter-label">{{utils.getString('fl_transmission')}}</div>
                        <div class="filter-tags">
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('TRANSMISSION', item.LookItemID)"
                                [class.selected-tag]="query.TRANSMISSION == item.LookItemID"
                                *ngFor="let item of transmission">
                                {{item['LookItem_' + lang]}}
                            </div>                            
                        </div>
                    </div>
                    <div class="filter-field">
                        <div class="filter-label">{{utils.getString('fl_fuel')}}</div>
                        <div class="filter-tags">
                            <div class="filter-tag m-r-10" 
                                (click)="arrFilter('FUEL', item.LookItemID)"
                                [class.selected-tag]="arrStr('FUEL').includes(item.LookItemID)"
                                *ngFor="let item of fuel">
                                {{item['LookItem_' + lang]}}
                            </div>                            
                        </div>
                    </div>
                    <div class="filter-field">
                        <div class="filter-label">{{utils.getString('fl_seller_type')}}</div>
                        <div class="filter-tags">
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('AccountType', 1)"
                                [class.selected-tag]="query.AccountType == 1">
                                {{utils.getString('fl_seller_type_1')}}
                            </div>  
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('AccountType', 2)"
                                [class.selected-tag]="query.AccountType == 2">
                                {{utils.getString('fl_seller_type_2')}}
                            </div>                            
                        </div>
                    </div>
                    <div class="filter-field">
                        <div class="filter-label">{{utils.getString('fl_badges')}}</div>
                        <div class="filter-tags">
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('TopCondition', 1)"
                                [class.selected-tag]="query.TopCondition == 1">
                                <span *ngIf="lang == 'en'">Top Condition</span>
                                <span *ngIf="lang == 'ar'">حالة ممتازة</span>
                            </div>  
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('Negotiable', 1)"
                                [class.selected-tag]="query.Negotiable == 1">
                                <span *ngIf="lang == 'en'">Negotiable</span>
                                <span *ngIf="lang == 'ar'">قابل للتفاوض</span>
                            </div> 
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('UrgentSale', 1)"
                                [class.selected-tag]="query.UrgentSale == 1">
                                <span *ngIf="lang == 'en'">Urgent Sale</span>
                                <span *ngIf="lang == 'ar'">بيع عاجل</span>
                            </div>      
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('NeedMaintenance', 1)"
                                [class.selected-tag]="query.NeedMaintenance == 1">
                                <span>{{utils.getString('fl_need_maintenance')}}</span>
                            </div> 
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('HasDownPayment', 1)"
                                [class.selected-tag]="query.HasDownPayment == 1">
                                <span *ngIf="lang == 'en'">0 Down Payment</span>
                                <span *ngIf="lang == 'ar'">بدون دفعة أولى</span>
                            </div>                                     
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('PriceDropped', 1)"
                                [class.selected-tag]="query.PriceDropped == 1">
                                <span *ngIf="lang == 'en'">Price Dropped</span>
                                <span *ngIf="lang == 'ar'">سعر مخفض</span>
                            </div>                                                       
                        </div>
                    </div>
                    <div class="filter-field">
                        <div class="filter-label">{{utils.getString('fl_features')}}</div>
                        <div class="filter-tags">
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('F_NAVIGATION', 1)"
                                [class.selected-tag]="query.F_NAVIGATION == 1">
                                <span *ngIf="lang == 'en'">Navigation</span>
                                <span *ngIf="lang == 'ar'">نظام ملاحة</span>
                            </div>   
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('F_PARKING_SENSOR', 1)"
                                [class.selected-tag]="query.F_PARKING_SENSOR == 1">
                                <span *ngIf="lang == 'en'">Parking Sensors</span>
                                <span *ngIf="lang == 'ar'">حساسات الإصطفاف</span>
                            </div>  
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('F_REVERSING_CAMERA', 1)"
                                [class.selected-tag]="query.F_REVERSING_CAMERA == 1">
                                <span *ngIf="lang == 'en'">Reversing Camera</span>
                                <span *ngIf="lang == 'ar'">كاميرا خلفية</span>
                            </div> 
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('F_GCC_SPECS', 1)"
                                [class.selected-tag]="query.F_GCC_SPECS == 1">
                                <span *ngIf="lang == 'en'">GCC Specifications</span>
                                <span *ngIf="lang == 'ar'">مواصفات خليجية</span>
                            </div> 
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('F_SUN_ROOF', 1)"
                                [class.selected-tag]="query.F_SUN_ROOF == 1">
                                <span *ngIf="lang == 'en'">Sun Roof</span>
                                <span *ngIf="lang == 'ar'">فتحة سقف</span>
                            </div> 
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('F_LEATHER_SEATS', 1)"
                                [class.selected-tag]="query.F_LEATHER_SEATS == 1">
                                <span *ngIf="lang == 'en'">Leather Seats</span>
                                <span *ngIf="lang == 'ar'">مقاعد جلدية</span>
                            </div>   
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('F_SEVEN_SEATERS', 1)"
                                [class.selected-tag]="query.F_SEVEN_SEATERS == 1">
                                <span *ngIf="lang == 'en'">7 Seater</span>
                                <span *ngIf="lang == 'ar'">7 مقاعد</span>
                            </div>  
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('F_SERVICE_CONTRACT', 1)"
                                [class.selected-tag]="query.F_SERVICE_CONTRACT == 1">
                                <span *ngIf="lang == 'en'">Service Contract</span>
                                <span *ngIf="lang == 'ar'">عقد صيانة</span>
                            </div>      
                            <div class="filter-tag m-r-10" 
                                (click)="numFilter('F_WARRANTY', 1)"
                                [class.selected-tag]="query.F_WARRANTY == 1">
                                <span *ngIf="lang == 'en'">Warranty</span>
                                <span *ngIf="lang == 'ar'">ضمان</span>
                            </div>                                                        
                        </div>
                    </div>
                    <div class="filter-field">
                        <div class="filter-label">{{utils.getString('fl_price_guidance')}}</div>
                        <div class="filter-tags">
                            <div class="filter-tag m-r-10" 
                                (click)="arrFilter('PRICE_RATE', item.value)"
                                [class.selected-tag]="arrStr('PRICE_RATE').includes(item.value)"
                                *ngFor="let item of pricerates">
                                {{item['display_' + lang]}}
                            </div>                            
                        </div>
                    </div>
                    <div class="filter-field">
                        <div class="filter-label">{{utils.getString('fl_color')}}</div>
                        <div class="filter-tags">
                            <div class="filter-tag m-r-10" 
                                (click)="arrFilter('COLOR', item.LookItemID)"
                                [class.selected-tag]="arrStr('COLOR').includes(item.LookItemID)"
                                *ngFor="let item of colors">
                                <span class="clr m-r-5" [style.backgroundColor]="item.LookCode"></span>
                                <span class="clr-name">{{item['LookItem_' + lang]}}</span>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div class="used-cars">
                    <div class="data-grid" *ngIf="!isLoaded && cars.length == 0">
                        <div class="data-card" *ngFor="let item of [].constructor(24)">
                            <app-skeleton></app-skeleton>
                        </div>
                    </div>
                    <div class="no-cars-found" *ngIf="isLoaded && cars.length == 0">
                        <img src="../../../assets/images/no-data-found.png" />
                        <div class="no-cars-title">{{utils.getString('fl_no_cars_found_t')}}</div>
                        <div class="no-cars-info">{{utils.getString('fl_no_cars_found')}}</div>
                    </div>
                    <div class="data-grid" *ngIf="isLoaded && cars.length > 0">
                        <div class="data-card" *ngFor="let item of cars">
                            <app-car-item [car]="item" [priceType]="query.PRICE_TYPE"></app-car-item>
                        </div>
                    </div>
                    <div class="cars-pagination" *ngIf="isLoaded && cars.length > 0 && totalPages > 1">
                        <button
                            [disabled]="query.offset === 1"
                            (click)="selectPage(1)">   
                            <i class="fal fa-chevron-double-left" *ngIf="lang == 'en'"></i>           
                            <i class="fal fa-chevron-double-right" *ngIf="lang == 'ar'"></i>                       
                        </button>
                        <button
                            [disabled]="query.offset === 1"
                            (click)="selectPage(query.offset - 1)">    
                            <i class="fal fa-chevron-left" *ngIf="lang == 'en'"></i>           
                            <i class="fal fa-chevron-right" *ngIf="lang == 'ar'"></i>                         
                        </button>
                        <button
                            *ngFor="let page of visiblePages"
                            [class.selected]="query.offset === page"
                            (click)="selectPage(page)">
                            {{page}}
                        </button>                        
                        <button
                            [disabled]="query.offset >= totalPages"
                            (click)="selectPage(query.offset + 1)">    
                            <i class="fal fa-chevron-right" *ngIf="lang == 'en'"></i>           
                            <i class="fal fa-chevron-left" *ngIf="lang == 'ar'"></i>           
                        </button>
                        <button
                            [disabled]="query.offset >= totalPages"
                            (click)="selectPage(totalPages)">   
                            <i class="fal fa-chevron-double-right" *ngIf="lang == 'en'"></i>           
                            <i class="fal fa-chevron-double-left" *ngIf="lang == 'ar'"></i>                               
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<a href="#" routerLink="/{{lang}}/sell-car/sell-my-car" class="sell-my-car-now sell-{{lang}}">
    <i class="fas fa-car m-r-5"></i>
    <span>{{utils.getString('sell_my_car')}}</span>
</a>