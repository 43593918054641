<div class="acc-title-wrapper">
    <h3 class="acc-title">{{utils.getString('ac_cars')}}</h3>
    <a href="#" routerLink="/{{lang}}/sell-car" class="acc-add m-l-auto"><i class="fal fa-plus"></i> {{utils.getString('add_car')}}</a>
</div>
<div class="acc-list-view">
    <div class="acc-card" *ngFor="let item of orders">
        <div class="card-img-wrapper">
            <img class="card-img m-r-15" src="{{utils.getLargeCarPhoto(item.MainPhoto)}}" alt="Go Cars" />
            <div class="cim-info">
                <div class="ac-card-title">
                    <div class="card-title-wrapper">
                        <div class="the-c-title m-r-10">
                            <a href="#" routerLink="/{{lang}}/used-car//{{item.CarID}}" target="_blank">
                                <span class="m-r-5">{{item['AdTitle_' + lang]}}</span>
                                <span class="m-r-5">/</span>
                                <span>{{item['AreaName_' + lang]}}, {{item['CityName_' + lang]}}</span>
                            </a>
                        </div>
                        <a href="#" *ngIf="item.AdStatus != 3" routerLink="/{{lang}}/manage-car" class="manage-car m-l-auto" [queryParams]="{id: item.CarID, sellBy: item.SellBy}"><i class="fal fa-edit"></i><span>{{utils.getString('edit')}}</span></a>
                    </div>
                </div>        
                <div class="ac-card-desc">  
                    <div class="car-id-status">
                        <div class="ac-card-id m-r-auto">ID #{{item.CarID}}</div>
                        <div class="car-status">
                            <i class="fas fa-circle state-{{item.AdStatus}}"></i>
                            <span *ngIf="item.AdStatus == 0 && lang == 'en'">Waiting for Approval</span>
                            <span *ngIf="item.AdStatus == 1 && lang == 'en'">Approved</span>
                            <span *ngIf="item.AdStatus == 3 && lang == 'en'">Rejected</span>
                            <span *ngIf="item.AdStatus == 0 && lang == 'ar'">بإنتظار الموافقة</span>
                            <span *ngIf="item.AdStatus == 1 && lang == 'ar'">موافق عليه</span>
                            <span *ngIf="item.AdStatus == 3 && lang == 'ar'">مرفوض</span>
                        </div>
                    </div>           
                    <div>{{item.CYear}} - {{item.Milage | number}} {{utils.getString('km')}}</div>       
                    <div *ngIf="item.SellBy != 1 && item.AccountType != 2"><span class="m-r-5">{{utils.getString('expiry_date')}}:</span>{{item.CarExpiryDate | date:'mediumDate'}} {{item.CarExpiryDate | date:'shortTime'}}</div>       
                    <div *ngIf="item.SellBy == 1 || item.AccountType == 2"><span class="m-r-5">{{utils.getString('expiry_date')}}:</span>{{utils.getString('no_expiry')}}</div>       
                </div>
                <div class="ac-card-payment">
                    <div class="order-price">{{item.Price | number}} {{utils.getString('cur')}}</div>
                    <div class="order-payment m-l-auto">               
                        <span *ngIf="item.IsPaid" class="paid-order">{{utils.getString('paid')}}</span>
                        <span *ngIf="!item.IsPaid && item.Price == 0" class="pending-order">{{utils.getString('pending_order')}}</span>
                        <a href="{{utils.paymentUrl(item.PaymentToken)}}" *ngIf="!item.IsPaid && item.Price > 0 && item.AdStatus != 3" class="pay-order">{{utils.getString('pay_order')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>