<article class="car-item layout-{{lang}}" [class.sold]="car.IsSold">
    <button aria-label="Add to Favourites" class="car-fav layout-{{lang}}" [class.added]="car.fav" (click)="addToFavourites()">
        <i class="fal fa-heart" [class.fas]="car.fav"></i>
    </button>
    <a href="#" class="car-item-photo"
    routerLink="/{{lang}}/used-car//{{car.CarID}}/{{carLink()}}">
        <span class="price-tag rate-{{car.PriceRate}}" *ngIf="car.PriceRate != -1">{{car['PriceRate_' + lang]}}</span>
        <figure>
            <img src="{{utils.getImage(car.MainPhoto, 600, 450)}}" alt="{{car['AdTitle_' + lang]}}" />
        </figure>
        <span class="featured-car" *ngIf="car.IsFeatured"><i class="bi bi-star-fill"></i></span>
        <span class="top-condition" *ngIf="car.TopCondition && !car.IsFeatured"><i class="bi bi-stars"></i></span>
        <span class="photo-desc" *ngIf="car.Photo360"><i class="bi bi-arrow-repeat"></i> 360</span>
        <span class="photo-desc" *ngIf="!car.Photo360"><i class="bi bi-image"></i> {{car.PhotosCount}}</span>
        <span class="sold-label" *ngIf="car.IsSold">{{utils.getString('sold')}}</span>
    </a>
    <div class="car-info">
        <div class="title">
            <a href="#" class="car-item-title m-r-10" routerLink="/{{lang}}/used-car//{{car.CarID}}/{{carLink()}}">
                {{car['AdTitle_' + lang]}}
            </a>
            <span class="car-item-price" *ngIf="priceType == 'total'">
                <span *ngIf="lang == 'en'">{{utils.getString('cur')}} {{car.Price | number}}</span>
                <span *ngIf="lang == 'ar'">{{car.Price | number}} {{utils.getString('cur')}}</span>
                <div class="price-drop" *ngIf="car.PriceDropped"><b>{{car.DropRate}}%</b> {{utils.getString('price_drop')}}</div>
            </span>
            <span class="car-item-price" *ngIf="car.MonthlyPrice > 0 && priceType == 'monthly'">
                {{car.MonthlyPrice | number}} / {{utils.getString('month')}}
                <div class="price-drop" *ngIf="car.PriceDropped"><b>{{car.DropRate}}%</b> {{utils.getString('price_drop')}}</div>
            </span>
        </div>
        <div class="desc">
            <span class="km m-r-10">
                {{car.CYear}} - {{car.Milage | number}} {{utils.getString('km')}}
            </span>
            <span class="car-monthly-price" *ngIf="car.MonthlyPrice > 0 && priceType == 'total'">
                {{car.MonthlyPrice | number}} / {{utils.getString('month')}}
            </span>
            <span class="car-monthly-price" *ngIf="priceType == 'monthly'">
                <span *ngIf="lang == 'en'">{{utils.getString('cur')}} {{car.Price | number}}</span>
                <span *ngIf="lang == 'ar'">{{car.Price | number}} {{utils.getString('cur')}}</span>        
            </span>
        </div>
    </div>
</article>