let siteName = 'Go Cars';
let baseUrl = 'https://api.gocars.ae/';
 
export const config = {
  isProduction: true,
  apiUrl: `${baseUrl}api/`,
  paymentUrl: `${baseUrl}Pay`, 
  panoUrl: `${baseUrl}/360.html?id=`,
  logoUrl: `${baseUrl}logo.png`,
  defaultLanguage: 'en',
  siteCountry: 'UAE',
  siteCountry_en: 'UAE',
  siteCountry_ar: 'الإمارات',
  // watermark_large: '5ca5d6c3-6666-4446-b785-8a73179685a4',
  // watermark_small: '5b56c1dc-8534-4ac2-a154-2eedb6f57279',
  watermark_large: '68df7c54-679f-45b1-8440-6f2b2fb56598',
  watermark_small: '27a2412e-46f1-4f00-b9c1-63107aaa5218',
  meta: {
    siteName: siteName,
    og_image: 'https://ucarecdn.com/0bdecef6-b067-4717-9eec-ccfcdf3b7a75/goog.jpg',
    og_site_name: `${siteName}`,
    og_type: 'Website',
    siteUrl: 'https://gocars.ae'
  }
};
