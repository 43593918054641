import { Injectable, Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { config } from '../config';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { resx } from '../resx';
import Swal from 'sweetalert2'

@Injectable()
export class Utils {

    loginObserver = new BehaviorSubject<boolean>(this._isLoggedIn());
    currencyObserver = new BehaviorSubject<string>(this._currencyValue());
    profileObserver = new BehaviorSubject<string>(this._profileValue());

    constructor(@Inject(PLATFORM_ID) private platformID: Object,
    private router: Router, private activatedRoute: ActivatedRoute) {

    }

    config(key){
        return config[key];
    }

    public getString(key, lang?) {
        lang = (lang ? lang : this.getLanguage());
        let value = (resx[key] ? (resx[key][lang] ? resx[key][lang] : key) : key);
        return value;
    }

    isBrowser():boolean{
        return isPlatformBrowser(this.platformID);
    }

    getLanguage():string{   
        if (this.router.url.indexOf('/en/') != -1) {
            return 'en';
        } else {
            return 'ar';
        }
    }

    changeUrl():string{
        let url = this.router.url;
        let lang = this.getLanguage();
        if(this.getLanguage() == 'en'){
            url = url.replace('/' + lang + '/', '/ar/');
        } else {
            url = url.replace('/' + lang + '/', '/en/');
        }
        return url;
    }

    _isLoggedIn() {
        if(this.isBrowser()){
            if(localStorage.user_id && localStorage.user_id != '00000000-0000-0000-0000-000000000000'){
                return true;
            }
        }        
        return false;
    }
    getUserId(){
        if(this.isBrowser()){
            if(localStorage.user_id && localStorage.user_id != '00000000-0000-0000-0000-000000000000'){
                return localStorage.user_id;
            } else {
                return '00000000-0000-0000-0000-000000000000';
            }
        } else {
            return '00000000-0000-0000-0000-000000000000';
        }
    }

    _currencyValue():string {        
        if(this.isBrowser()){
            return (localStorage.currency ? localStorage.currency : 'USD');
        } else {
            return 'USD';
        }
    }
    _profileValue():string {        
        return (this.isBrowser() ? localStorage.user_id : '00000000-0000-0000-0000-000000000000');
    }

    doLogin(uid): void {
        if(this.isBrowser()) localStorage.setItem('user_id', uid);
        this.loginObserver.next(true);
    }
    doLogout(): void {
        if(this.isBrowser()) {
            localStorage.removeItem('user_id');
            localStorage.removeItem('userToken');
            localStorage.removeItem('ProfilePhoto');
            localStorage.removeItem('FirstName');
            localStorage.removeItem('mode');
        }        
        this.loginObserver.next(false);
    }

    forceLogout(): void {
        if(this.isBrowser()) {
            localStorage.removeItem('user_id');
            localStorage.removeItem('userToken');
            localStorage.removeItem('ProfilePhoto');
            localStorage.removeItem('FirstName');
            localStorage.removeItem('mode');
        }        
    }

    changeCurrency(_cur): void {
        if(this.isBrowser()) localStorage.currency = _cur;
        this.currencyObserver.next(_cur);
    }

    updateProfile(){
        this.profileObserver.next(this.getUUID());
    }

    isLoggedIn(): Observable<boolean> {
        return this.loginObserver.asObservable();
    }

    currencyChanged(): Observable<string> {
        return this.currencyObserver.asObservable();
    }

    profileUpdated(): Observable<string> {
        return this.profileObserver.asObservable();
    }

    public getFile(id:string): string {
        return `https://ucarecdn.com/${id}/`;
    }

    public getRegularIdWithSize(id:string, w = 1024, h = 768): string {
        var ucid = id.split('/')[0];
        return `https://ucarecdn.com/${ucid}/-/scale_crop/${w}x${h}/smart/-/format/auto/`;
    }

    public getRegularFile(id:string): string {
        var ucid = id.split('/')[0];
        return `https://ucarecdn.com/${ucid}/-/format/auto/`;
    }

    public getImage(id:string, w = 1024, h = 768): string {        
        return `https://ucarecdn.com/${id}/-/scale_crop/${w}x${h}/smart/-/format/auto/`;        
    }

    public getLargeCarPhoto(id:string, w = 1024, h = 768): string {        
        return `https://ucarecdn.com/${id}/-/scale_crop/${w}x${h}/smart/-/overlay/${config.watermark_large}/50p,50p/50p/-/format/auto/`;        
    }  
    
    public getSmallCarPhoto(id:string, w = 600, h = 450): string {        
        return `https://ucarecdn.com/${id}/-/scale_crop/${w}x${h}/smart/-/overlay/${config.watermark_small}/50p,50p/50p/-/format/auto/`;        
    }  

    public getResizeImage(id:string, w = 1024): string {        
        return `https://ucarecdn.com/${id}/-/resize/${w}x/-/format/auto/`;        
    }


    public getLogo(){
        return config.logoUrl;
    }

    getUUID() {
        return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    getStrUrl(str){        
        str = str.trim().toLowerCase();
        str = str.replace(/ /g, '-');
        str = str.replace(/\//g, '-');
        str = str.replace(/\./g, '-');
        str = str.replace(/\(/g, '-');
        str = str.replace(/\)/g, '-');
        return str;
    }

    fixNumber(txt){    
        try{
            if(txt){
                txt = txt.replaceAll(/٠/ig, '0');
                txt = txt.replaceAll(/١/ig, '1');
                txt = txt.replaceAll(/٢/ig, '2');
                txt = txt.replaceAll(/٣/ig, '3');
                txt = txt.replaceAll(/٤/ig, '4');
                txt = txt.replaceAll(/٥/ig, '5');
                txt = txt.replaceAll(/٦/ig, '6');
                txt = txt.replaceAll(/٧/ig, '7');
                txt = txt.replaceAll(/٨/ig, '8');
                txt = txt.replaceAll(/٩/ig, '9');
              }
              return txt;
        } catch {
            return txt;
        }
    }

    alert(title, type?, text?){
        if(this.isBrowser()){
            if(!type) type = 'info';
            Swal.fire({
                title: title,
                text: (text ? text : ''),
                confirmButtonText: (this.getLanguage() == 'en' ? 'OK' : 'موافق'),
                icon: type
            });
        }
    }

    success(title?, text?, timer?){
        if(this.isBrowser){
            if(!title) title = (this.getLanguage() == 'en' ? 'Saved successfully' : 'تم الحفظ بنجاح');
            Swal.fire({
                title: title,
                text: (text ? text : ''),
                confirmButtonText: (this.getLanguage() == 'en' ? 'OK' : 'موافق'),
                timer: (timer ? timer : 1500),
                icon: 'success'
            });
        }
    }

    confirm(title, text?):Promise<boolean>{
        return new Promise((resolve, reject) => {
            if(this.isBrowser){
                Swal.fire({
                    title: title,
                    text: text,
                    showDenyButton: false,
                    showCancelButton: true,
                    confirmButtonText: (this.getLanguage() == 'en' ? 'Yes' : 'نعم'),
                    cancelButtonText: (this.getLanguage() == 'en' ? 'No' : 'لا'),
                    allowOutsideClick:false
                  }).then((result) => {
                    if (result.isConfirmed) {
                        resolve(true);
                    } else  {
                        resolve(false);
                    }
                  })
            } else {
                resolve(false);
            }             
          });
    }

    getTimeZone():number{
        if(this.isBrowser()){
            let offset = new Date().getTimezoneOffset();
            let timeZone = offset / 60;
            timeZone = -(timeZone);
            return timeZone;
        } else {
            return 0
        }
    }

    base64_encode(text){
        return btoa(text);
    }

    base64_decode(text){
        return atob(text);
    }

    paymentUrl(paymentToken){        
        let mode:string = (config.isProduction ? 'prod' : 'test');
        return `${config.paymentUrl}?token=${paymentToken}&uid=${this.getUserId()}&platform=web&mode=${mode}`;
    }

    getFavs():Array<number>{
        try{
            if(localStorage.favs){
                return JSON.parse(localStorage.favs);
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    isFav(id:number):boolean{
        let favs:Array<number> = this.getFavs();
        return favs.includes(id);
    }

    isFavExist(favs:Array<number>, id:number):boolean{
        return favs.includes(id);
    }

    toggleFav(id):boolean{
        let favs:Array<number> = this.getFavs();
        let isFavExist = this.isFavExist(favs, id);
        if(isFavExist){
            favs = this.removeFromFavs(favs, id);
        } else {
            favs = this.addToFavs(favs, id);
        }
        localStorage.favs = JSON.stringify(favs);
        return this.isFavExist(favs, id);
    }

    addToFavs(favs:Array<number>, id:number):Array<number>{        
        try{
            favs.push(id);
            return favs;
        } catch{
            return favs;
        }
    }

    removeFromFavs(favs:Array<number>, id:number):Array<number>{
        try{
            for( var i = 0; i < favs.length; i++){     
                if (favs[i] === id) {         
                    favs.splice(i, 1); 
                }        
            }
            return favs;
        } catch{
            return favs;
        }
    }
}
