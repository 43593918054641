<div class="acc-title-wrapper">
    <h3 class="acc-title">{{utils.getString('buy_online')}}</h3>
</div>
<div class="acc-list-view">
    <div class="acc-card" *ngFor="let item of orders">
        <div class="card-img-wrapper">
            <img class="card-img m-r-15" src="{{utils.getLargeCarPhoto(item.MainPhoto)}}" alt="Go Cars" />
            <div class="cim-info">
                <div class="ac-card-title">
                    <div class="card-title-wrapper">
                        <div class="the-c-title m-r-10">
                            <span class="m-r-5">{{item['AdTitle_' + lang]}}</span>
                        </div>
                    </div>
                </div>        
                <div class="ac-card-desc">  
                    <div class="car-id-status">
                        <div class="ac-card-id m-r-auto">ID #{{item.CarID}}</div>
                    </div>           
                    <div>{{item.CYear}} - {{item.Milage | number}} {{utils.getString('km')}}</div>       
                </div>
                <div class="ac-card-payment">
                    <div class="order-price" *ngIf="lang == 'en'">
                        Paid 
                        {{item.TotalPaid}}
                        of
                        {{item.Price | number}} {{utils.getString('cur')}}
                    </div>
                    <div class="order-price" *ngIf="lang == 'ar'">
                        تم دفع 
                        {{item.TotalPaid}}
                        من
                        {{item.Price | number}} {{utils.getString('cur')}}
                    </div>
                    <div class="order-payment m-l-auto">               
                        <span *ngIf="item.NoOfPayments == item.PaymentsDone" class="paid-order">{{utils.getString('paid')}}</span>
                        <a href="#" routerLink="/{{lang}}/account/buy-payments"
                        [queryParams]="{id: item.BID}" 
                         *ngIf="item.NoOfPayments != item.PaymentsDone" class="pay-order m-l-5">{{utils.getString('pay_order')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>