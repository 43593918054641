import { Injectable } from '@angular/core';
import { config } from '../config';
import { Api } from '../http/Api';

@Injectable({
  providedIn: 'root'
})
export class CarsService {

  controller:string = 'Cars';

  constructor(private api: Api) { }

  getTopCars() {
    return this.api.get(`${this.controller}/top-cars`);
  }

  getTopFeaturedCars() {
    return this.api.get(`${this.controller}/top-featured-cars`);
  }

  getTopLux(top) {
    return this.api.get(`${this.controller}/top-lux?top=${top}`);
  }

  getSearchCars(q) {
    return this.api.get(`${this.controller}/search-cars?q=${q}`);
  }

  hireAnExpert(order) {
    return this.api.post(`${this.controller}/hire-an-expert`, order);
  }

  sparePartOrder(order) {
    return this.api.post(`${this.controller}/spare-part-order`, order);
  }

  manageCar(car) {
    return this.api.post(`${this.controller}/manage-car-v2`, car);
  }

  getCarToEdit(id) {
    return this.api.get(`${this.controller}/get-car-to-edit?id=${id}`);
  }

  prepareAddCar() {
    return this.api.get(`${this.controller}/prepare-add-car`);
  }

  getCar(id) {
    return this.api.get(`${this.controller}/get-car?id=${id}`);
  }

  getRelatedCars(id) {
    return this.api.get(`${this.controller}/get-related-cars?id=${id}`);
  }

  getFavs(ids) {
    return this.api.get(`${this.controller}/get-favs?ids=${ids}`);
  }

  trackPrice(order) {
    return this.api.post(`${this.controller}/track-price`, order);
  }

  reserveCar(order) {
    return this.api.post(`${this.controller}/reserve-car`, order);
  }

  testDriveRequest(order) {
    return this.api.post(`${this.controller}/test-drive-request`, order);
  }

  makeAnOffer(order) {
    return this.api.post(`${this.controller}/make-an-offer`, order);
  }

  buyOnline(car) {
    return this.api.post(`${this.controller}/create-buy-car-order`, car);
  }
}
