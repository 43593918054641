import { BrowserModule, TransferState, makeStateKey, StateKey } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './http/interceptor/httpconfig.interceptor';
import { Utils } from './classes/utils';
import { Api } from './http/Api';
import { AppRoutingModule } from './app-routing.module';
import { ServicesModuleModule } from './services-module.module';
import { MaterialModule } from './material-module.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingComponent } from './pages/landing/landing.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { SliderComponent } from './components/slider/slider.component';
import { MakesInputComponent } from './components/makes-input/makes-input.component';
import { QuickSearchComponent } from './components/quick-search/quick-search.component';
import { UsedCarsComponent } from './pages/used-cars/used-cars.component';
import { SellCarComponent } from './pages/sell-car/sell-car.component';
import { CarFinancingComponent } from './pages/car-financing/car-financing.component';
import { HireAnExpertComponent } from './pages/hire-an-expert/hire-an-expert.component';
import { BuySparePartsComponent } from './pages/buy-spare-parts/buy-spare-parts.component';
import { PageComponent } from './pages/page/page.component';
import { FaqComponent } from './pages/faq/faq.component';
import { SelectAreaComponent } from './components/select-area/select-area.component';
import { OtpComponent } from './components/otp/otp.component';
import { CarItemComponent } from './components/car-item/car-item.component';
import { CarComponent } from './pages/car/car.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { LoginComponent } from './components/login/login.component';
import { AccountComponent } from './pages/account/account.component';
import { ProfileComponent } from './pages/account/profile/profile.component';
import { MyCarsComponent } from './pages/account/my-cars/my-cars.component';
import { ManageCarsComponent } from './pages/account/manage-cars/manage-cars.component';
import { ExpertOrdersComponent } from './pages/account/expert-orders/expert-orders.component';
import { MyQoutesComponent } from './pages/account/my-qoutes/my-qoutes.component';
import { RecievedQoutesComponent } from './pages/account/recieved-qoutes/recieved-qoutes.component';
import { MySparePartOrdersComponent } from './pages/account/my-spare-part-orders/my-spare-part-orders.component';
import { NotificationssComponent } from './pages/account/notificationss/notificationss.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DashboardComponent } from './pages/account/dashboard/dashboard.component';
import { ManageCarComponent } from './pages/manage-car/manage-car.component';
import { HasFeaturePipe } from './pipes/has-feature.pipe';
import { KzFilterPipe } from './pipes/kz-filter.pipe';
import { FavsComponent } from './pages/favs/favs.component';
import { ReservedCarsComponent } from './pages/account/reserved-cars/reserved-cars.component';
import { LuxuryCarsForRentComponent } from './pages/luxury-cars-for-rent/luxury-cars-for-rent.component';
import { LuxItemComponent } from './components/lux-item/lux-item.component';
import { BlogItemComponent } from './components/blog-item/blog-item.component';
import { BlogComponent } from './pages/blog/blog.component';
import { ArticleComponent } from './pages/article/article.component';
import { MultiUploadComponent } from './components/multi-upload/multi-upload.component';
import { ModelsInputComponent } from './components/models-input/models-input.component';
import { BuyOrdersComponent } from './pages/account/buy-orders/buy-orders.component';
import { BuyPaymentsComponent } from './pages/account/buy-orders/buy-payments/buy-payments.component';

@NgModule({
  declarations: [
    AppComponent,   
    LandingComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    SliderComponent,
    MakesInputComponent,
    QuickSearchComponent,
    UsedCarsComponent,
    SellCarComponent,
    CarFinancingComponent,
    HireAnExpertComponent,
    BuySparePartsComponent,
    PageComponent,
    FaqComponent,
    SelectAreaComponent,
    OtpComponent,
    CarItemComponent,
    CarComponent,
    SkeletonComponent,
    LoginComponent,
    AccountComponent,
    ProfileComponent,
    MyCarsComponent,
    ManageCarsComponent,
    ExpertOrdersComponent,
    MyQoutesComponent,
    RecievedQoutesComponent,
    MySparePartOrdersComponent,
    NotificationssComponent,
    DashboardComponent,
    ManageCarComponent,
    HasFeaturePipe,
    KzFilterPipe,
    FavsComponent,
    ReservedCarsComponent,
    LuxuryCarsForRentComponent,
    LuxItemComponent,
    BlogItemComponent,
    BlogComponent,
    ArticleComponent,
    MultiUploadComponent,
    ModelsInputComponent,
    BuyOrdersComponent,
    BuyPaymentsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    //BrowserTransferStateModule,
    ServicesModuleModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot()    
  ],
  providers: [Api, Utils, 
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
