import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Utils } from '../classes/utils';
import { config } from '../config';
import jwt_encode from "jwt-encode";
import jwt_decode from "jwt-decode";

@Injectable()
export class Api {
  public _routeParams: any;

  constructor(private http: HttpClient, private utils: Utils) { }

  getTimeZone():number{
    let offset = new Date().getTimezoneOffset();
    let timeZone = offset / 60;
    timeZone = -(timeZone);
    return timeZone;
}

  getDeviceId() {
    if(this.utils.isBrowser()){
      if(localStorage.device_id){
        return localStorage.device_id;
      } else {
        localStorage.device_id = this.getUUID();
        return localStorage.device_id;
      }
    } else {
      return '00000000-0000-0000-0000-000000000000';
    }
  }

  getUUID() {
    return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }

getToken() {
  let token = {
    "UniqueID": '00000000-0000-0000-0000-000000000000',
    "SessionID": '00000000-0000-0000-0000-000000000000',
    "country": "AE",
    "currency": "USD",
    "uuid": this.getUUID(),
    "DeviceID": this.getDeviceId(),
    "UserToken": '00000000-0000-0000-0000-000000000000',
    'gmt': '0',
    'lang': 'en'
  };
  if(!this.utils.isLoggedIn()){
      this.utils.doLogout();
  }
  if(this.utils.isBrowser()){
    token.UniqueID = (localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '00000000-0000-0000-0000-000000000000');
    token.lang = (localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en');
    token.DeviceID = this.getDeviceId();
    token.SessionID = navigator.userAgent;
    //token.country = (localStorage.getItem('country') ? localStorage.getItem('country') : '-');
    token.country = 'AE';
    token.currency = (localStorage.getItem('currency') ? localStorage.getItem('currency') : 'USD');
    token.UserToken = (localStorage.getItem('userToken') ? localStorage.getItem('userToken') : '00000000-0000-0000-0000-000000000000');
    token.gmt = this.getTimeZone() + '';
  }
  const secret = 'kinzi-workspace';            
  const jwt = jwt_encode(token, secret);                      
  return jwt;   
}

  async get(apiName, data?, url?, headers?): Promise<any> {
    return this._get(apiName, data, url, headers).toPromise();
  }

  async post(apiName, data?, url?, headers?): Promise<any> {
    return this._post(apiName, data, url, headers).toPromise();
  }

  async put(apiName, data?, url?, headers?): Promise<any> {
    return this._put(apiName, data, url, headers).toPromise();
  }

  async delete(apiName, data?, url?, headers?): Promise<any> {
    return this._delete(apiName, data, url, headers).toPromise();
  }

  private _get(apiName, data?, url?, customHeaders?) {
    let _headers;
    _headers = this.getHeaders(customHeaders);
    let headers = new HttpHeaders(_headers);
    let callUrl = '';
    if (apiName) {
      callUrl = config.apiUrl + apiName;
    } else {
      callUrl = url;
    }
    if (data) {
      data = '?' + data;
      callUrl = callUrl + data;
    }
    return this.http.get(callUrl, {
      headers: headers
    });
  }

  private _post(apiName, data, url?, customHeaders?) {
    let callUrl = '';
    if (apiName) {
      callUrl = config.apiUrl + apiName;
    } else {
      callUrl = url;
    }
    let _headers = this.getHeaders(customHeaders);
    let _data = {};
    if (data) _data = data;
    let headers = new HttpHeaders(_headers);
    return this.http.post(callUrl, JSON.stringify(_data), {
      headers: headers
    });
  }

  private _put(apiName, data, url?, customHeaders?) {
    let callUrl = '';
    if (apiName) {
      callUrl = config.apiUrl + apiName;
    } else {
      callUrl = url;
    }
    let _headers = this.getHeaders(customHeaders);
    let _data = {};
    if (data) _data = data;
    let headers = new HttpHeaders(_headers);
    return this.http.put(callUrl, JSON.stringify(_data), {
      headers: headers
    });
  }

  private _delete(apiName, data, url?, customHeaders?) {
    let _headers = this.getHeaders();
    let headers = new HttpHeaders(_headers);
    let callUrl = '';
    if (apiName) {
      callUrl = config.apiUrl + apiName;
    } else {
      callUrl = url;
    }
    return this.http.delete(callUrl, {
      headers: headers
    });
  }

  getHeaders(customHeaders?) {
    let _headers = {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + this.getToken(),
      'env': (config.isProduction ? 'prod' : 'test'),
      "node": (this.utils.isBrowser() ? 'client' : 'server')
  };
    if (customHeaders) {
      let customHeaderKeys = Object.keys(customHeaders);
      for (var i = 0; i < customHeaderKeys.length; i++) {
        _headers[customHeaderKeys[i]] = customHeaders[customHeaderKeys[i]];
      }
    }
    return _headers;
  }

  customUpload(file): Promise<any> {
    let headers = new HttpHeaders();
    let formData: FormData = new FormData();
    formData.append("file", file); 
    formData.append("UPLOADCARE_PUB_KEY", "3145fb813e3dff2bb861"); 
    formData.append("UPLOADCARE_STORE", "1"); 
    return this.http.post('https://upload.uploadcare.com/base/', formData, {
        headers: headers
      }).toPromise();
  }
}
