var en_country = 'UAE';
var ar_country = 'الامارات';

export const resx = {
  ac_type_1: {
      en: `Individual Account`,
      ar: `حساب فردي`
  },
  ac_type_2: {
      en: `Dealer Account`,
      ar: `حساب تاجر`
  },
  lang: {
      en: `العربية`,
      ar: `English`
  },
  sell_your_car: {
    en: `Sell a Car`,
    ar: `بيع سيارة`
  },
  sell_your_car_mob: {
      en: `Sell`,
      ar: `بيع`
  },
  sell_my_car: {
      en: `Sell my Car`,
      ar: `بيع سيارتي`
  },
  cars_home: {
    en: `Home`,
    ar: `الرئيسية`
  },
  buy_car: {
      en: `Buy Car`,
      ar: `اشتري سيارة`
  },
  buy_car_mob: {
      en: `Buy`,
      ar: `اشتري`
  },
  buy_used_cars: {
    en: `Used Cars `,
    ar: `سيارات مستعملة`
  },
  login: {
      en: `Log in`,
      ar: `دخول`
  },
  featured: {
      en: `Featured Used Cars`,
      ar: `سيارات مستعملة مميزة`
  },
  get_app: {
    en: `Download Go Cars app now!`,
    ar: `قم بتحميل تطبيق حو كارز الآن!`
  },
  our_choice: {
      en: `Our choice for you today!`,
      ar: `إخترنا لك اليوم!`
  },
  makes_search: {
      en: `Search by make, model`,
      ar: `ابحث عن ماركة, موديل`
  },
  search: {
      en: `Search`,
      ar: `بحث`
  },
  browse: {
      en: `Browse Used Cars in ${en_country}`,
      ar: `تصفح سيارات مستعملة في ${ar_country}`
  },
  cancel: {
      en: `Cancel`,
      ar: `إلغاء`
  },
  makes: {
      en: `Make`,
      ar: `الماركة`
  },
  models: {
      en: `Model`,
      ar: `الموديل`
  },
  cities: {
      en: `City`,
      ar: `المدينة`
  },
  prices: {
      en: `Price`,
      ar: `السعر`
  },
  price: {
      en: `Price`,
      ar: `السعر`
  },
  bodyTypes: {
      en: `Body Type`,
      ar: `الجسم`
  },
  years: {
      en: `Year`,
      ar: `السنة`
  },
  our_services: {
      en: `Buying or Selling? We can Help`,
      ar: `شراء أم بيع؟ نستطيع مساعدتك`
  },
  used_car_in: {
      en: `Used Cars for sale in`,
      ar: `سيارات مستعملة للبيع في`
  },
  sell_car_in: {
      en: `Sell Any Car in`,
      ar: `بيع سيارتك في`
  },
  cur: {
      en: `AED`,
      ar: `درهم`
  },
  site_country: {
      en: `${en_country}`,
      ar: `${ar_country}`
  },
  km: {
      en: `KM`,
      ar: `كم`
  },
  terms_and_conditions: {
      en: `Terms & Conditions`,
      ar: `الشروط والأحكام`
  },
  privacy_policy: {
      en: `Privacy Policy`,
      ar: `سياسة الخصوصية`
  },
  month: {
      en: `month`,
      ar: `شهر`
  },
  view_all_cars: {
      en: `View All Cars`,
      ar: `عرض جميع السيارات`
  },
  about_go_cars: {
    en: `About Go Cars`,
    ar: `عن Go Cars`
  },
  used_cars: {
    en: `Used Cars`,
    ar: `سيارات مستعملة`
  },
  buy_spare_parts: {
    en: `Buy Spare Parts`,
    ar: `اشتري قطع غيار`
  },
  hire_an_expert: {
    en: `Hire an Expert`,
    ar: `خدمة خبير السيارات`
  },
  need_help: {
    en: `Need Help?`,
    ar: `تحتاح إلى مساعدة؟`
  },
  order_now: {
    en: `New Order`,
    ar: `طلب جديد`
  },
  go_cars_inspection: {
    en: `GoCars Inspection`,
    ar: `فحص Go Cars`
  },
  contact_us: {
    en: `Contact Us`,
    ar: `اتصل بنا`
  },
  faq: {
    en: `FAQ`,
    ar: `الأسئلة الشاشعة`
  },
  car_financing: {
    en: `Car Financing`,
    ar: `تمويل السيارات`
  },
  price_drop: {
    en: `price drop`,
    ar: `انخفاض السعر`
  },
  drop: {
    en: `drop`,
    ar: `انخفاض`
  },
  sold: {
    en: `SOLD`,
    ar: `تم البيع`
  },
  sort_by: {
    en: `Sort by`,
    ar: `ترتيب حسب`
  },
  sort_by_latest: {
    en: `Latest`,
    ar: `الأحدث`
  },
  sort_by_lowprice: {
    en: `Price (Low to High)`,
    ar: `السعر (الأقل إلى الأعلى)`
  },
  sort_by_highprice: {
    en: `Price (High to Low)`,
    ar: `السعر (الأعلى إلى الأقل)`
  },
  sort_by_lowmilage: {
    en: `Milage (Low to High)`,
    ar: `الأميال (الأقل إلى الأعلى)`
  },
  sort_by_highmilage: {
    en: `Milage (High to Low)`,
    ar: `الأميال (الأعلى إلى الأقل)`
  },
  sort_by_lowyear: {
    en: `Year (Low to High)`,
    ar: `السنة (الأقل إلى الأعلى)`
  },
  sort_by_highyear: {
    en: `Year (High to Low)`,
    ar: `السنة (الأعلى إلى الأقل)`
  },
  fl_full_price: {
    en: `Full Price`,
    ar: `السعر كامل`
  },
  fl_monthly_price: {
    en: `Monthly Price`,
    ar: `السعر الشهري`
  },
  fl_range_price: {
    en: `Price`,
    ar: `السعر`
  },
  fl_milage_price: {
    en: `Milage`,
    ar: `الأميال`
  },
  fl_year_price: {
    en: `Year`,
    ar: `السنة`
  },
  fl_total_records: {
    en: `Showing`,
    ar: `عرض`
  },
  fl_total_records2: {
    en: `used cars`,
    ar: `سيارات`
  },
  fl_city: {
    en: `City`,
    ar: `المدينة`
  },
  fl_transmission: {
    en: `Transmission`,
    ar: `ناقل الحركة`
  },
  fl_fuel: {
    en: `Fuel`,
    ar: `نوع الوقود`
  },
  fl_price_guidance: {
    en: `Price guidance`,
    ar: `توصيات السعر`
  },
  fl_color: {
    en: `Color`,
    ar: `اللون`
  },
  fl_body_type: {
    en: `Body Type`,
    ar: `الجسم`
  },
  fl_need_maintenance: {
    en: `Damaged Car`,
    ar: `سيارة مصدومة`
  },
  fl_specs_type: {
    en: `Specifications`,
    ar: `المواصفات`
  },
  vin: {
    en: `VIN / Chassis Number`,
    ar: `رقم القاعدة`
  },
  fl_seller_type: {
    en: `Seller Type`,
    ar: `نوع البائع`
  },
  fl_seller_type_1: {
    en: `Individual`,
    ar: `بائع فرد`
  },
  fl_seller_type_2: {
    en: `Dealer`,
    ar: `بائع تاجر`
  },
  fl_badges: {
    en: `Badges`,
    ar: `شارات مميزة`
  },
  fl_clearall: {
    en: `Clear all`,
    ar: `مسح الكل`
  },
  fl_filters: {
    en: `Filters`,
    ar: `خيارات البحث`
  },
  fl_no_cars_found_t: {
    en: `We are sorry!`,
    ar: `نعتذر!`
  },
  fl_no_cars_found: {
    en: `We didn\`t find any cars that match your search`,
    ar: `لم نجد أي سيارة تطابق بحثك`
  },
  fl_features: {
    en: `Features`,
    ar: `المواصفات`
  },
  meta_for_sale_in: {
    en: `for sale in`,
    ar: `مستعملة للبيع في`
  },
  meta_city_car_in: {
    en: `Used Cars for sale in`,
    ar: `سيارات مستعملة للبيع في`
  },
  meta_used_cars: {
    en: `Used Cars for sale in`,
    ar: `سيارات مستعملة للبيع في`
  },
  login_or_signup: {
    en: `Log in or sign up`,
    ar: `دخول أو تسجيل حساب`
  },
  tryagain: {
    en: `Try again!`,
    ar: `حاول مرة أخرى`
  },
  your_mobile: {
    en: `Mobile Number`,
    ar: `رقم الجوال`
  },
  your_preferred_language: {
    en: `Preferred Language`,
    ar: `اللغة المفضلة`
  },
  your_preferred_language_hint: {
    en: `Emails and notifications language`,
    ar: `لغة رسائل البريد الإلكتروني والإشعارات`
  },
  send_otp: {
    en: `Send OTP`,
    ar: `أرسل رمز التحقق`
  },
  verify_otp: {
    en: `Verify OTP`,
    ar: `تحقق من الرمز`
  },
  otp: {
    en: `Enter the OTP sent to you at`,
    ar: `أدخل رمز التحقق الذي تم إرساله لك على`
  },
  save: {
    en: `SAVE`,
    ar: `حفظ`
  },
  logout: {
    en: `Logout`,
    ar: `تسجيل الخروج`
  },
  please_select: {
    en: `Please select`,
    ar: `يرجى الإختيار`
  },
  confirm_logout: {
    en: `Are you sure you want to logout?`,
    ar: `هل أنت متأكد أنك تريد تسجيل الخروج؟`
  },
  invalidMobile: {
    en: `Mobile Number must be 9 digits starting with 5`,
    ar: `رقم الهاتف يجب أن يتكون من 9 أرقام تبدأ بـ5`
  }, 
  invalidOTP: {
    en: `Invalid or expired OTP`,
    ar: `كود التحقق خاطئ أو منتهي الصلاحية`
  }, 
  resend:{
    en: `Resend Code`,
    ar: `إعادة إرسال الرمز`
  },
  your_fname: {
    en: `First Name`,
    ar: `الإسم الأول`
  },
  your_lname: {
    en: `Last Name`,
    ar: `الإسم الأخير`
  },
  your_email: {
    en: `Email Address`,
    ar: `البريد الإلكتروني`
  },
  signup: {
    en: `Create an account`,
    ar: `تسجيل حساب جديد`
  },
  invalidEmail: {
    en: `Invalid email address`,
    ar: `البريد الإلكتروني الذي أدخلته غير صحيح`
  }, 
  requiredRegistrationFields: {
      en: `Please enter all required fields`,
      ar: `يرجى إدخال جميع الحقول الإجبارية`
  }, 
  requiredAddressFields: {
      en: `Please enter all required fields`,
      ar: `يرجى إدخال جميع الحقول الإجبارية`
  }, 
  requiredFields: {
      en: `Please enter all required fields`,
      ar: `يرجى إدخال جميع الحقول الإجبارية`
  }, 
  mobileExist: {
      en: `The Mobile Number you enterd is already in use`,
      ar: `رقم الجوال الذي أدخلته مستخدم مسبقاً`
  }, 
  emailExist: {
      en: `The Email Address you enterd is already in use`,
      ar: `البريد الإلكتروني الذي أدخلته مستخدم مسبقاً`
  }, 
  mu_account_link: {
    en: `My Account`,
    ar: `حسابي`
  }, 
  ac_notifications: {
    en: `Notifications`,
    ar: `الإشعارات`
  }, 
  ac_favourites: {
    en: `Favourites`,
    ar: `المفضلة`
  },  
  ac_reserved_cars: {
    en: `Reserved Cars`,
    ar: `السيارات المحجوزة`
  },  
  your_favourites: {
    en: `Your Favourites`,
    ar: `المفضلة`
  }, 
  no_favourites: {
    en: `No cars in your Favourites list yet`,
    ar: `لا يوجد سيارات في قائمة المفضلة حتى الآن`
  }, 
  browse_cars: {
    en: `Browse Cars`,
    ar: `تصفح السيارات`
  },  
  ac_my_dashboard: {
    en: `Dashboard`,
    ar: `لوحة القيادة`
  }, 
  ac_my_profile: {
    en: `My Profile`,
    ar: `حسابي`
  }, 
  ac_cars: {
    en: `My Cars`,
    ar: `سياراتي`
  }, 
  ac_expert_appoitments: {
    en: `Expert Appointments`,
    ar: `مواعيد خبير السيارات`
  }, 
  ac_my_qoutes: {
    en: `Offers`,
    ar: `العروض`
  }, 
  ac_recieved_qoutes: {
    en: `Offers Received`,
    ar: `العروض المستلمة`
  }, 
  ac_space_parts_order: {
    en: `Spare Part Orders`,
    ar: `طلبات قطع السيارات`
  }, 
  ac_menu: {
    en: `MENU`,
    ar: `القائمة`
  }, 
  reserved_until: {
    en: `Reserved Until`,
    ar: `محجوزة لغاية`
  }, 
  reserved_car_price: {
    en: `Car Price`,
    ar: `سعر السيارة`
  }, 
  reserved_price: {
    en: `Paid amount`,
    ar: `المبلغ المدفوع`
  }, 
  expiry_date: {
    en: `Ad Expiry Date`,
    ar: `تاريخ نهاية الإعلان`
  }, 
  no_expiry: {
    en: `-No Expiry Date-`,
    ar: `-لا يوجد تاريخ نهاية-`
  }, 
  ad_will_expire: {
    en: `Your ad will remain for 30 days`,
    ar: `سيبقى إعلانك لمدة 30 يومًا`
  }, 
  ad_no_expire: {
    en: `Your ad will remain until we sell you car`,
    ar: `سيبقى إعلانك حتى نبيع لك سيارتك`
  }, 
  city: {
    en: `City`,
    ar: `المدينة`
  }, 
  area: {
    en: `Area`,
    ar: `المنط قة`
  }, 
  no_of_bookings: {
    en: `Number of Visits`,
    ar: `عدد زيارات الخبير`
  }, 
  one_visit: {
    en: `1 Visit`,
    ar: `زيارة واحدة`
  }, 
  three_visits: {
    en: `3 Visits`,
    ar: `3 زيارات`
  }, 
  first_visit: {
    en: `First Visit Date`,
    ar: `تاريخ الزيارة الأولى`
  }, 
  second_visit: {
    en: `Second Visit Date`,
    ar: `تاريخ الزيارة الثانية`
  }, 
  third_visit: {
    en: `Third Visit Date`,
    ar: `تاريخ الزيارة الثالثة`
  }, 
  timeslot: {
    en: `Timeslot`,
    ar: `وقت الزيارة`
  }, 
  pay_now: {
    en: `PAY NOW`,
    ar: `إدفع الآن`
  }, 
  submit_spare_request: {
    en: `SUBMIT ORDER`,
    ar: `أرسل الطلب`
  }, 
  submit_price_offer: {
    en: `Submit Offer`,
    ar: `أرسل العرض`
  }, 
  drag_to_change: {
    en: `Drag to change price`,
    ar: `إسحب لتغيير السعر`
  }, 
  offer_date: {
    en: `Offer date`,
    ar: `تاريخ العرض`
  }, 
  offer_amount: {
    en: `Offer amount`,
    ar: `قيمة العرض`
  }, 
  trim: {
    en: `Class / Trim (optional)`,
    ar: `فئة السيارة (اختياري)`
  }, 
  year: {
    en: `Year`,
    ar: `سنة الصنع`
  }, 
  part_name: {
    en: `Part Name`,
    ar: `القطعة المطلوبة`
  }, 
  part_number: {
    en: `Part Number (optional)`,
    ar: `رقم القطعة (اختياري)`
  }, 
  spare_car: {
    en: `Make & Model`,
    ar: `الماركة والموديل`
  }, 
  tell_us_part: {
    en: `Tell us about the spare part you want`,
    ar: `أخبرنا عن القطعة التي تريدها`
  }, 
  tell_us_car: {
    en: `Tell us about your car`,
    ar: `أخبرنا عن سيارتك`
  }, 
  tell_us_location: {
    en: `Where do you want it to be delivered?`,
    ar: `أين تريد أن تصلك القطعة`
  }, 
  paid: {
    en: `Paid`,
    ar: `مدفوع`
  }, 
  warranty: {
    en: `Warranty`,
    ar: `الضمان`
  }, 
  pay_order: {
    en: `Pay Now`,
    ar: `إدفع الآن`
  }, 
  pending_order: {
    en: `Processing`,
    ar: `قيد الإجراء`
  }, 
  open: {
    en: `Open`,
    ar: `عرض`
  }, 
  continue: {
    en: `CONTINUE`,
    ar: `متابعة`
  }, 
  sell_by_error: {
    en: `Please choose how do you want to sell your car`,
    ar: `يرجى إختيار كيف تريد بيع سيارتك`
  }, 
  select_date: {
    en: `Select Date`,
    ar: `قم بإختيار التاريخ`
  }, 
  select_time: {
    en: `Select Time`,
    ar: `الوقت`
  }, 
  main_photo: {
    en: `Main Photo`,
    ar: `الصورة الرئيسية`
  }, 
  main_photo_recommended: {
    en: `recommended size 1024px X 768px`,
    ar: `أفضل حجم 1024px X 768px`
  }, 
  additional_photos: {
    en: `Photos`,
    ar: `الصور`
  }, 
  additional_photos_min: {
    en: `Add at least 3 photos`,
    ar: `أضِف 3 صور على الأقل`
  }, 
  additional_photos_error: {
    en: `Please upload at least 3 additional photos`,
    ar: `أضِف 3 صور على الأقل`
  }, 
  published: {
    en: `Published`,
    ar: `منشور`
  }, 
  sold_car: {
    en: `Sold`,
    ar: `تم البيع`
  }, 
  missing: {
    en: `Missing`,
    ar: `لم يتم إدخال`
  }, 
  edit: {
    en: `Edit`,
    ar: `تعديل`
  }, 
  add_car: {
    en: `Add Car`,
    ar: `إضافة سيارة`
  }, 
  when_inspect: {
    en: `When should we inspect the car?`,
    ar: `متى يمكننا فحص السيارة؟`
  }, 
  max_limit: {
    en: `You reached the maximum number of live car ads in your account`,
    ar: `لقد وصلت إلى الحد الأقصى لعدد إعلانات السيارات في حسابك`
  },
  vc_down_paymeny: {
    en: `Down Payment`,
    ar: `الدفعة الأولى`
  }, 
  vc_interested: {
    en: `Interested in this car?`,
    ar: `مهتم بهذه السيارة`
  }, 
  vc_test_drive: {
    en: `Test Drive (FREE)`,
    ar: `تجربة القيادة (مجاناً)`
  }, 
  vc_test_drive_date: {
    en: `When are you available?`,
    ar: `عندما تكون متفرغا؟`
  }, 
  vc_test_drive_date_hint: {
    en: `We will check with the seller and confirm`,
    ar: `سنقوم بتأكيد الموعد مع البائع والرد عليك بأسرع وقت ممكن`
  }, 
  vc_test_drive_time: {
    en: `Pick a time`,
    ar: `حدد الوقت`
  }, 
  vc_test_drive_order: {
    en: `Submit Request`,
    ar: `أرسل طلبك`
  }, 
  vc_reserve_car: {
    en: `Reserve Car`,
    ar: `احجز السيارة`
  }, 
  vc_test_success: {
    en: `We received your Test Drive request`,
    ar: `لقد تلقينا طلبك لتجربة القيادة`
  }, 
  vc_offer_success: {
    en: `We received your Offer`,
    ar: `لقد تلقينا العرض`
  }, 
  vc_test_confirm: {
    en: `We will contact the seller and confirm your appointment`,
    ar: `سنقوم بالتواصل مع البائع ونؤكد موعدك`
  }, 
  vc_offer_confirm: {
    en: `We will contact the seller and get back to you shortly`,
    ar: `سنقوم بالتواصل مع البائع والرد عليك بأسرع وقت ممكن`
  }, 
  vc_make_an_offer: {
    en: `Make an offer`,
    ar: `قدّم عرض سعر`
  }, 
  vc_buy_online: {
    en: `Buy Online Now`,
    ar: `إشتري السيارة ألآن`
  }, 
  vc_show_phone_number: {
    en: `Show Phone Number`,
    ar: `أظهر رقم الهاتف`
  }, 
  vc_track_price: {
    en: `Track Price`,
    ar: `تتبع السعر`
  }, 
  enter_amount: {
    en: `Enter amount`,
    ar: `أدخل المبلغ`
  }, 
  notify_me: {
    en: `Notify Me`,
    ar: `تتبع السعر`
  }, 
  we_will_notify_you: {
    en: `Thank you, We will notify you once the price dropped`,
    ar: `شكراً لك, سنقوم بتنبيهك عندما ينخفض السعر`
  }, 
  vc_posted: {
    en: `Posted on`,
    ar: `نشر بتاريخ`
  }, 
  vc_additional_info: {
    en: `Additional Details`,
    ar: `تفاصيل إضافية`
  }, 
  vc_view_all_additional_info: {
    en: `View All`,
    ar: `عرض الكل`
  }, 
  vc_features: {
    en: `Car Features`,
    ar: `مزايا السيارة`
  }, 
  vc_view_all_features: {
    en: `All Features`,
    ar: `جميع المزايا`
  }, 
  similar_cars: {
    en: `Other Similar Used Cars`,
    ar: `سيارات أخرى مشابهة`
  },
  inspection_report: {
    en: `Inspection Report`,
    ar: `تقرير فحص السيارة`
  },  
  learn_more: {
    en: `Learn more`,
    ar: `اعرف المزيد`
  },  
  sure_to_reserve: {
    en: `Do want to reserve this car for 7 days?`,
    ar: `هل تريد حجز هذه السيارة لمدة 7 أيام؟`
  }, 
  already_reserved: {
    en: `This car is already reserved`,
    ar: `هذه السيارة محجوزة مسبقاً`
  }, 
  rent_luxury: {
    en: `Rent Luxury Cars in ${en_country}`,
    ar: `استأجر سيارات فاخرة في ${ar_country}`
  }, 
  confirm_and_pay: {
    en: `Confirm & Pay`,
    ar: `التأكيد والدفع`
  },  
  lux: {
    en: `Luxury Cars for Rent`,
    ar: `سيارات فاخرة للايجار`
  },  
  top_lux: {
    en: `Luxury Cars for Rent in ${en_country}`,
    ar: `سيارات فاخرة للايجار في ${ar_country}`
  },  
  lux_price: {
    en: `Starting from`,
    ar: `تبدأ من`
  }, 
  lux_per_day: {
    en: `/ Day`,
    ar: `/ اليوم`
  },  
  lux_msg: {
    en: `Message`,
    ar: `رسالة`
  },  
  lux_call: {
    en: `Call`,
    ar: `اتصل`
  },  
  blog: {
    en: `Blog`,
    ar: `المدونة`
  }, 
  blog_top: {
    en: `New on our Blog`,
    ar: `جديد في المدونة`
  },  
  blog_view_all: {
    en: `View More`,
    ar: `عرض المزيد`
  }, 
  blog_title: {
    en: `Go Cars Blog`,
    ar: `مدونة جو كارز`
  }, 
  blog_all: {
    en: `All`,
    ar: `الكل`
  }, 
  blog_more: {
    en: `More on our blog`,
    ar: `المزيد في المدونة`
  }, 
  about_this_car: {
    en: `Articles about this car`,
    ar: `مقالات عن هذه السيارة`
  }, 
  nup_negotiable: {
    en: `Negotiable`,
    ar: `قابل للتفاوض`
  }, 
  nup_mobile_to_display: {
    en: `Mobile Number`,
    ar: `رقم الجوال`
  }, 
  nup_price_rate: {
    en: `Price guidance`,
    ar: `دليل السعر`
  }, 
  nup_no_of_keys: {
    en: `Number of Keys`,
    ar: `عدد المفاتيح`
  }, 
  nup_color: {
    en: `Color`,
    ar: `اللون`
  }, 
  buy_online: {
    en: `Purchased Cars`,
    ar: `السيارات المشتراة`
  }, 
  back: {
    en: `Back`,
    ar: `رجوع`
  }, 
}