import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../classes/utils';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';
import { CarsService } from '../../services/cars.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { config } from '../../config';
import * as $ from 'jquery';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-manage-car',
  templateUrl: './manage-car.component.html',
  styleUrls: ['./manage-car.component.less']
})
export class ManageCarComponent implements OnInit {

  step:string = 'step-1';
  progress:number = 0;
  accountType:number = 1;
  lang:string = '';
  isBrowser:boolean;
  webPage:any = {};  
  isLoggedIn:boolean;
  isLoaded:boolean = false;
  htmlContent:any;
  section = [];
  isLoading:boolean = false;
  options:any = {};
  filtered_models = [];
  timeslots = [];
  fees:any = {};
  minDate:Date;
  maxDate:Date;
  car:any = {
    MMID: '',
    CarID: -1,
    IsPublished: true,
    IsFeatured: false,
    IsSold: false,
    SellBy: 1,
    CityID: null,
    AreaID: null,
    Make: null,
    Model: null,
    Class: null,
    CYear: null,
    BodyType: -999,
    Specs: -999,
    VIN: null,
    Transmission: -999,
    Milage: null,
    Fuel: -999,
    MainPhoto: '',
    Photos: '',
    Price: null,
    Warranty: -999,
    AppointmentTime: null,
    AppointmentDate: null,
    IsPaid: 0,
    NeedMaintenance: 0,
    NoOfKeys: 2,
    PriceRate: -1,
    Negotiable: 0,
    MobileToDisplay: '',
    Color: -999
  };
  cityOptions:IDropdownSettings = {};
  areaOptions:IDropdownSettings = {};
  makeOptions:IDropdownSettings = {};
  modelOptions:IDropdownSettings = {};
  cities:any[] = [];
  all_areas:any[] = [];
  areas:any[] = [];
  uploadedPhotos = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private carsService: CarsService,
    private metaTagService: Meta,
    private pageTitle: Title,
    private userService: UserService,
    private service: SharedService,
    private sanitizer: DomSanitizer) {
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();   
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;
        if(!_isLoggedIn){
          this.router.navigate([`/${this.lang}/home`]);
        }
      });  
      this.activatedRoute.queryParams.subscribe(async params => {
       if(params['id'] && params['sellBy']){
        this.car.CarID = +params['id'];
        this.car.SellBy = +params['sellBy'];        
        if(this.car.SellBy != 1 && this.car.SellBy != 2){
          this.router.navigate([`/${this.lang}/home`]);
        }
       } else {
        this.router.navigate([`/${this.lang}/home`]);
       }
       if(this.isLoggedIn){
        this.userService.getProfile().then(data => {
          this.accountType = data[0].AccountType;
        });
       }
      });       
   }

   ngOnInit(): void {
    this.minDate = new Date();
    this.minDate.setDate( this.minDate.getDate() + 2 );
    this.maxDate = new Date();
    this.maxDate.setDate( this.maxDate.getDate() + 2 + 8 );
    this.getMetaTags('manage-car');
    this.getData();
    if(this.isBrowser){
      let self = this;
      window['triggerUpload'] = function(){
        $('input#uploader').click();
      }
    }
  }

  async getData(){
    this.isLoading = true;
    await this.getCitiesAndAreas();
    this.options = await this.prepareAddCar();    
    this.makesDdl();
    if(this.car.CarID != -1){
      let car = await this.getCar();
      if(car.CarID == -3){
        this.router.navigate([`/${this.lang}/home`]);
      } else {
        this.car = JSON.parse(JSON.stringify(car));
        this.car.MMID = `${car.Make}:${car.Model}`;
        let uploadedPhotosTEMP:Array<any> = [];
        uploadedPhotosTEMP.push(this.car.MainPhoto);
        uploadedPhotosTEMP = uploadedPhotosTEMP.concat(this.car.Photos.split('###'));
        this.uploadedPhotos = uploadedPhotosTEMP
        this.car.MakeObj = this.options.makes.filter(function(el){
          return el.MakeID == car.Make
        })[0];
        this.selectMake(this.car.MakeObj);
        this.car.ModelObj = this.filtered_models.filter(function(el){
          return el.ModelID == car.Model
        })[0];
        this.selectModel(this.car.ModelObj);
        this.car.City = this.cities.filter(function(el){
          return el.CityID == car.CityID
        })[0];
        this.selectCity(this.car.City);
        this.car.Area = this.areas.filter(function(el){
          return el.AreaID == car.AreaID
        })[0];
        this.selectArea(this.car.Area);
      }
    } else {
    }
    if(this.car.SellBy == 1 && this.car.CarID == -1){
      this.car.MainPhoto = '00000000-0000-0000-0000-000000000000';
      this.car.Photos = '';
    }
    this.isLoading = false;
    this.isLoaded = true;    
  }

  prepareAddCar():Promise<any>{
    return new Promise((resolve, reject) => {
      this.carsService.prepareAddCar().then(resp => {         
        if(this.car.SellBy == 2){
          this.car.MobileToDisplay = '+971 ' + resp.mobileNumber;
        }       
        resolve(resp.data);
      });
    });
  }

  getCar():Promise<any>{
    return new Promise((resolve, reject) => {
      this.carsService.getCarToEdit(this.car.CarID).then(resp => {        
        if(resp.data.car.length == 1){
          resolve(resp.data.car[0]);
        } else {
          resolve({CarID: -3});
        }
      });
    });
  }

  makesDdl(){
    this.makeOptions = {
      singleSelection: true,
      closeDropDownOnSelection: true,
      idField: 'MakeID',
      textField: `MakeName_${this.lang}`,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      limitSelection: 2
    };
    this.modelOptions = {
      singleSelection: true,
      closeDropDownOnSelection: true,
      idField: 'ModelID',
      textField: `ModelName_${this.lang}`,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      limitSelection: 2
    };
  }
  selectMake(event){
    if(this.car.MakeObj.length == 1){
      this.car.Make = this.car.MakeObj[0].MakeID;    
    } else {
      this.car.MakeObj = [event];      
      this.car.Make = this.car.MakeObj[0].MakeID;    
    }    
    this.car.Model = null;
    this.car.ModelObj = [];
    this.filtered_models = this.options.models.filter(function(el){
      return el.MakeID == event.MakeID
    });
  }
  selectModel(event){
    if(this.car.ModelObj.length == 1){
      this.car.Model = this.car.ModelObj[0].ModelID;    
    } else {
      this.car.ModelObj = [event];
      this.car.Model = this.car.ModelObj[0].ModelID;    
    }    
  }

  getCitiesAndAreas():Promise<any>{
    return new Promise((resolve, reject) => {
      this.service.getCountryLocations().then(resp => {
        this.cities = resp.data.cities;
        this.all_areas = resp.data.areas;
        this.timeslots = resp.data.timeslots;
        this.cityOptions = {
          singleSelection: true,
          closeDropDownOnSelection: true,
          idField: 'CityID',
          textField: `CityName_${this.lang}`,
          itemsShowLimit: 1,
          allowSearchFilter: true,
          limitSelection: 2
        };
        this.areaOptions = {
          singleSelection: true,
          closeDropDownOnSelection: true,
          idField: 'AreaID',
          textField: `AreaName_${this.lang}`,
          itemsShowLimit: 1,
          allowSearchFilter: true,
          limitSelection: 2
        };
        resolve(true);
      });   
    }); 
  }
  selectCity(event){
    if(this.car.City.length == 1){
      this.car.CityID = this.car.City[0].CityID;    
    } else {
      this.car.City = [event];
      this.car.CityID = this.car.City[0].CityID;    
    }    
    this.car.Area = [];
    this.car.AreaID = null;
    this.areas = this.all_areas.filter(function(el){
      return el.CityID == event.CityID
    });
  }
  selectArea(event){
    if(this.car.Area.length == 1){
      this.car.AreaID = this.car.Area[0].AreaID;    
    } else {
      this.car.Area = [event];
      this.car.AreaID = this.car.Area[0].AreaID;    
    }    
  }

  getMetaTags(pageId) {
    this.service.getWebPage(pageId).then(data => {
      if(data.data.page.length > 0){
        this.webPage = data.data.page[0];        
        this.pageTitle.setTitle(this.webPage.pgTitle);  
        this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.webPage.PageContent);                
         this.metaTagService.addTags([
           { name: 'keywords', content: this.webPage.ggKeywords },
           { name: 'description', content: this.webPage.ggDescription },
           { name: 'og:title', content: this.webPage.ogTitle },
           { name: 'og:description', content: this.webPage.ogDescription },
           { name: 'og:site_name', content: config.meta.siteName },
           { name: 'og:type', content: 'Website' },
           { name: 'og:image', content: (this.webPage.ogImage ? this.utils.getImage(this.webPage.ogImage, 1200, 630) : config.meta.og_image ) },
           { name: 'og:url', content: config.meta.siteUrl + this.router.url },
         ]);         
      } else {
        this.pageTitle.setTitle(config.meta.siteName);
      }      
    });     
  }

  uploadMainPhoto(event){
    if(event.target.files.length == 1){
      let file = event.target.files[0];
      this.isLoading = true;
      this.service.upload(file).then(resp => {
        this.car.MainPhoto = resp.file;
        this.isLoading = false;
      }).catch(_ => {
        this.isLoading = false;
      });
    }    
  }

  async uploadMorePhoto(event){
    if(event.target.files.length > 0){
      this.isLoading = true;
      setTimeout(() => {
        if(this.isLoading == true) this.isLoading = false;
      }, 5000);  
      for(var i = 0; i < event.target.files.length; i++){
        let file = event.target.files[i];
        let resp = await this.service.upload(file);
        this.uploadedPhotos.push(resp.file);
      }
      this.car.Photos = this.uploadedPhotos.join('###');
      this.isLoading = false;  
    }  
  }

  removeImage(photo){
    this.uploadedPhotos = this.uploadedPhotos.filter(function(el){
      return el != photo
    });
    this.car.Photos = this.uploadedPhotos.join('###');
  }

  submitCar(){
    if(this.car.SellBy == 1 && this.car.CarID == -1){
      this.car.MainPhoto = '00000000-0000-0000-0000-000000000000';
      this.car.Photos = '';
    }
    if(this.car.SellBy == 2){
      this.car.AppointmentTime = '';
      this.car.AppointmentDate = null;
    }
    if(!this.car.Make){
      this.utils.alert(`${this.utils.getString('missing')} (${this.utils.getString('makes')})`, 'error');
    } else if(!this.car.Model){
      this.utils.alert(`${this.utils.getString('missing')} (${this.utils.getString('models')})`, 'error');
    } else if(!this.car.CYear){
      this.utils.alert(`${this.utils.getString('missing')} (${this.utils.getString('year')})`, 'error');
    } else if(!this.car.BodyType){
      this.utils.alert(`${this.utils.getString('missing')} (${this.utils.getString('bodyType')})`, 'error');
    } else if(!this.car.Milage && this.car.Milage !== 0){
      this.utils.alert(`${this.utils.getString('missing')} (${this.utils.getString('milage')})`, 'error');
    } else if(!this.car.Specs || this.car.Specs == -999){
      this.utils.alert(`${this.utils.getString('missing')} (${this.utils.getString('fl_specs_type')})`, 'error');
    } else if(!this.car.VIN && this.car.Specs == 2){
      this.utils.alert(`${this.utils.getString('missing')} (${this.utils.getString('vin')})`, 'error');
    } else if(!this.car.Transmission || this.car.Transmission == -999){
      this.utils.alert(`${this.utils.getString('missing')} (${this.utils.getString('fl_transmission')})`, 'error');
    } else if(!this.car.Fuel || this.car.Fuel == -999){
      this.utils.alert(`${this.utils.getString('missing')} (${this.utils.getString('fl_fuel')})`, 'error');
    } else if(!this.car.Price){
      this.utils.alert(`${this.utils.getString('missing')} (${this.utils.getString('price')})`, 'error');
    } else if(this.car.SellBy == 2 && this.uploadedPhotos.length < 3){
      this.utils.alert(`${this.utils.getString('additional_photos_error')}`, 'error');
    } else if(this.car.SellBy == 1 && !this.car.AppointmentDate && this.car.CarID == -1){
      this.utils.alert(`${this.utils.getString('select_date')}`, 'error');
    } else if(this.car.SellBy == 1 && !this.car.AppointmentTime && this.car.CarID == -1){
      this.utils.alert(`${this.utils.getString('select_time')}`, 'error');
    } else {
      if(this.isLoggedIn){
        this.isLoading = true;
        let _uploadedPhotos:Array<any> = JSON.parse(JSON.stringify(this.uploadedPhotos));
        this.car.MainPhoto = _uploadedPhotos[0];
        _uploadedPhotos.shift();
        this.car.Photos = _uploadedPhotos.join('###');
        this.carsService.manageCar(this.car).then(_ => {
           let resp = _.paymentToken;
          let isPaid = _.isPaid;
          if(resp.indexOf('error:') > 0){
            this.utils.alert(`${this.utils.getString('tryagain')}`, 'error');
            this.isLoading = false;
          } else if (resp == '403'){
            this.utils.alert(`${this.utils.getString('max_limit')}`, 'error');
            this.isLoading = false;
          } else if (resp == '404'){
            this.router.navigate([`/${this.lang}/home`]);
            this.isLoading = false;
          } else {
            if(this.isBrowser){
              if(isPaid){
                this.isLoading = false;
                this.router.navigate([`/${this.lang}/account/my-cars`]);   
              } else {
                let paymentLink = this.utils.paymentUrl(resp);              
                location.href = paymentLink;
              }
            }            
          }
        });
      } else {
        window['showLoginPopup']();    
        this.isLoading = false;    
      }      
    }
  }

  cancelCar(){
    let confirmMessage = 'Are you sure you want to cancel?';
    if(this.lang == 'ar') confirmMessage = 'هل أنت متأكد أنك تريد الإلغاء؟';
    if(confirm(confirmMessage)){
      this.router.navigate([`/${this.lang}/account/my-cars`]);
    }
  }

  onMakeChanged(veh:any){
    if(veh){
      let parts = veh.MMID.split(':');
      this.car.Make = parts[0];
      this.car.Model = parts[1];
    } else {
      this.car.Make = null;
      this.car.Model = null;
    }
  }

  totalToPay():number{
    let featuredAdPrice:number = this.options.fees[0].FeaturedAdPrice;
    let goCarsSell:number = this.options.fees[0].GoCarsSell;
    let userSellAlone:number = this.options.fees[0].UserSellAlone;
    let amount:number = 0;

    if(this.car.IsFeatured) amount = featuredAdPrice;

    if(this.accountType == 1){
      if(this.car.SellBy == 1){
        amount += goCarsSell;
      } else {
        amount += userSellAlone;
      }
    }

    return amount;
  }
}
