import { Component, OnInit, Input, Output, EventEmitter, NgModule } from '@angular/core';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.less'],
})
export class OtpComponent implements OnInit {

  otp:string = '';
  @Input('digits') digits:number = 6;
  @Input('enabled') enabled:boolean = true;
  @Output() otpChanged = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  handleOtp(e:any, i:any){
    let value = e.target.value;
    if(value && (i + 1) < this.digits){
      let id = 'idx' + (i + 1);
      (document.getElementById(id) as any).focus();
      (document.getElementById(id) as any).select();
    }
    if(e.keyCode === 8 && i > 0){
      let id = 'idx' + (i - 1);
      (document.getElementById(id) as any).focus();
      (document.getElementById(id) as any).select();
    }
    let id = 'idx' + i;
    let inputValue =  (document.getElementById(id) as any).value;
    if(inputValue.split('').length > 1){
      (document.getElementById(id) as any).value = inputValue.split('')[0];
    }
    this.getOTP();
  }
  
  handleChangeOtp(e:any){
    let paste = e.target.value
    let value = paste.split('');    
    if(value.length == this.digits){
      setTimeout(() => {
        for(var i = 0; i < value.length; i++){
          (document.querySelectorAll('.otp-input input')[i] as any).value = value[i];
        }
      }, 10);     
      let id = 'idx' + (this.digits - 1);
      (document.getElementById(id) as any).focus();
      (document.getElementById(id) as any).select();
      this.getOTP();
    } 
  }

  handlePasteOtp(e:any){
    let paste = e.clipboardData.getData('text');
    let value = paste.split('');    
    if(value.length == this.digits){
      setTimeout(() => {
        for(var i = 0; i < value.length; i++){
          (document.querySelectorAll('.otp-input input')[i] as any).value = value[i];
        }
      }, 10);     
      let id = 'idx' + (this.digits - 1);
      (document.getElementById(id) as any).focus();
      (document.getElementById(id) as any).select();
    } else {
      setTimeout(() => {
        for(var i = 0; i < this.digits; i++){
          (document.querySelectorAll('.otp-input input')[i] as any).value = '';
        }
        let id = 'idx0';
        (document.getElementById(id) as any).focus();
        (document.getElementById(id) as any).select();
      }, 50);
    }
    this.getOTP();
  }

  getOTP(){
    let inputs = document.querySelectorAll('.otp-input input');
    let otp:string = '';
    for(var i = 0; i < inputs.length; i++){
      let value = (document.querySelectorAll('.otp-input input')[i] as any).value;
      if(value) otp = otp + value;
    }
    this.otp = otp;
    if(this.otp) this.otpChanged.emit(this.otp);
  }
}
