<footer>
    <div class="container" *ngIf="settings.SettingID">
        <div class="row">
            <div class="col-12">
                <div class="footer-header">
                    <img src="../../../assets/images/footer-logo.png" alt="GoCars" />
                    <div class="footer-social m-l-auto">
                        <a href="{{settings.AndroidUrl}}" class="m-l-10" *ngIf="settings.AndroidUrl" target="_blank">
                            <i class="fab fa-android"></i>
                        </a>
                        <a href="{{settings.iOSUrl}}" class="m-l-10" *ngIf="settings.iOSUrl" target="_blank">
                            <i class="fab fa-apple"></i>
                        </a>
                        <a href="{{settings._Facebook}}" class="m-l-10" *ngIf="settings._Facebook" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="{{settings._Facebook}}" class="m-l-10" *ngIf="settings._Facebook" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="{{settings._Instagram}}" class="m-l-10" *ngIf="settings._Instagram" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                        <a href="{{settings._Snapchat}}" class="m-l-10" *ngIf="settings._Snapchat" target="_blank">
                            <i class="fab fa-snapchat-ghost"></i>
                        </a>
                        <a href="{{settings._TikTok}}" class="m-l-10" *ngIf="settings._TikTok" target="_blank">
                            <i class="bi bi-tiktok"></i>
                        </a>
                        <a href="{{settings._Twitter}}" class="m-l-10" *ngIf="settings._Twitter" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                    </div>
                </div>
                <div class="col-12">
                    <div class="main-footer-links">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-12">
                                <a href="#" routerLink="/{{lang}}/page/about-go-cars">{{utils.getString('about_go_cars')}}</a>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12">
                                <a href="#" routerLink="/{{lang}}/buy-spare-parts">{{utils.getString('buy_spare_parts')}}</a>
                            </div>
                            <!-- <div class="col-lg-3 col-md-6 col-12">
                                <a href="#" routerLink="/{{lang}}/hire-an-expert">{{utils.getString('hire_an_expert')}}</a>
                            </div> -->
                            <!-- <div class="col-lg-3 col-md-6 col-12">
                                <a href="#" routerLink="/{{lang}}/car-financing">{{utils.getString('car_financing')}}</a>
                            </div> -->
                            <div class="col-lg-3 col-md-6 col-12">                                
                                <a href="#" routerLink="/{{lang}}/luxury-cars-for-rent">{{utils.getString('lux')}}</a>   
                            </div>
                            <div class="col-lg-3 col-md-6 col-12">
                                <a href="#" routerLink="/{{lang}}/blog">{{utils.getString('blog')}}</a>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12">
                                <a href="#" routerLink="/{{lang}}/faq">{{utils.getString('faq')}}</a>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12">
                                <a href="#" routerLink="/{{lang}}/page/contact-us">{{utils.getString('contact_us')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="footer-links">
                                <div class="flinks-title">
                                    <span *ngIf="lang == 'en'">Used Cars By Location</span>
                                    <span *ngIf="lang == 'ar'">سيارات مستعملة حسب المدينة</span>
                                </div>
                                <a href="#" *ngFor="let item of cities"
                                    routerLink="/{{lang}}/used-cars/{{cityLink(item)}}"
                                    [queryParams]="{CITY: item.CityID}" class="link-slide-item">
                                    {{utils.getString('used_car_in')}} {{item['CityName_' + lang]}}
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="footer-links">
                                <div class="flinks-title">
                                    <span *ngIf="lang == 'en'">Sell Your Car</span>
                                    <span *ngIf="lang == 'ar'">بيع سيارتك</span>
                                </div>
                                <a href="#" *ngFor="let item of cities"
                                    routerLink="/{{lang}}/sell-car/{{sellInCityLink(item)}}"
                                    [queryParams]="{CITY: item.CityID}" class="link-slide-item">
                                    {{utils.getString('sell_car_in')}} {{item['CityName_' + lang]}}
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="footer-links">
                                <div class="flinks-title">
                                    <span *ngIf="lang == 'en'">Popular Makes</span>
                                    <span *ngIf="lang == 'ar'">الماركات الأكثر شيوعاً</span>
                                </div>
                                <a href="#" *ngFor="let item of makes"
                                    routerLink="/{{lang}}/used-cars/{{makeLink(item)}}"
                                    [queryParams]="{MMID: item.MMID}" class="link-slide-item">
                                    {{used(item['MakeName_' + lang])}}
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="footer-links">
                                <div class="flinks-title">
                                    <span *ngIf="lang == 'en'">Popular Models</span>
                                    <span *ngIf="lang == 'ar'">الموديلات الأكثر شيوعاً</span>
                                </div>
                                <a href="#" *ngFor="let item of models"
                                    routerLink="/{{lang}}/used-cars/{{modelLink(item)}}"
                                    [queryParams]="{MMID: item.MMID}" class="link-slide-item">
                                    {{used(item['mmname_' + lang])}}
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="footer-links">
                                <div class="flinks-title">
                                    <span *ngIf="lang == 'en'">Used Cars by Body Type</span>
                                    <span *ngIf="lang == 'ar'">سيارات مستعملة حسب الجسم</span>
                                </div>
                                <a href="#" *ngFor="let item of bodyTypes"
                                    routerLink="/{{lang}}/used-cars/{{bodyTypeLink(item)}}"
                                    [queryParams]="{BODY_TYPE: item.LookItemID}" class="link-slide-item">
                                    {{used(item['LookItem_' + lang])}}
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="footer-links">
                                <div class="flinks-title">
                                    <span *ngIf="lang == 'en'">Used Cars by Budget</span>
                                    <span *ngIf="lang == 'ar'">سيارات مستعملة حسب الميزانية</span>
                                </div>
                                <a href="#" *ngFor="let item of prices"
                                    routerLink="/{{lang}}/used-cars/{{priceLink(item)}}" 
                                    [queryParams]="{MIN_PRICE: item.min, MAX_PRICE: item.max}"
                                    class="link-slide-item">
                                    {{usedPrice(item.display)}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="copyrights-wrapper">
                        <div class="payment-method">
                            <img src="../../../assets/images/master-card.png" alt="Go Cars" />
                            <img src="../../../assets/images/visa.png" alt="Go Cars" />
                        </div>
                        <div class="terms">
                            <a href="#" routerLink="/{{lang}}/page/terms-and-conditions">{{utils.getString('terms_and_conditions')}}</a>
                            <span class="t-sep">-</span>
                            <a href="#" routerLink="/{{lang}}/page/privacy-policy">{{utils.getString('privacy_policy')}}</a>
                        </div>
                        <div class="copyright">
                            <i class="fal fa-copyright"></i> Go Cars. All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>