import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../classes/utils';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';
import { CarsService } from '../../services/cars.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { config } from '../../config';

@Component({
  selector: 'app-buy-spare-parts',
  templateUrl: './buy-spare-parts.component.html',
  styleUrls: ['./buy-spare-parts.component.less']
})
export class BuySparePartsComponent implements OnInit {

  lang:string = '';
  isBrowser:boolean;
  webPage:any = {};  
  isLoggedIn:boolean;
  htmlContent:any;
  section = [];
  isLoading:boolean = false;
  fees:any = {};
  minDate:Date;
  order:any = {
    NoOfBookings: 1,
    CityID: null,
    AreaID: null,
    Make: null,
    Model: null,
    Class: null,
    CYear: null,
    PartName: null,
    PartNumber: null
  };
  cityOptions:IDropdownSettings = {};
  areaOptions:IDropdownSettings = {};
  cities:any[] = [];
  all_areas:any[] = [];
  areas:any[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private carsService: CarsService,
    private metaTagService: Meta,
    private pageTitle: Title,
    private service: SharedService,
    private sanitizer: DomSanitizer) {
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();   
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;
      });       
   }

  ngOnInit(): void {
    this.getMetaTags('buy-spare-parts');
    this.service.getSection('buy-spare-parts').then(resp => {
      this.section = resp.data.section;
     });
     this.getData();
  }

  async getData(){
    this.isLoading = true;
    this.getCitiesAndAreas();
  }

  getCitiesAndAreas(){
    this.service.getCountryLocations().then(resp => {
      this.isLoading = false;
      this.cities = resp.data.cities;
      this.all_areas = resp.data.areas;
      this.cityOptions = {
        singleSelection: true,
        closeDropDownOnSelection: true,
        idField: 'CityID',
        textField: `CityName_${this.lang}`,
        itemsShowLimit: 1,
        allowSearchFilter: true,
        limitSelection: 2
      };
      this.areaOptions = {
        singleSelection: true,
        closeDropDownOnSelection: true,
        idField: 'AreaID',
        textField: `AreaName_${this.lang}`,
        itemsShowLimit: 1,
        allowSearchFilter: true,
        limitSelection: 2
      };
    });   
  }
  selectCity(event){
    if(this.order.City.length == 1){
      this.order.CityID = this.order.City[0].CityID;    
    } else {
      this.order.City = [event];
      this.order.CityID = this.order.City[0].CityID;    
    }    
    this.order.Area = [];
    this.order.AreaID = null;
    this.areas = this.all_areas.filter(function(el){
      return el.CityID == event.CityID
    });
  }
  selectArea(event){
    if(this.order.Area.length == 1){
      this.order.AreaID = this.order.Area[0].AreaID;    
    } else {
      this.order.Area = [event];
      this.order.AreaID = this.order.Area[0].AreaID;    
    }    
  }

  getMetaTags(pageId) {
    this.service.getWebPage(pageId).then(data => {
      if(data.data.page.length > 0){
        this.webPage = data.data.page[0];        
        this.pageTitle.setTitle(this.webPage.pgTitle);  
        this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.webPage.PageContent);                
         this.metaTagService.addTags([
           { name: 'keywords', content: this.webPage.ggKeywords },
           { name: 'description', content: this.webPage.ggDescription },
           { name: 'og:title', content: this.webPage.ogTitle },
           { name: 'og:description', content: this.webPage.ogDescription },
           { name: 'og:site_name', content: config.meta.siteName },
           { name: 'og:type', content: 'Website' },
           { name: 'og:image', content: (this.webPage.ogImage ? this.utils.getImage(this.webPage.ogImage, 1200, 630) : config.meta.og_image ) },
           { name: 'og:url', content: config.meta.siteUrl + this.router.url },
         ]);         
      } else {
        this.pageTitle.setTitle(config.meta.siteName);
      }      
    });     
  }

  onMakeChanged(event){
    if(event.MMID){
      let values = event.MMID.split(':');
      this.order.Make = values[0];
      this.order.Model = values[1];
    } else {
      this.order.Make = null;
      this.order.Model = null;
    }
  }

  submitOrder(){
    if(this.isBrowser){
      let validForm:boolean = true;
      if(!this.order.CityID) validForm = false;
      if(!this.order.AreaID) validForm = false;
      if(!this.order.Make) validForm = false;
      if(!this.order.Model) validForm = false;
      if(!this.order.CYear) validForm = false;
      if(!this.order.PartName) validForm = false;
      if(validForm){
        if(this.isLoggedIn){
          this.isLoading = true;         
          this.carsService.sparePartOrder(this.order).then(resp => {
            if(resp){              
              this.router.navigate([`/${this.lang}/account/spare-parts-orders`]);
            } else {
              this.utils.alert(this.utils.getString('tryagain'), 'error');   
            }
            this.isLoading = false;
          });          
        } else {
          window['showLoginPopup']();
        }        
      } else {
        this.utils.alert(this.utils.getString('requiredFields'), 'error');
      }
    }
  }
}
