import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Utils } from '../../classes/utils';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';

export interface IMakes {
  MMID: string;
  MakeName_en: string;
  MakeName_ar: string;
  ModelName_en: string;
  ModelName_ar: string;
  mmname_en: string;
  mmname_ar: string;
}

@Component({
  selector: 'app-makes-input',
  templateUrl: './makes-input.component.html',
  styleUrls: ['./makes-input.component.less']
})
export class MakesInputComponent implements OnInit {

  @Input('value') value:string = '';
  @Input('modelsOnly') modelsOnly:boolean = false;
  @Input('ui-class') uiClass:string = '';
  myControl = new FormControl('');
  options: IMakes[] = [];
  filteredOptions: Observable<IMakes[]>;
  lang:string = '';
  isBrowser:boolean;
  @Output() onMakeChanged:EventEmitter<any> = new EventEmitter();
  @Output() onTriggerSearch:EventEmitter<any> = new EventEmitter();

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private service: SharedService) {
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();  
      this.router.events.subscribe((url: NavigationEnd) => {
        if (url && url.urlAfterRedirects) {
          this.lang = this.utils.getLanguage();       
        }
        return;
      });           
   }

  ngOnInit() {
    if(this.modelsOnly){
      this.service.getModels().then(resp => {
        this.options = resp.data.options;
        this.defaultValue();
      });
    } else {
      this.service.getMakesModels().then(resp => {
        this.options = resp.data.options;
        this.defaultValue();
      });
    }    
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value['mmname_' + this.lang];
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );
  }

  defaultValue(){
    let value = this.value;
    if(this.value){
      let make = this.options.filter(function(el){
        return el.MMID == value
      });
      this._selectedMake = {};
      if(make.length > 0){      
        this._selectedMake = make[0];      
      }    
      this.myControl.setValue(make[0]['mmname_' + this.lang]);    
      // this.filteredOptions = this.myControl.valueChanges.pipe(
      //   startWith(''),
      //   map(value => {
      //     const name = make[0].mmname_en;
      //     return name ? this._filter(name as string) : this.options.slice();
      //   }),
      // );      
      this.onMakeChanged.emit(this._selectedMake);
    }
  }

  displayFn(make: IMakes): string {
    return make && make['mmname_' + this.lang] ? make['mmname_' + this.lang] : '';
  }

  private _filter(name: string): IMakes[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => 
      option['mmname_en'].toLowerCase().includes(filterValue)
      ||
      option['mmname_ar'].toLowerCase().includes(filterValue)
    );
  }

  _selectedMake:any;
  selectMake(event){
    let make = this.options.filter(function(el){
      return el.mmname_en == event.option.value || el.mmname_ar == event.option.value
    });
    this._selectedMake = {};
    if(make.length > 0){      
      this._selectedMake = make[0];      
    }    
    this.onMakeChanged.emit(this._selectedMake);
  }
  doSearch(event){      
    if(this._selectedMake.MMID && event.currentTarget && event.currentTarget.value){
      this.onTriggerSearch.emit(this._selectedMake);
    } else {
      this.onMakeChanged.emit({});
    }    
  }
}
