<a href="#" routerLink="/{{lang}}/blog/article/{{article.ArticleID}}" class="blog-item">
    <div class="article-photo">
        <img src="{{utils.getRegularIdWithSize(article.ArticlePhoto, 1920, 1080)}}" alt="{{article['ArticleTitle_' + lang]}}" />
    </div>
    <div class="article-info">
        <div class="article-title">{{article['ArticleTitle_' + lang]}}</div>
        <div class="article-category">{{article['CatName_' + lang]}}</div>
        <div class="article-date">
            {{article.PublishDate | date:'longDate'}} {{article.PublishDate | date:'shortTime'}}
        </div>
    </div>
</a>