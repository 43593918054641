import { Injectable } from '@angular/core';
import { Api } from '../http/Api';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  controller:string = 'Blog';

  constructor(private api: Api) { }

  getTopBlog() {
    return this.api.get(`${this.controller}/top-articles`);
  }

  getArticles(id) {
    return this.api.get(`${this.controller}/get-articles?id=${id}`);
  }

  getRelatedArticles(id) {
    return this.api.get(`${this.controller}/get-related-articles?id=${id}`);
  }

  getBlogCategories() {
    return this.api.get(`${this.controller}/get-blog-categories`);
  }

  getArticle(id) {
    return this.api.get(`${this.controller}/get-article?id=${id}`);
  }
}
