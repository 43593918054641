import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Swiper, EffectFade, Autoplay, Navigation, Pagination } from 'swiper';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../classes/utils';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.less']
})
export class SliderComponent implements OnInit {

  @Input('slides') slides = [];
  lang:string = '';
  isBrowser:boolean;
  settings:any = {};
  isLoggedIn:boolean;
  swiper:any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private metaTagService: Meta,
    private pageTitle: Title,
    private service: SharedService,
    private sanitizer: DomSanitizer) {
      this.isBrowser = this.utils.isBrowser();
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;
      });
      this.router.events.subscribe((url: NavigationEnd) => {
        if (url && url.urlAfterRedirects) {
          this.lang = this.utils.getLanguage();       
        }
        return;
      });     
   }

  ngOnInit(): void {
   
  }
  ngOnChanges(changes: SimpleChanges) {
    if(this.isBrowser){      
      setTimeout(() => {
        this.setupSwiper();
        setTimeout(() => {
          this.setupSwiper();
        }, 500);
      }, 100);         
    }  
  }

  setupSwiper(){
    Swiper.use([Autoplay]);
    Swiper.use([Navigation]);
    Swiper.use([Pagination]);
    if(this.swiper){
      try {
        this.swiper.destroy();
      } catch{}
    }
    this.swiper = new Swiper('.main-swiper', {
          spaceBetween: 0,
          slidesPerView: 1,
          loop: true, 
          loopedSlides: 100,
          autoplay: {
            delay: 10 * 1000,
            disableOnInteraction: false,
          },    
          pagination: {
            el: '.mainslider-pagination',
            type: 'bullets',
            clickable: true
          },
    });  
  }

  trackByLoop(index: number, item: any): number {
    return item.IDXID;
  }
}
