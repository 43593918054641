<div class="example-container" cdkDropListGroup>
  <div cdkDropList [cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped($event)"></div>
  <div cdkDropList *ngFor="let item of items; let i = index"
       [cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped($event)">
    <div cdkDrag class="example-box" [class.main-photo]="i == 0"
    cdkDragBoundary=".example-container"
    (cdkDragMoved)="dragMoved($event);">
        <button class="remove-image layout-{{lang}}" (click)="removeImage(item)"><i class="far fa-trash-alt"></i></button>
        <img src="https://ucarecdn.com/{{item}}/-/scale_crop/600x450/smart/-/format/auto/">
    </div>
  </div>
  <div class="custom-upload" (click)="triggerUpload()">
    <img src="../../../assets/images/upload.png" />
  </div>
</div> 