import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../classes/utils';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';
import { BlogService } from '../../services/blog.service';
import { config } from '../../config';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.less']
})
export class ArticleComponent implements OnInit {

  id:number = -1;
  lang:string = '';
  isBrowser:boolean;
  webPage:any = {};  
  article:any = {};
  siteCountry:string = '';
  htmlContent:any;
  catId:number = -1;
  related = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private blogService: BlogService,
    private metaTagService: Meta,
    private pageTitle: Title,
    private service: SharedService,
    private sanitizer: DomSanitizer) {
      this.siteCountry = config.siteCountry
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();  
      this.activatedRoute.params.subscribe(async params => {
        if(params['id']){
         this.id = +params['id'];
          this.getData();
        } else {
          this.router.navigate([`/${this.lang}/blog`]);
        }
       });           
   }

  ngOnInit(): void {      
    
  }

  getData(){    
    this.blogService.getArticle(this.id).then(resp => {
      this.article = resp.data.post[0];
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.article['ArticleBody_' + this.lang]);             
      this.catId = this.article.BlogCatID;
      let whatsappLink:string = `Check out this article on GoCars Blog - *${this.article.ArticleTitle_en}* - https://gocars.ae/en/blog/article/${this.id}`;
      if(this.lang == 'ar'){
        whatsappLink = `اقرأ هذا المقال على مدونة جو كارز - *${this.article.ArticleTitle_ar}* - https://gocars.ae/ar/blog/article/${this.id}`;
      }
      this.article.shareLink = 'https://wa.me/?text=' + encodeURIComponent(whatsappLink);
      this.blogService.getRelatedArticles(this.catId).then(resp => {
        this.related = resp.data.posts;
      });
      let title = this.article['ArticleTitle_' + this.lang];
      let blogTitle = this.utils.getString('blog_title');
      this.pageTitle.setTitle(`${title} | ${blogTitle}`);                
         this.metaTagService.addTags([
           { name: 'keywords', content: `${title} | ${blogTitle}` },
           { name: 'description', content: `${title} | ${blogTitle}` },
           { name: 'og:title', content: `${title} | ${blogTitle}` },
           { name: 'og:description', content: `${title} | ${blogTitle}` },
           { name: 'og:site_name', content: config.meta.siteName },
           { name: 'og:type', content: 'Website' },
           { name: 'og:image', content: this.utils.getRegularIdWithSize(this.article.ArticlePhoto, 1200, 630) },
           { name: 'og:url', content: config.meta.siteUrl + this.router.url },
         ]);     
    });
  }
}
