import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../classes/utils';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';
import { CarsService } from '../../services/cars.service';
import { UserService } from '../../services/user.service';
import { config } from '../../config';
import { Swiper, EffectFade, Autoplay, Navigation, Pagination, Lazy, Thumbs } from 'swiper';
import * as noUiSlider from 'nouislider';

@Component({
  selector: 'app-car',
  templateUrl: './car.component.html',
  styleUrls: ['./car.component.less']
})
export class CarComponent implements OnInit {

  id:number = -1;
  isLoaded:boolean = false;
  isLoading:boolean = false;
  lang:string = '';
  isBrowser:boolean;
  webPage:any = {};  
  isLoggedIn:boolean;
  isSold:boolean = false;
  car:any = {car: []};  
  thumbs:any;
  swiper:any;
  original_thumbs:any;
  original_swiper:any;
  photo360:any;
  show360:boolean = false;
  showPriceTrack:boolean = false;
  targetPrice:number = 0;
  minPrice:number = 0;
  maxPrice:number = 0;
  defaultTarget:number = 0;
  showScratches:boolean = false;
  showTestDrive:boolean = false;
  testDriveOrder:any = {};
  depositRefundPolicy = [];
  showDepositDialog:boolean = false;
  testTimeSlots: [];
  showOfferDialog:boolean = false;
  offerAmount:number = 0;
  viewLargeSlider:boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private carsService: CarsService,
    private metaTagService: Meta,
    private pageTitle: Title,
    private service: SharedService,
    private userService: UserService,
    private sanitizer: DomSanitizer) {
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();   
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;        
      });  
      this.activatedRoute.params.subscribe(async params => {
       if(params['id']){
          this.id = +params['id'];
          this.isLoaded = false;
          this.isLoading = true;
          this.isSold = false;
          this.car = {car: []};
          this.thumbs = null;
          this.swiper = null;
          this.getCar();
       } else {
        this.router.navigate([`/${this.lang}/home`]);
       }
      });       
   }

  ngOnInit(): void {
  }

  getCar(){
    this.carsService.getCar(this.id).then(resp => {      
      this.car = resp.data;            
      this.isLoaded = true;
      this.isLoading = false;
      if(this.car.car.length == 1){
        this.isSold = resp.data.car[0].IsSold;        
        if(this.car.car[0].IsSold == 0 && this.car.car[0].SellBy == 1){
          this.service.getSection('reserve-refund').then(resp => {            
           this.depositRefundPolicy = resp.data.section;                
          });
          this.service.getSection('test-drive-time').then(resp => {            
            this.testTimeSlots = resp.data.section;                
           });          
        }
        let whatsappLink:string = `Check out this car on GoCars - *${this.car.car[0].AdTitle_en}* - https://gocars.ae/en/used-car/${this.car.car[0].CarID}`;
        this.car.shareLink = 'https://wa.me/?text=' + encodeURIComponent(whatsappLink);
        if(this.car.car[0].MobileNumber){
          let mobile:string = '+971' + this.car.car[0].MobileNumber;
          mobile = mobile.replace('+9710', '+971');
          this.car.car[0].MobileNumber = mobile;
        }
        this.car.fav = this.utils.isFav(this.car.car[0].CarID);
        //alert(this.car.car[0].IsInspected)
        let photos = [];
        photos.push({
          photo: this.utils.getLargeCarPhoto(this.car.car[0].MainPhoto),
          original: this.utils.getRegularFile(this.car.car[0].MainPhoto),
          is360: false
        });       
        if(this.car.car[0].Photo360) {
          photos.push({
            photo: this.utils.getLargeCarPhoto(this.car.car[0].Photo360),
            original: this.utils.getLargeCarPhoto(this.car.car[0].Photo360),
            is360: true
          });  
        }
        if(this.car.car[0].Photos){
          let morePhotos = this.car.car[0].Photos.split('###');
          for(var i = 0; i < morePhotos.length; i++){
            photos.push({              
              photo: this.utils.getLargeCarPhoto(morePhotos[i]),
              original: this.utils.getRegularFile(morePhotos[i]),
              is360: false
            }); 
          }
        }
        this.car.photos = photos;
        if(this.isBrowser){      
          setTimeout(() => {
            this.setupSwiper();
            setTimeout(() => {
              this.setupSwiper();
            }, 500);
          }, 100);         
        }         
        this.getMetaTags(this.car.car[0]);
        this.carsService.getRelatedCars(this.id).then(resp => {
          this.car.related = resp.data.cars;
        });
      } else {
        this.router.navigate([`/${this.lang}/404`]);
      }
      if(config.isProduction == false && this.isBrowser) console.log(this.car);
    });
  }

  setupSwiper(){
    Swiper.use([Navigation]);
    Swiper.use([Pagination]);
    Swiper.use([Lazy]);
    Swiper.use([Thumbs]);
    if(this.swiper){
      try {
        this.swiper.destroy();
        this.thumbs.destroy();
      } catch{}
    }
    this.thumbs = new Swiper('.car-thumbs-swiper', {
      lazy: {
        enabled: true,
        loadPrevNext: true,
        loadPrevNextAmount: 4
      },        
      watchSlidesProgress: true,
      spaceBetween: 15,
      slidesPerView: 6.5,   
      breakpoints: {
        320: {
          slidesPerView: 2.5,            
        },
        460: {
          slidesPerView: 3.5,            
        },
        768: {
          slidesPerView: 5.5,
        },
        1024: {
          slidesPerView: 6.5,
        },
        1200: {
          slidesPerView: 6.5,
        },
      }
    });  
    this.swiper = new Swiper('.car-photos-swiper', {
        lazy: {
          enabled: true,
          loadPrevNext: true,
          loadPrevNextAmount: 4
        },        
        spaceBetween: 0,
        slidesPerView: 1, 
        navigation: { 
          nextEl: ".car-photos-swiper .swiper-button-next",
          prevEl: ".car-photos-swiper .swiper-button-prev",
        },  
        thumbs: {
          swiper: this.thumbs
        },
      });  
  }

  openOriginalSlider(i:number){
    if(this.isBrowser){     
      this.viewLargeSlider = true; 
      setTimeout(() => {
        this.originalSwiper(i);         
        setTimeout(() => {
          this.originalSwiper(i);
        }, 500);
      }, 100);         
    }  
  }

  originalSwiper(i?:number){
    Swiper.use([Navigation]);
    Swiper.use([Pagination]);
    Swiper.use([Lazy]);
    Swiper.use([Thumbs]);
    if(this.original_swiper){
      try {
        this.original_swiper.destroy();
        this.original_thumbs.destroy();
      } catch{}
    }
    this.original_thumbs = new Swiper('.car-original-thumbs .swiper', {
      initialSlide: (i ? i : 0),
      lazy: {
        enabled: true,
        loadPrevNext: true,
        loadPrevNextAmount: 4
      },        
      watchSlidesProgress: true,
      spaceBetween: 15,
      centeredSlides: false,
      slidesPerView: 8,   
      breakpoints: {
        320: {
          slidesPerView: 4,            
        },
        460: {
          slidesPerView: 5,            
        },
        768: {
          slidesPerView: 6,
        },
        1024: {
          slidesPerView: 7,
        },
        1200: {
          slidesPerView: 8,
        },
      }
    });  
    this.original_swiper = new Swiper('.org-modal-body .swiper', {
        initialSlide: (i ? i : 0),
        lazy: {
          enabled: true,
          loadPrevNext: true,
          loadPrevNextAmount: 4
        },        
        autoHeight: true,
        spaceBetween: 0,
        slidesPerView: 1, 
        centeredSlides: true,
        navigation: { 
          nextEl: ".org-modal-body .swiper-button-next",
          prevEl: ".org-modal-body .swiper-button-prev",
        },  
        thumbs: {
          swiper: this.original_thumbs
        },
      });  
  }

  getMetaTags(car){
    let lang = this.lang;
    let title = `${car['MakeName_' + lang]} ${car['ModelName_' + lang]} ${car.CYear} ${this.utils.getString('meta_for_sale_in')} ${car['CityName_' + lang]}, ${this.utils.getString('site_country')}`;
    this.pageTitle.setTitle(title + ' | Go Cars');                
    this.metaTagService.addTags([
      { name: 'keywords', content: title + ', ' + car.MakeName_en + ', ' + car.MakeName_ar + ', ' + car.ModelName_en + ', ' + car.ModelName_ar },
      { name: 'description', content: title },
      { name: 'og:title', content: title + ' | Go Cars' },
      { name: 'og:description', content: title },
      { name: 'og:site_name', content: config.meta.siteName },
      { name: 'og:type', content: 'Website' },
      { name: 'og:image', content: this.utils.getLargeCarPhoto(car.MainPhoto, 1200, 630) },
      { name: 'og:url', content: config.meta.siteUrl + this.router.url },
    ]);   
  }

  addToFavourites(){
    this.car.fav = this.utils.toggleFav(this.car.car[0].CarID);
    if(this.isBrowser) window['favChanged']();
  }

  play360(){
    this.photo360 = this.sanitizer.bypassSecurityTrustResourceUrl(config.panoUrl + this.car.car[0].Photo360);
    this.show360 = true;
  }

  viewIns(ins){
    this.utils.alert(ins['InspectItem_' + this.lang], 'info', ins['InspectItemDesc_' + this.lang])
  }

  startPriceTracking(){
    if(this.isBrowser){
      if(this.isLoggedIn){
        this.targetPrice = 0;
        this.minPrice = Math.ceil(this.car.car[0].Price / 2);        
        this.maxPrice = this.car.car[0].Price;
        this.defaultTarget = Math.ceil(this.maxPrice - (this.maxPrice * 0.05));
        if(this.car.trackPrice.length == 1){
          this.targetPrice = this.car.trackPrice[0].TargetPrice;
        }
        this.showPriceTrack = true;        
      } else {
        window['showLoginPopup']();
      }
    }
  }

  trackPrice(){
    let drop = {
      CarID: this.id,
      TargetPrice: this.targetPrice
    }
    this.isLoading = true;
    this.carsService.trackPrice(drop).then(_ => {
      this.isLoading = false;
      this.showPriceTrack = false;
      this.utils.alert(this.utils.getString('we_will_notify_you'));
      this.car.trackPrice.push({
        TargetPrice: this.targetPrice
      })
    }).catch(_ => {
      this.isLoading = false;
      this.showPriceTrack = false;
    });
  }

  scratchImage(photoId):string{
    let fileId = photoId.split('/')[0];
    return this.utils.getImage(fileId, 512, 512);
  }

  async reserveCar(){
    if(this.isBrowser){
      if(this.isLoggedIn){
        let confirm:boolean = true; // await this.utils.confirm(this.utils.getString('sure_to_reserve'));
        if(confirm){
          let order = {
            CarID: this.id,          
          }
          this.isLoading = true;
          this.carsService.reserveCar(order).then(resp => {
            if(resp == '00000000-0000-0000-0000-000000000000'){
              this.utils.alert(this.utils.getString('already_reserved'), 'error');
              this.isLoading = false;
            } else {
              let paymentLink = this.utils.paymentUrl(resp);              
              location.href = paymentLink;
            }
          });
        }
      } else {
        window['showLoginPopup']();
        this.isLoading = false;
      }      
    }
  }

  async testDrive(){
    if(this.isBrowser){
      if(this.isLoggedIn) {
        this.testDriveOrder = {
          CarID: this.id,
          TestDate: '',
          TimeSlot: ''
        };
        var availableDays = [];
        for(var i = 1; i <= 7; i++){
          availableDays.push(this.dbDate(this.addDays(new Date(), i)));
        }
        this.testDriveOrder.availableDays = availableDays;
        this.showTestDrive = true;
      } else {
        window['showLoginPopup']();
      }     
    }
  }

  submitTestDriveRequest(){
    this.isLoading = true;
    this.carsService.testDriveRequest(this.testDriveOrder).then(resp => {
      this.utils.success(this.utils.getString('vc_test_success'), this.utils.getString('vc_test_confirm'), 4000);
      this.isLoading = false;
      this.showTestDrive = false;
    });
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  dbDate(dt:Date):string{
    return dt.getFullYear() + '-' + this.num(dt.getMonth() + 1) + '-' + this.num(dt.getDate());
  }

  num(n):string{
    if(n < 10) return '0' + n;
    return n;
  }

  startOffer(){
    if(this.isBrowser){
      if(this.isLoggedIn){
        this.offerAmount = this.car.car[0].Price;
        this.showOfferDialog = true;
        setTimeout(() => {
          this.priceSlider();
        }, 50);
      } else {
        window['showLoginPopup']();
      } 
    }
  }

  makeAnOffer(){
    this.isLoading = true;
    let offer:any = {
      CarID: this.id,
      OfferAmount: this.offerAmount
    };
    this.carsService.makeAnOffer(offer).then(resp => {
      this.utils.success(this.utils.getString('vc_offer_success'), this.utils.getString('vc_offer_confirm'), 4000);
      this.isLoading = false;
      this.showOfferDialog = false;
      this.router.navigate([`/${this.lang}/account/offers-sent`]);
    });
  }
  
  buyCarOnline(){
    if(this.isBrowser){
      if(this.isLoggedIn){
       let car:any = {
        CarID: this.car.car[0].CarID
       }
       this.isLoading = true;
       this.carsService.buyOnline(car).then(resp => {
        this.router.navigate([`/${this.lang}/account/buy-payments`], { queryParams: {id: resp} });
        this.isLoading = false;
      });
      } else {
        window['showLoginPopup']();
      } 
    }
  }

  priceSlider(){
    if(this.isBrowser){
      let self = this;
      var valuesSlider = (document as any).getElementById('priceSlider');     
      if(!(valuesSlider as any).noUiSlider){
        noUiSlider.create(valuesSlider, {
          start: [self.car.car[0].Price],
          range: {
            'min': 0,
            'max': self.car.car[0].Price
          },
          step: 100,     
          orientation: 'horizontal',
          direction: (this.lang == 'en' ? 'ltr' : 'rtl')         
      });     
      }      
      (valuesSlider as any).noUiSlider.on('slide',function(values, handle, unencoded, tap, positions, noUiSlider) {
        let x = (valuesSlider as any).noUiSlider;
        // self.query.MIN_PRICE = parseFloat(values[0]).toFixed(0);
        // self.query.MAX_PRICE = parseFloat(values[1]).toFixed(0);        
      });
      (valuesSlider as any).noUiSlider.on('update',function(values, handle, unencoded, tap, positions, noUiSlider) {
        let x = (valuesSlider as any).noUiSlider;
        self.offerAmount = parseInt(values[0]);
      });
    }
  }

  copyCode(){    
    if(this.isBrowser) navigator.clipboard.writeText(this.car.car[0].VIN);  
  }
}
