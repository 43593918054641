import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../../classes/utils';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-reserved-cars',
  templateUrl: './reserved-cars.component.html',
  styleUrls: ['./reserved-cars.component.less']
})
export class ReservedCarsComponent implements OnInit {

  lang:string = '';
  isBrowser:boolean;
  isLoggedIn:boolean;
  orders = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private userService: UserService) {
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();   
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;
        if(!_isLoggedIn){
          this.lang = this.utils.getLanguage();     
          this.router.navigate([`/${this.lang}/home`]);
        }
      });       
   }

  ngOnInit(): void {
    if(this.isBrowser) this.getData();
  }

  getData(){
    this.userService.getReservedCars().then(resp => {
      this.orders = resp.data.orders;
    });
  }
}
