import { Component, OnInit, Input } from '@angular/core';
import { Utils } from '../../classes/utils';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-blog-item',
  templateUrl: './blog-item.component.html',
  styleUrls: ['./blog-item.component.less']
})
export class BlogItemComponent implements OnInit {

  lang:string = '';
  @Input('article') article:any = {};

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils) { 
      this.lang = this.utils.getLanguage();
      this.router.events.subscribe((url: NavigationEnd) => {
        if (url && url.urlAfterRedirects) {
          this.lang = this.utils.getLanguage();       
        }
        return;
      });     
    }

  ngOnInit(): void {
  }
}
