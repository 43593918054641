<input type="search"
class="autocomplete-input {{uiClass}}"
(search)="doSearch($event)"
placeholder="{{utils.getString('makes_search')}}"
[formControl]="myControl"
[matAutocomplete]="auto">
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectMake($event)">
<mat-option *ngFor="let make of filteredOptions | async" [value]="make['mmname_' + lang]" class="opt-{{lang}}">
    {{make['mmname_' + lang]}}
</mat-option>
</mat-autocomplete>