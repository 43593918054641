<div class="container" *ngIf="webPage.PageTitle">
    <div class="row">
        <div class="col-12">
            <h1 class="web-page-title">{{webPage.PageTitle}}</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5 col-12">
            <div class="web-page-content clang-{{lang}}" *ngIf="webPage.PageContent" [innerHTML]="htmlContent"></div>           
            <div class="bullets-section">
                <div class="bullets-option" *ngFor="let item of section; let i = index">
                    <div class="bullet-icon m-r-10">
                        <span class="part-step">{{i+1}}</span>
                    </div>
                    <div class="bullet-info">
                        <div class="bullet-title">{{item['SectionTitle_' + lang]}}</div>
                        <div class="bullet-subtitle">{{item['SectionSubTitle_' + lang]}}</div>   
                    </div>                 
                </div>
            </div>
        </div>
        <div class="col-md-7 col-12">
            <div class="order-form">
                <div class="gc-legend">{{utils.getString('tell_us_part')}}</div>
                <div class="gc-field">
                    <label class="gc-label">{{utils.getString('part_name')}}</label>
                    <div class="gc-input">
                      <span class="gc-input-group m-r-10">
                        <i class="fal fa-engine-warning"></i>
                      </span>
                      <input type="text" placeholder="" [(ngModel)]="order.PartName" />
                    </div>
                </div>   
                <div class="gc-field">
                    <label class="gc-label">{{utils.getString('part_number')}}</label>
                    <div class="gc-input">
                      <span class="gc-input-group m-r-10">
                        <i class="fal fa-keyboard"></i>
                      </span>
                      <input type="text" placeholder="" [(ngModel)]="order.PartNumber" />
                    </div>
                </div>  
                <div class="gc-legend">{{utils.getString('tell_us_car')}}</div>
                <div class="gc-field">
                    <label class="gc-label">{{utils.getString('spare_car')}}</label>
                    <div class="gc-input">
                      <span class="gc-input-group m-r-10">
                        <i class="fal fa-car"></i>
                      </span>
                      <app-makes-input [ui-class]="'spare-input'" [modelsOnly]="true"
                        (onMakeChanged)="onMakeChanged($event)"></app-makes-input>     
                    </div>
                </div>     
                <div class="gc-field">
                    <label class="gc-label">{{utils.getString('trim')}}</label>
                    <div class="gc-input">
                      <span class="gc-input-group m-r-10">
                        <i class="fal fa-car-side"></i>
                      </span>
                      <input type="text" placeholder="" [(ngModel)]="order.Class" />
                    </div>
                </div>
                <div class="gc-field">
                    <label class="gc-label">{{utils.getString('year')}}</label>
                    <div class="gc-input">
                      <span class="gc-input-group m-r-10">
                        <i class="fal fa-calendar-alt"></i>
                      </span>
                      <input type="number" placeholder="" [(ngModel)]="order.CYear" />
                    </div>
                </div>       
                <div class="gc-legend">{{utils.getString('tell_us_location')}}</div>
                <div class="row">
                    <div class="col-lg-6 col-12">
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('city')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="fal fa-city"></i>
                              </span>
                                <ng-multiselect-dropdown [placeholder]="utils.getString('please_select')" [settings]="cityOptions"
                                [data]="cities" [(ngModel)]="order.City" (onSelect)="selectCity($event)"
                                (onDeSelect)="selectCity($event)">
                                </ng-multiselect-dropdown>              
                            </div>
                        </div>   
                    </div>
                    <div class="col-lg-6 col-12">
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('area')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="fal fa-map-marked"></i>
                              </span>
                                <ng-multiselect-dropdown [placeholder]="utils.getString('please_select')" [settings]="areaOptions"
                                [data]="areas" [(ngModel)]="order.Area" (onSelect)="selectArea($event)"
                                (onDeSelect)="selectArea($event)">
                                </ng-multiselect-dropdown>              
                            </div>
                        </div>
                    </div>
                </div>   
                <button class="save-action" (click)="submitOrder()" [disabled]="isLoading">{{utils.getString('submit_spare_request')}}</button>
            </div>
        </div>
    </div>
</div>