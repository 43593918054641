<div class="container" *ngIf="webPage.PageTitle">
    <div class="row">
        <div class="col-12">
            <h1 class="web-page-title">{{webPage.PageTitle}}</h1>
            <div class="faq">
                <div class="question" *ngFor="let item of faq; let i = index" [attr.data-index]="i">
                    <div class="the-question" (click)="toggleQuestion(i)">
                        <span class="m-r-auto">
                            {{item['SectionTitle_' + lang]}}
                        </span>
                        <i class="far fa-chevron-down"></i>
                    </div>
                    <div class="the-answer web-page-content clang-{{lang}} m-l-10" [innerHTML]="item['SectionDescription_' + lang]"></div>
                </div>
            </div>
        </div>
    </div>
</div>