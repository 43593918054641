import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './pages/landing/landing.component';
import { HomeComponent } from './pages/home/home.component';
import { UsedCarsComponent } from './pages/used-cars/used-cars.component';
import { SellCarComponent } from './pages/sell-car/sell-car.component';
import { HireAnExpertComponent } from './pages/hire-an-expert/hire-an-expert.component';
import { PageComponent } from './pages/page/page.component';
import { CarFinancingComponent } from './pages/car-financing/car-financing.component';
import { BuySparePartsComponent } from './pages/buy-spare-parts/buy-spare-parts.component';
import { FaqComponent } from './pages/faq/faq.component';
import { CarComponent } from './pages/car/car.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AccountComponent } from './pages/account/account.component';
import { ProfileComponent } from './pages/account/profile/profile.component';
import { MyCarsComponent } from './pages/account/my-cars/my-cars.component';
import { ManageCarsComponent } from './pages/account/manage-cars/manage-cars.component';
import { ExpertOrdersComponent } from './pages/account/expert-orders/expert-orders.component';
import { MyQoutesComponent } from './pages/account/my-qoutes/my-qoutes.component';
import { RecievedQoutesComponent } from './pages/account/recieved-qoutes/recieved-qoutes.component';
import { MySparePartOrdersComponent } from './pages/account/my-spare-part-orders/my-spare-part-orders.component';
import { NotificationssComponent } from './pages/account/notificationss/notificationss.component';
import { DashboardComponent } from './pages/account/dashboard/dashboard.component';
import { ManageCarComponent } from './pages/manage-car/manage-car.component';
import { FavsComponent } from './pages/favs/favs.component';
import { ReservedCarsComponent } from './pages/account/reserved-cars/reserved-cars.component';
import { LuxuryCarsForRentComponent } from './pages/luxury-cars-for-rent/luxury-cars-for-rent.component';
import { BlogComponent } from './pages/blog/blog.component';
import { ArticleComponent } from './pages/article/article.component';
import { BuyOrdersComponent } from './pages/account/buy-orders/buy-orders.component';
import { BuyPaymentsComponent } from './pages/account/buy-orders/buy-payments/buy-payments.component';

const routes: Routes = [
  //{ path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: '', component: LandingComponent },
  { path: ':lang/used-cars/:quick', component: UsedCarsComponent },
  { path: ':lang/used-cars', component: UsedCarsComponent },
  { path: ':lang/sell-car/:quick', component: SellCarComponent },
  { path: ':lang/sell-car', component: SellCarComponent },
  { path: ':lang/hire-an-expert', component: HireAnExpertComponent },
  { path: ':lang/page/:id', component: PageComponent },
  { path: ':lang/used-car/:id/:title', component: CarComponent },
  { path: ':lang/used-car/:id', component: CarComponent },
  { path: ':lang/car-financing', component: CarFinancingComponent },
  { path: ':lang/buy-spare-parts', component: BuySparePartsComponent },
  { path: ':lang/faq', component: FaqComponent },
  { path: ':lang/home', component: HomeComponent },
  { path: ':lang/favourites', component: FavsComponent },
  { path: ':lang/manage-car', component: ManageCarComponent },
  { path: ':lang/blog', component: BlogComponent },
  { path: ':lang/blog/article/:id', component: ArticleComponent },
  { path: ':lang/luxury-cars-for-rent', component: LuxuryCarsForRentComponent },
  { path: ':lang/account', component: AccountComponent, children: [
    { path: 'dashboard', component: DashboardComponent },
    { path: 'my-profile', component: ProfileComponent },
    { path: 'my-cars', component: MyCarsComponent },
    { path: 'reserved-cars', component: ReservedCarsComponent },
    { path: 'manage-car', component: ManageCarsComponent },
    { path: 'expert-orders', component: ExpertOrdersComponent },
    { path: 'offers-sent', component: MyQoutesComponent },
    { path: 'offers-received', component: RecievedQoutesComponent },
    { path: 'spare-parts-orders', component: MySparePartOrdersComponent },
    { path: 'notifications', component: NotificationssComponent },
    { path: 'buy-orders', component: BuyOrdersComponent },
    { path: 'buy-payments', component: BuyPaymentsComponent }
  ]},
  { path: '404', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

