<app-slider [slides]="slides"></app-slider>
<section id="homeSearch" class="container">
    <div class="row">
        <div class="col-12">
            <div class="home-search">
                <i class="fal fa-search"></i>
                <app-makes-input [ui-class]="'home-input'" 
                (onMakeChanged)="onMakeChanged($event)"
                (onTriggerSearch)="doHomeSearch($event)"></app-makes-input>
                <button aria-label="Search Used Cars" class="do-home-search" (click)="doHomeSearch()">{{utils.getString('search')}}</button>
            </div>
        </div>
    </div>
</section>
<section id="topCars" *ngIf="featuredCars.length > 0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="top-cars-title">{{utils.getString('featured')}}</h1>
                <div class="row">
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12" *ngFor="let car of featuredCars">
                        <app-car-item [car]="car"></app-car-item>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>
<section class="quick-search">    
    <app-quick-search></app-quick-search>
</section>
<div class="dynamic-services">
    <section class="home-service" *ngFor="let item of ourServices | kzFilter: {DisplayOrder: 1}">
        <div class="container">
            <div class="row">                
                <div class="col-lg-6 col-md-5 col-12">
                    <img src="{{utils.getImage(item.SectionIcon, 512, 512)}}" alt="GoCars" class="srv-icon" />
                </div>
                <div class="col-lg-6 col-md-7 col-12">
                    <h3 class="srv-title">{{item['SectionTitle_' + lang]}}</h3>                   
                    <div class="srv-desc clang-{{lang}}" [innerHTML]="item.html"></div>
                    <div class="srv-link">
                        <a href="{{item['SectionLink_' + lang]}}">
                            {{utils.getString('learn_more')}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<section id="topCars">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="top-cars-title">{{utils.getString('our_choice')}}</h1>
                <div class="row">
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12" *ngFor="let car of topCars">
                        <app-car-item [car]="car"></app-car-item>
                    </div>                    
                </div>
            </div>
            <div class="col-12">
                <div class="view-all-cars">
                    <a href="#" routerLink="/{{lang}}/used-cars/featured-cars">
                        {{utils.getString('view_all_cars')}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="download-our-app" *ngIf="topCars.length > 0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="app-details">
                    <div class="app-links">
                        <h1>{{utils.getString('get_app')}}</h1>
                        <div class="the-links">
                            <a href="https://play.google.com/store/apps/details?id=app.gocars.ae"><img src="../../../assets/images/app/play-store.png" alt="{{utils.getString('get_app')}}" class="m-r-15" /></a>
                            <a href="https://apps.apple.com/us/app/go-cars-buy-sell-in-uae/id6447811168"><img src="../../../assets/images/app/app-store.png" alt="{{utils.getString('get_app')}}" /></a>
                        </div>
                    </div>
                    <div class="app-on-device">
                        <img src="../../../assets/images/app/app.png" alt="{{utils.getString('get_app')}}" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="dynamic-services">
    <section class="home-service" *ngFor="let item of ourServices | kzFilter: {DisplayOrder: 2}">
        <div class="container">
            <div class="row">                
                <div class="col-lg-6 col-md-5 col-12">
                    <img src="{{utils.getImage(item.SectionIcon, 512, 512)}}" alt="GoCars" class="srv-icon" />
                </div>
                <div class="col-lg-6 col-md-7 col-12">
                    <h3 class="srv-title">{{item['SectionTitle_' + lang]}}</h3>                   
                    <div class="srv-desc clang-{{lang}}" [innerHTML]="item.html"></div>
                    <div class="srv-link">
                        <a href="{{item['SectionLink_' + lang]}}">
                            {{utils.getString('learn_more')}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<section id="topLux" *ngIf="topLux.length > 0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="top-cars-title">{{utils.getString('top_lux')}}</h1>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngFor="let car of topLux">
                        <app-lux-item [car]="car"></app-lux-item>
                    </div>                    
                </div>
            </div>
            <div class="col-12">
                <div class="view-all-cars">
                    <a href="#" routerLink="/{{lang}}/luxury-cars-for-rent">
                        {{utils.getString('view_all_cars')}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="topLux" *ngIf="topArticles.length > 0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="top-cars-title">{{utils.getString('blog_top')}}</h1>
                <div class="row">
                    <div class="col-md-6 col-12" *ngFor="let article of topArticles">
                        <app-blog-item [article]="article"></app-blog-item>
                    </div>                    
                </div>
            </div>
            <div class="col-12">
                <div class="view-all-cars">
                    <a href="#" routerLink="/{{lang}}/blog">
                        {{utils.getString('blog_view_all')}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<a href="#" routerLink="/{{lang}}/sell-car/sell-my-car" class="sell-my-car-now sell-{{lang}}">
    <i class="fas fa-car m-r-5"></i>
    <span>{{utils.getString('sell_my_car')}}</span>
</a>