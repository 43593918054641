<h3 class="acc-title">{{utils.getString('ac_notifications')}}</h3>
<div class="acc-list-view">
    <div class="acc-card" *ngFor="let item of notifications" [class.read]="item.IsRead">
        <div class="ac-card-title">
           {{item.title}}
        </div>
        <div class="ac-card-desc">
            <div>{{item.body}}</div>
            <div>{{item._SendDate}}</div>         
        </div>
        <div class="ac-card-payment">
            <div class="order-payment m-l-auto">               
                <button class="open-notification" (click)="open(item)">
                    {{utils.getString('open')}}
                    <i class="fal fa-long-arrow-right m-l-5" *ngIf="lang == 'en'"></i>
                    <i class="fal fa-long-arrow-left m-l-5" *ngIf="lang == 'ar'"></i>
                </button>
            </div>
        </div>
    </div>
</div>