<div class="container account-page" *ngIf="isBrowser">
    <div class="row">
        <div class="col-12">
            <div class="gocars-account">
                    <div class="account-mob-nav" (click)="toggleAccountNav()">
                        <i class="fal fa-bars m-r-5"></i><span>{{utils.getString('ac_menu')}}</span>
                    </div>  
                    <div class="account-nav m-r-20">              
                        <a href="#" routerLink="/{{lang}}/account/dashboard" routerLinkActive="active-acc-link">
                            <i class="fal fa-tachometer-alt-slow"></i>
                            <span>{{utils.getString('ac_my_dashboard')}}</span>
                        </a>  
                        <a href="#" routerLink="/{{lang}}/account/notifications" routerLinkActive="active-acc-link">
                            <i class="fal fa-bell"></i>
                            <span>{{utils.getString('ac_notifications')}}</span>
                            <span id="ac-notifications" class="ac-barge m-l-auto m-r-5" *ngIf="notificatonsCount > 0">{{notificatonsCount}}</span>
                        </a>  
                        <a href="#" routerLink="/{{lang}}/account/my-profile" routerLinkActive="active-acc-link">
                            <i class="fal fa-user"></i>
                            <span>{{utils.getString('ac_my_profile')}}</span>
                        </a>  
                        <a href="#" routerLink="/{{lang}}/favourites" routerLinkActive="active-acc-link">
                            <i class="fal fa-heart"></i>
                            <span>{{utils.getString('ac_favourites')}}</span>
                            <span id="ac-notifications" class="ac-barge m-l-auto m-r-5" *ngIf="favsCount > 0">{{favsCount}}</span>
                        </a>  
                        <a href="#" routerLink="/{{lang}}/account/my-cars" routerLinkActive="active-acc-link">
                            <i class="fal fa-cars"></i>
                            <span>{{utils.getString('ac_cars')}}</span>
                        </a>  
                        <a href="#" routerLink="/{{lang}}/account/buy-orders" routerLinkActive="active-acc-link">
                            <i class="fal fa-car-alt"></i>
                            <span>{{utils.getString('buy_online')}}</span>
                        </a>  
                        <a href="#" routerLink="/{{lang}}/account/reserved-cars" routerLinkActive="active-acc-link">
                            <i class="fal fa-car-garage"></i>
                            <span>{{utils.getString('ac_reserved_cars')}}</span>
                        </a>  
                        <a href="#" routerLink="/{{lang}}/account/expert-orders" routerLinkActive="active-acc-link">
                            <i class="fal fa-car-mechanic"></i>
                            <span>{{utils.getString('ac_expert_appoitments')}}</span>
                        </a>  
                        <a href="#" routerLink="/{{lang}}/account/offers-sent" routerLinkActive="active-acc-link">
                            <i class="fal fa-file-upload"></i>
                            <span>{{utils.getString('ac_my_qoutes')}}</span>
                        </a>  
                        <a href="#" routerLink="/{{lang}}/account/spare-parts-orders" routerLinkActive="active-acc-link">
                            <i class="fal fa-tire"></i>
                            <span>{{utils.getString('ac_space_parts_order')}}</span>
                        </a>                 
                    </div>
                    <div class="account-wrapper">
                        <router-outlet></router-outlet>
                    </div>
            </div>
        </div>
    </div>
</div>