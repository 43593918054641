import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../classes/utils';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';
import { CarsService } from '../../services/cars.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { config } from '../../config';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-sell-car',
  templateUrl: './sell-car.component.html',
  styleUrls: ['./sell-car.component.less']
})
export class SellCarComponent implements OnInit {

  lang:string = '';
  isBrowser:boolean;
  webPage:any = {};  
  isLoggedIn:boolean;
  htmlContent:any;
  section = [];
  isLoading:boolean = false;
  fees:any = {};
  sellBy:number;
  accountType:number = 0;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private carsService: CarsService,
    private metaTagService: Meta,
    private pageTitle: Title,
    private service: SharedService,
    private userService: UserService,
    private sanitizer: DomSanitizer) {
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();   
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;
        if(!this.isLoggedIn){
          this.router.navigate([`/${this.lang}/home`]);
          window['showLoginPopup']();
        } else {
          // this.router.navigate([`/${this.lang}/manage-car`], {queryParams: {id: -1, sellBy: 2}});
        }
      });       
   }

  ngOnInit(): void {
    this.getMetaTags('sell-car');
    this.service.getSection('sell-features').then(resp => {
      this.section = resp.data.section;
     });
     this.getData();
  }

  async getData(){
    this.isLoading = true;
    this.fees = await this.getFees();
    this.userService.getProfile().then(data => {
      if(data.length == 1){
        this.accountType = data[0].AccountType;
        if(this.accountType == 2){
          this.router.navigate([`/${this.lang}/manage-car`], {queryParams: {id: -1, sellBy: 2}});
        }
      }
    });
    this.isLoading = false;
  }

  getFees():Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.service.getFees().then(resp => {        
        resolve(resp.data.fees[0]);
      });
    });
  }

  getMetaTags(pageId) {
    this.service.getWebPage(pageId).then(data => {
      if(data.data.page.length > 0){
        this.webPage = data.data.page[0];        
        this.pageTitle.setTitle(this.webPage.pgTitle);  
        this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.webPage.PageContent);                
         this.metaTagService.addTags([
           { name: 'keywords', content: this.webPage.ggKeywords },
           { name: 'description', content: this.webPage.ggDescription },
           { name: 'og:title', content: this.webPage.ogTitle },
           { name: 'og:description', content: this.webPage.ogDescription },
           { name: 'og:site_name', content: config.meta.siteName },
           { name: 'og:type', content: 'Website' },
           { name: 'og:image', content: (this.webPage.ogImage ? this.utils.getImage(this.webPage.ogImage, 1200, 630) : config.meta.og_image ) },
           { name: 'og:url', content: config.meta.siteUrl + this.router.url },
         ]);         
      } else {
        this.pageTitle.setTitle(config.meta.siteName);
      }      
    });     
  }

  proceed(){
    if(this.isBrowser){
      if(this.sellBy){
        this.router.navigate([`/${this.lang}/manage-car`], {queryParams: {id: -1, sellBy: this.sellBy}});
        // if(this.isLoggedIn){
        //   this.router.navigate([`/${this.lang}/manage-car`], {queryParams: {id: -1, sellBy: this.sellBy}});
        // } else {
        //   window['showLoginPopup']();
        // }
      } else {
        this.utils.alert(this.utils.getString('sell_by_error'), 'error');
      }
    }
  }
}
