<div class="acc-title-wrapper">
    <h3 class="acc-title">{{utils.getString('ac_space_parts_order')}}</h3>
    <a href="#" routerLink="/{{lang}}/buy-spare-parts" class="acc-add m-l-auto"><i class="fal fa-plus"></i> {{utils.getString('order_now')}}</a>
</div>
<div class="acc-list-view">
    <div class="acc-card" *ngFor="let item of orders">
        <div class="ac-card-title">
            {{item.PartName}}<span *ngIf="item.PartNumber" class="m-l-5">({{item.PartNumber}})</span>
        </div>
        <div class="ac-card-id">ID #{{item.SPARTID}}</div>
        <div class="ac-card-desc">            
            <div><i class="fal fa-car m-r-5"></i>{{item['MakeName_' + lang]}} {{item['ModelName_' + lang]}}<span *ngIf="item.Class" class="m-l-5">({{item.Class}})</span></div>           
            <div><i class="fal fa-calendar-alt m-r-5"></i>{{item.CYear}}</div>           
            <div><i class="fal fa-location m-r-5"></i>{{item['AreaName_' + lang]}}, {{item['CityName_' + lang]}}</div>                       
        </div>
        <div class="ac-card-payment">
            <div class="order-price" *ngIf="item.Price > 0">{{item.Price | number}} {{utils.getString('cur')}}</div>
            <div class="order-payment m-l-auto">                
                <span *ngIf="item.IsPaid" class="paid-order">{{utils.getString('paid')}}</span>
                <span *ngIf="!item.IsPaid && item.Price == 0" class="pending-order">{{utils.getString('pending_order')}}</span>
                <a href="{{utils.paymentUrl(item.PaymentToken)}}" *ngIf="!item.IsPaid && item.Price > 0" class="pay-order">{{utils.getString('pay_order')}}</a>
            </div>
        </div>
    </div>
</div>