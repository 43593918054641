<div class="acc-title-wrapper">
    <h3 class="acc-title">{{utils.getString('ac_expert_appoitments')}}</h3>
    <a href="#" routerLink="/{{lang}}/hire-an-expert" class="acc-add m-l-auto"><i class="fal fa-plus"></i> {{utils.getString('order_now')}}</a>
</div>
<div class="acc-list-view">
    <div class="acc-card" *ngFor="let item of orders">
        <div class="ac-card-title">
            <span *ngIf="item.NoOfBookings == 1" class="m-r-5">{{utils.getString('one_visit')}}</span>
            <span *ngIf="item.NoOfBookings == 3" class="m-r-5">{{utils.getString('three_visits')}}</span>
            <span class="m-r-5">/</span>
            <span>{{item['AreaName_' + lang]}}, {{item['CityName_' + lang]}}</span>
        </div>
        <div class="ac-card-id">ID #{{item.EXOID}}</div>
        <div class="ac-card-desc">           
            <div>{{item.b1_date}}</div>
            <div *ngIf="item.NoOfBookings != 1">{{item.b2_date}}</div>
            <div *ngIf="item.NoOfBookings != 1">{{item.b3_date}}</div>            
        </div>
        <div class="ac-card-payment">
            <div class="order-price" *ngIf="item.Price > 0">{{item.Price | number}} {{utils.getString('cur')}}</div>
            <div class="order-payment m-l-auto">               
                <span *ngIf="item.IsPaid" class="paid-order">{{utils.getString('paid')}}</span>
                <span *ngIf="!item.IsPaid && item.Price == 0" class="pending-order">{{utils.getString('pending_order')}}</span>
                <a href="{{utils.paymentUrl(item.PaymentToken)}}" *ngIf="!item.IsPaid && item.Price > 0" class="pay-order">{{utils.getString('pay_order')}}</a>
            </div>
        </div>
    </div>
</div>