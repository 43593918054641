<section id="topLux">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="top-cars-title">{{utils.getString('blog_title')}}</h1>
                <div class="blog-categories" *ngIf="categories.length > 0">
                    <a href="#" routerLink="/{{lang}}/blog" [class.active]="id == -1">{{utils.getString('blog_all')}}</a>
                    <a href="#" routerLink="/{{lang}}/blog" [class.active]="id == item.BlogCatID" [queryParams]="{id: item.BlogCatID}" *ngFor="let item of categories">{{item['CatName_' + lang]}}</a>
                </div>
                <div class="row" *ngIf="categories.length > 0">
                    <div class="col-md-6 col-12" *ngFor="let article of articles">
                        <app-blog-item [article]="article"></app-blog-item>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>