import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../classes/utils';
import { config } from '../../config';
import { Swiper, EffectFade, Autoplay, Navigation, Pagination } from 'swiper';

@Component({
  selector: 'app-lux-item',
  templateUrl: './lux-item.component.html',
  styleUrls: ['./lux-item.component.less']
})
export class LuxItemComponent implements OnInit {

  @Input('car') car:any = {};

  lang:string = '';
  isBrowser:boolean;
  isLoggedIn:boolean;
  swiper:any = null;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils) {
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;
      });
      this.router.events.subscribe((url: NavigationEnd) => {
        if (url && url.urlAfterRedirects) {
          this.lang = this.utils.getLanguage();       
        }
        return;
      });     
   }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.isBrowser){      
      setTimeout(() => {
        this.setupSwiper();
        setTimeout(() => {
          this.setupSwiper();
        }, 500);
      }, 100);         
    }  
  }

  setupSwiper(){    
    Swiper.use([Pagination]);
    if(this.swiper){
      try {
        this.swiper.destroy();
      } catch{}
    }
    this.swiper = new Swiper('.lux-swiper-' + this.car.LuxCarID, {
          spaceBetween: 0,
          slidesPerView: 1,
          pagination: {
            el: '.lux-pagination-' + this.car.LuxCarID,
            type: 'bullets',
            clickable: true
          },
    });  
  }

  whatsappLink(){
    let text = '';
    if(this.lang == 'en'){
      text = `Hello, I want to rent %0a *${this.car['LuxTitle_' + this.lang]}* %0a ID: ${this.car.LuxCarID}`;
    } else {
      text = `مرحبا, أريد استئجار %0a *${this.car['LuxTitle_en']}* %0a ID: ${this.car.LuxCarID}`;
    }
    return `https://wa.me/${this.car.ContactNumber}?text=${text}`;
  }
}
