import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../classes/utils';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { CarsService } from '../../services/cars.service';
import { SharedService } from '../../services/shared.service';
import { config } from '../../config';

@Component({
  selector: 'app-favs',
  templateUrl: './favs.component.html',
  styleUrls: ['./favs.component.less']
})
export class FavsComponent implements OnInit {

  lang:string = '';
  isBrowser:boolean;
  webPage:any = {};  
  favsCount:number = -1;
  siteCountry:string = '';
  cars = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private carsService: CarsService,
    private metaTagService: Meta,
    private pageTitle: Title,
    private service: SharedService,
    private sanitizer: DomSanitizer) {
      this.siteCountry = config.siteCountry
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();          
   }

  ngOnInit(): void {
    this.getMetaTags('favourites');   
    let favs:Array<number> = this.utils.getFavs();      
    this.favsCount = favs.length;
    if(this.favsCount > 0){
      let ids = favs.join(',');
      this.carsService.getFavs(ids).then(resp => {
        this.cars = resp.data.cars;
      });
    }
  }

  getMetaTags(pageId) {
    this.service.getWebPage(pageId).then(data => {
      if(data.data.page.length > 0){
        this.webPage = data.data.page[0];        
        this.pageTitle.setTitle(this.webPage.pgTitle);                
         this.metaTagService.addTags([
           { name: 'keywords', content: this.webPage.ggKeywords },
           { name: 'description', content: this.webPage.ggDescription },
           { name: 'og:title', content: this.webPage.ogTitle },
           { name: 'og:description', content: this.webPage.ogDescription },
           { name: 'og:site_name', content: config.meta.siteName },
           { name: 'og:type', content: 'Website' },
           { name: 'og:image', content: (this.webPage.ogImage ? this.utils.getImage(this.webPage.ogImage, 1200, 630) : config.meta.og_image ) },
           { name: 'og:url', content: config.meta.siteUrl + this.router.url },
         ]);         
      } else {
        this.pageTitle.setTitle(config.meta.siteName);
      }      
    });     
  }

  onFavChanged(car){
    if(!car.fav){
      this.cars = this.cars.filter(item => item.CarID != car.CarID)
    }
    let favs:Array<number> = this.utils.getFavs();      
    this.favsCount = favs.length;
  }
}
