import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../classes/utils';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';
import { CarsService } from '../../services/cars.service';
import { config } from '../../config';
import * as noUiSlider from 'nouislider';
import jwt_encode from "jwt-encode";

@Component({
  selector: 'app-used-cars',
  templateUrl: './used-cars.component.html',
  styleUrls: ['./used-cars.component.less']
})
export class UsedCarsComponent implements OnInit {

  carsTitle:string = '';
  isLoaded:boolean = false;
  isLoading:boolean = false;
  cars = [];
  total:number = 0;
  filtersCount:number = 0;
  lang:string = '';
  isBrowser:boolean;
  webPage:any = {};  
  makes = [];
  cities = [];
  bodyTypes = [];
  colors = [];
  transmission = [];
  fuel = [];
  pricerates = [];
  selectedCars = [];
  rowsPerPage: 24;
  query:any = {
    sort: 'latest',
    offset: 1,
    rows: 24
  };
  toggleFilters:boolean = false;
  totalPages:number = 0;
  visibleRangeLength = 5;
  visiblePages: number[];
  
  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private metaTagService: Meta,
    private pageTitle: Title,
    private service: SharedService,
    private carsService: CarsService,
    private sanitizer: DomSanitizer) {
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();    
      this.activatedRoute.queryParams.subscribe(async params => {
        if(this.makes.length == 0){
          await this.getLookups();
        }        
        this.parseFilters(params);
        this.resetPage();
        this.doSearch();
      });  
   }

  ngOnInit(): void {
    this.getMetaTags('used-cars');
  }

  getMetaTags(pageId) {
    this.service.getWebPage(pageId).then(data => {
      if(data.data.page.length > 0){
        this.webPage = data.data.page[0];        
        //this.pageTitle.setTitle(this.webPage.pgTitle);                
         this.metaTagService.addTags([
           { name: 'keywords', content: this.webPage.ggKeywords },
           { name: 'description', content: this.webPage.ggDescription },
           //{ name: 'og:title', content: this.webPage.ogTitle },
           { name: 'og:description', content: this.webPage.ogDescription },
           { name: 'og:site_name', content: config.meta.siteName },
           { name: 'og:type', content: 'Website' },
           { name: 'og:image', content: (this.webPage.ogImage ? this.utils.getImage(this.webPage.ogImage, 1200, 630) : config.meta.og_image ) },
           { name: 'og:url', content: config.meta.siteUrl + this.router.url },
         ]);         
      } else {
        this.pageTitle.setTitle(config.meta.siteName);
      }      
    });     
  }

  dynamicPageTitle(params){
    let lang = this.lang;
    if(params['MMID'] && params['MMID'] != '0:0' && this.arrStr('MMID', ',').length == 1){
      let obj = this.makes.filter(function(el){
        return el.MMID == params['MMID']
      })[0];
      this.carsTitle = `${obj['mmname_' + lang]} ${this.utils.getString('meta_for_sale_in')} ${config['siteCountry_' + lang]}`;
    } else if(params['CITY'] && params['CITY'] != '' && this.arrStr('CITY').length == 1){
      let obj = this.cities.filter(function(el){
        return el.CityID == params['CITY']
      })[0];
      this.carsTitle = `${this.utils.getString('meta_city_car_in')} ${obj['CityName_' + lang]}`;
    } else if(params['BODY_TYPE'] && params['BODY_TYPE'] != '' && this.arrStr('BODY_TYPE').length == 1){
      let obj = this.bodyTypes.filter(function(el){
        return el.LookItemID == params['BODY_TYPE']
      })[0];
      this.carsTitle = `${obj['LookItem_' + lang]} ${this.utils.getString('meta_for_sale_in')} ${config['siteCountry_' + lang]}`;
    } else {      
      this.carsTitle = `${this.utils.getString('meta_used_cars')} ${config['siteCountry_' + lang]}`;
    }
    this.pageTitle.setTitle(this.carsTitle + ' | Go Cars');    
    this.metaTagService.addTags([
      { name: 'og:title', content: this.carsTitle + ' | Go Cars'},
    ]);         
  }

  getLookups():Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.service.getUsedFilters().then(resp => {
        this.makes = resp.data.makes;
        this.cities = resp.data.cities;
        this.bodyTypes = resp.data.bodyTypes;
        this.colors = resp.data.colors;
        this.transmission = resp.data.transmission;
        this.fuel = resp.data.fuel;
        this.pricerates = resp.data.pricerates;
        resolve(true);
      });
    });
  }

  resetPage(){
    this.isLoaded = false;
    this.cars = [];    
    this.total = 0;
    this.totalPages = 0;
  }

  onMakeChanged(event){
    if(event && event.MMID){
      let mid = event.MMID;
      let selectedCar = this.selectedCars.filter(function(el){
        return el.MMID == mid
      });
      if(selectedCar.length == 0){
        this.selectedCars.push(this.makes.filter(function(el){
          return el.MMID == mid
        })[0]);
        let MMIDs = [];
        for(var i = 0; i < this.selectedCars.length; i++){
          let make = this.selectedCars[i];
          MMIDs.push(make.MMID);
        }
        this.query.MMID = MMIDs.join(',');   
        this.redirect();   
      }
    }
  }

  async parseFilters(params){  
    this.query = {
      sort: 'latest',
      offset: 1,
      rows: 24,
      MMID: '0:0',
      PRICE_TYPE: 'total',
      MIN_PRICE: 0,
      MAX_PRICE: 200,
      MIN_MILAGE: 0,
      MAX_MILAGE: 200,
      MIN_YEAR: 1960,
      MAX_YEAR: new Date().getFullYear(),
      BODY_TYPE: '',
      CITY: '',
      PRICE_RATE: '',
      COLOR: '',
      TRANSMISSION: -1,
      FUEL: '',
      TopCondition: -1,
      Negotiable: -1,
      UrgentSale: -1,
      HasDownPayment: -1,
      NeedMaintenance: -1,
      PriceDropped: -1,
      AccountType: -1,
      F_NAVIGATION: -1,
      F_PARKING_SENSOR: -1,
      F_REVERSING_CAMERA: -1,
      F_GCC_SPECS: -1,
      F_LEATHER_SEATS: -1,
      F_SUN_ROOF: -1,
      F_SEVEN_SEATERS: -1,
      F_SERVICE_CONTRACT: -1,
      F_WARRANTY: -1
    }; 
    const keys = Object.keys(this.query);
    let filtersCount:number = 0;
    let nonFilter = ['offset', 'rows', 'sort', 'PRICE_TYPE'];
    for(var i = 0; i < keys.length; i++){     
      if(params[keys[i]]){
        if(this.query[keys[i]] != params[keys[i]] && !nonFilter.includes(keys[i])){
          filtersCount++;
        }
        this.query[keys[i]] = params[keys[i]];
      }
      this.filtersCount = filtersCount;      
      if(params[keys[i]] && keys[i] == 'offset'){
        this.query[keys[i]] = +params[keys[i]];
      } else if (keys[i] == 'offset'){
        this.query[keys[i]] = 1;
      }
    }    

    //track search history
    // if(this.isBrowser){
    //   let historyMakes = [];
    //   try {
    //     if(localStorage.historyMakes){
    //       historyMakes = JSON.parse(localStorage.historyMakes);
    //     }
    //   } catch {
    //     historyMakes = [];
    //   }
    // }

    this.selectedCars = [];
    if(params['MMID'] && params['MMID'] != '0:0'){
      let mids = params['MMID'].split(',');
      for(var i = 0; i < mids.length; i++){
        this.selectedCars.push(this.makes.filter(function(el){
          return el.MMID == mids[i]
        })[0]);
      }            
    }   
    if(this.isBrowser){
      setTimeout(() => {
        if(this.query.PRICE_TYPE == 'total'){
          this.fullPriceSlider(); 
        }        
        if(this.query.PRICE_TYPE == 'monthly'){    
          if(this.query.MAX_PRICE > 20) {
            this.query.MAX_PRICE = 20;
          }
          if(this.query.MIN_PRICE > 20) {
            this.query.MIN_PRICE = 0;
          }
          this.monthlyPriceSlider();
        }       
        this.milageSlider();   
        this.yearSlider();
      }, 300);
    }
    this.dynamicPageTitle(params);
  }

  fullPriceSlider(){
    if(this.isBrowser){
      let self = this;
      var valuesSlider = (document as any).getElementById('fullPriceSlider');     
      if(!(valuesSlider as any).noUiSlider){
        noUiSlider.create(valuesSlider, {
          start: [self.query.MIN_PRICE, self.query.MAX_PRICE],
          range: {
            'min': 0,
            'max': 200
          },
          step: 1,     
          orientation: 'horizontal',
          direction: (this.lang == 'en' ? 'ltr' : 'rtl')         
      });     
      }      
      (valuesSlider as any).noUiSlider.on('slide',function(values, handle, unencoded, tap, positions, noUiSlider) {
        let x = (valuesSlider as any).noUiSlider;
        self.query.MIN_PRICE = parseFloat(values[0]).toFixed(0);
        self.query.MAX_PRICE = parseFloat(values[1]).toFixed(0);        
      });
      (valuesSlider as any).noUiSlider.on('change',function(values, handle, unencoded, tap, positions, noUiSlider) {
        let x = (valuesSlider as any).noUiSlider;
        self.query.MIN_PRICE = parseFloat(values[0]).toFixed(0);
        self.query.MAX_PRICE = parseFloat(values[1]).toFixed(0);
        self.redirect();
      });
    }
  }

  monthlyPriceSlider(){
    if(this.isBrowser){
      let self = this;
      var valuesSlider = (document as any).getElementById('monthlyPriceSlider');  
      if(!(valuesSlider as any).noUiSlider){
        noUiSlider.create(valuesSlider, {
          start: [self.query.MIN_PRICE, self.query.MAX_PRICE],
          range: {
            'min': 0,
            'max': 10
          },
          step: 0.1,  
          orientation: 'horizontal',
          direction: (this.lang == 'en' ? 'ltr' : 'rtl')                       
      }); 
      }                 
      (valuesSlider as any).noUiSlider.on('change',function(values, handle, unencoded, tap, positions, noUiSlider) {
        let x = (valuesSlider as any).noUiSlider;
        self.query.MIN_PRICE = parseFloat(values[0]).toFixed(1);
        self.query.MAX_PRICE = parseFloat(values[1]).toFixed(1);
        self.redirect();
      });
      (valuesSlider as any).noUiSlider.on('slide',function(values, handle, unencoded, tap, positions, noUiSlider) {
        let x = (valuesSlider as any).noUiSlider;
        self.query.MIN_PRICE = parseFloat(values[0]).toFixed(1);
        self.query.MAX_PRICE = parseFloat(values[1]).toFixed(1);
      });
    }
  }

  milageSlider(){
    if(this.isBrowser){
      let self = this;
      var valuesSlider = (document as any).getElementById('milageSlider');   
      if(!(valuesSlider as any).noUiSlider)  {
        noUiSlider.create(valuesSlider, {
          start: [self.query.MIN_MILAGE, self.query.MAX_MILAGE],
          range: {
            'min': 0,
            'max': 200
          },
          step: 1, 
          orientation: 'horizontal',
          direction: (this.lang == 'en' ? 'ltr' : 'rtl')                        
      });  
      }                
      (valuesSlider as any).noUiSlider.on('slide',function(values, handle, unencoded, tap, positions, noUiSlider) {
        let x = (valuesSlider as any).noUiSlider;
        self.query.MIN_MILAGE = parseFloat(values[0]).toFixed(0);
        self.query.MAX_MILAGE = parseFloat(values[1]).toFixed(0);
      });           
      (valuesSlider as any).noUiSlider.on('change',function(values, handle, unencoded, tap, positions, noUiSlider) {
        let x = (valuesSlider as any).noUiSlider;
        self.query.MIN_MILAGE = parseFloat(values[0]).toFixed(0);
        self.query.MAX_MILAGE = parseFloat(values[1]).toFixed(0);
        self.redirect();
      });
    }
  }

  yearSlider(){
    if(this.isBrowser){
      let self = this;
      var valuesSlider = (document as any).getElementById('yearSlider');   
      if(!(valuesSlider as any).noUiSlider)  {
        noUiSlider.create(valuesSlider, {
          start: [self.query.MIN_YEAR, self.query.MAX_YEAR],
          range: {
            'min': 1960,
            'max': new Date().getFullYear()
          },
          step: 1, 
          orientation: 'horizontal',
          direction: (this.lang == 'en' ? 'ltr' : 'rtl')                        
      });  
      }                
      (valuesSlider as any).noUiSlider.on('slide',function(values, handle, unencoded, tap, positions, noUiSlider) {
        let x = (valuesSlider as any).noUiSlider;
        self.query.MIN_YEAR = parseFloat(values[0]).toFixed(0);
        self.query.MAX_YEAR = parseFloat(values[1]).toFixed(0);
      });           
      (valuesSlider as any).noUiSlider.on('change',function(values, handle, unencoded, tap, positions, noUiSlider) {
        let x = (valuesSlider as any).noUiSlider;
        self.query.MIN_YEAR = parseFloat(values[0]).toFixed(0);
        self.query.MAX_YEAR = parseFloat(values[1]).toFixed(0);
        self.redirect();
      });
    }
  }

  changePriceType(type){
    this.query.PRICE_TYPE = type;
    if(type == 'total'){
      this.query.MIN_PRICE = 0;
      this.query.MAX_PRICE = 200;
    } else {
      this.query.MIN_PRICE = 0;
      this.query.MAX_PRICE = 10;
    }
    this.redirect();
  }

  removeCar(car){
    let MMID = car.MMID;
    this.selectedCars = this.selectedCars.filter(function(el){
      return el.MMID != MMID
    });
    let mids = [];
    for(var i = 0; i < this.selectedCars.length; i++){
      let make = this.selectedCars[i];
      mids.push(make.MMID);
    }
    this.query.MMID = (mids.join(',').length > 0 ? mids.join(',') : '0:0');
    this.redirect();
  }

  redirect(){
    this.router.navigate([`/${this.lang}/used-cars`], {queryParams: this.query});
  }
  
  arrStr(prop, seperator = ':'){
    return (this.query[prop] && this.query[prop].split(seperator) ? this.query[prop].split(seperator).map( Number ) : []);
  }

  arrFilter(prop, val, seperator = ':'){
    let arr = this.arrStr(prop, seperator);
    if(arr.includes(val)){      
      arr = arr.filter(function(el){
        return el != val
      });
    } else {
      arr.push(val);
    }
    this.query[prop] = arr.join(seperator);
    this.redirect();
  }

  numFilter(prop, val){
    if(this.query[prop] == val){
      this.query[prop] = -1;
    } else {
      this.query[prop] = val;
    }
    this.redirect();
  }

  resetFilters(){
    this.query = {
      sort: 'latest',
      offset: 1,
      rows: 24,
      MMID: '0:0',
      PRICE_TYPE: 'total',
      MIN_PRICE: 0,
      MAX_PRICE: 200,
      MIN_MILAGE: 0,
      MAX_MILAGE: 200,
      MIN_YEAR: 1960,
      MAX_YEAR: new Date().getFullYear(),
      BODY_TYPE: '',
      CITY: '',
      PRICE_RATE: '',
      COLOR: '',
      TRANSMISSION: -1,
      FUEL: '',
      TopCondition: -1,
      Negotiable: -1,
      UrgentSale: -1,
      HasDownPayment: -1,
      NeedMaintenance: -1,
      PriceDropped: -1,
      AccountType: -1,
      F_NAVIGATION: -1,
      F_PARKING_SENSOR: -1,
      F_REVERSING_CAMERA: -1,
      F_GCC_SPECS: -1,
      F_LEATHER_SEATS: -1,
      F_SUN_ROOF: -1,
      F_SEVEN_SEATERS: -1,
      F_SERVICE_CONTRACT: -1,
      F_WARRANTY: -1
    }; 
    this.resetPage();
    this.redirect();
  }

  selectPage(pageNumber){
    this.query.offset = pageNumber;
    this.redirect();
  }

  updateVisiblePages(): void {
    const length = Math.min(this.totalPages, this.visibleRangeLength);
    const startIndex = Math.max(
      Math.min(
        this.query.offset - Math.ceil(length / 2),
        this.totalPages - length
      ),
      0
    );
    this.visiblePages = Array.from(
      new Array(length).keys(),
      (item) => item + startIndex + 1
    );
  }

  doSearch(){
    this.isLoading = true;
    let query:any = JSON.parse(JSON.stringify(this.query));        
    const secret = 'kinzi-workspace';            
    const jwt = jwt_encode(query, secret); 
    this.carsService.getSearchCars(jwt).then(resp => {
      if(resp.success){                  
        this.cars = resp.data.cars;
        this.total = resp.data.total[0].TOTAL;
        this.totalPages = Math.ceil(this.total / this.query.rows);  
        this.updateVisiblePages();           
      }
      this.isLoaded = true;
      this.isLoading = false;
    }).catch(_ => {
      this.isLoaded = true;
      this.isLoading = false;
    });
  }
}
