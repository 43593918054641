<div class="acc-title-wrapper">
    <h3 class="acc-title">{{utils.getString('ac_my_qoutes')}}</h3>
</div>
<div class="acc-list-view">
    <div class="acc-card" *ngFor="let item of orders">
        <div class="acc-card-wrapper">
            <img src="{{utils.getImage(item.MainPhoto, 600, 450)}}" alt="{{item['AdTitle_' + lang]}}" class="reserved-img m-r-15" />
            <div class="acc-card-main-info">
                <div class="ac-card-title">
                    <div class="card-title-wrapper">
                        <div class="the-c-title m-r-10">
                            <span class="m-r-5">{{item['AdTitle_' + lang]}}</span>                    
                        </div>                
                    </div>
                </div>        
                <div class="ac-card-desc">  
                    <div class="car-id-status">
                        <div class="ac-card-id m-r-auto">ID #{{item.CarID}}</div>
                        <div class="car-status">
                            <i class="fas fa-circle state-{{item.OfferStatus}}"></i>                            
                            <span>{{item['OfferStatus_' + lang]}}</span>
                        </div>
                    </div> 
                    <div>{{item.CYear}} - {{item.Milage | number}} {{utils.getString('km')}}</div>       
                    <div><span class="m-r-5">{{utils.getString('offer_date')}}:</span>{{item.OfferDate | date:'mediumDate'}} {{item.OfferDate | date:'shortTime'}}</div>       
                </div>
                <div class="ac-card-payment">
                    <div class="order-price"><span class="m-r-5">{{utils.getString('reserved_car_price')}}:</span>{{item.Price | number}} {{utils.getString('cur')}}</div>            
                </div>
                <div class="ac-card-payment">            
                    <div class="order-price"><span class="m-r-5">{{utils.getString('offer_amount')}}:</span>{{item.OfferAmount | number}} {{utils.getString('cur')}}</div>            
                </div>
            </div>
        </div>
    </div>
</div>