<div class="quick-search">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="browse-cars">{{utils.getString('browse')}}</h1>
            </div>
            <div class="col-12">
                <div class="quick-tabs">
                    <div class="tabs-wrapper">
                        <div class="tab-action" (click)="viewTab('makes')" [class.active]="tab == 'makes'">{{utils.getString('makes')}}</div>
                        <div class="tab-action" (click)="viewTab('models')" [class.active]="tab == 'models'">{{utils.getString('models')}}</div>
                        <div class="tab-action" (click)="viewTab('cities')" [class.active]="tab == 'cities'">{{utils.getString('cities')}}</div>
                        <div class="tab-action" (click)="viewTab('prices')" [class.active]="tab == 'prices'">{{utils.getString('prices')}}</div>
                        <div class="tab-action" (click)="viewTab('bodyTypes')" [class.active]="tab == 'bodyTypes'">{{utils.getString('bodyTypes')}}</div>
                        <div class="tab-action" (click)="viewTab('years')" [class.active]="tab == 'years'">{{utils.getString('years')}}</div>
                    </div>
                    <div class="tabs-line"></div>
                </div>
                <select class="form-control tab-select" [(ngModel)]="tab" (change)="viewTab(tab)">
                    <option value="makes">{{utils.getString('makes')}}</option>
                    <option value="models">{{utils.getString('models')}}</option>
                    <option value="cities">{{utils.getString('cities')}}</option>
                    <option value="prices">{{utils.getString('prices')}}</option>
                    <option value="bodyTypes">{{utils.getString('bodyTypes')}}</option>
                    <option value="years">{{utils.getString('years')}}</option>
                </select>
            </div>
            <div class="col-12">
                <div class="quick-tab" *ngIf="tab == 'makes'">
                    <div class="swiper makes-swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let item of makes">
                                <a href="#" 
                                routerLink="/{{lang}}/used-cars/{{makeLink(item)}}" 
                                [queryParams]="{MMID: item.MakeID + ':0'}"
                                title="{{item['MakeName_' + lang]}}" class="make-slide-item">
                                   <img src="{{utils.getImage(item.MakeLogo, 200, 200)}}" alt="GoCars" />
                                </a>
                            </div>
                        </div>
                    </div>  
                    <div class="tabs-pagination">
                        <div class="makes-pagination swiper-pagination"></div>   
                    </div>
                </div>  
                <div class="quick-tab" *ngIf="tab == 'models'">
                    <div class="swiper models-swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let item of models">
                                <a href="#" 
                                routerLink="/{{lang}}/used-cars/{{modelLink(item)}}" 
                                [queryParams]="{MMID: item.MMID}"
                                class="model-slide-item">
                                   {{item['mmname_' + lang]}}
                                </a>
                            </div>
                        </div>
                    </div>  
                    <div class="tabs-pagination">
                        <div class="models-pagination swiper-pagination"></div>   
                    </div>
                </div>  
                <div class="quick-tab" *ngIf="tab == 'cities'">
                    <div class="swiper cities-swiper links-swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let item of cities">
                                <a href="#" 
                                routerLink="/{{lang}}/used-cars/{{cityLink(item)}}" 
                                [queryParams]="{CITY: item.CityID}"
                                class="link-slide-item">
                                   {{item['CityName_' + lang]}}
                                </a>
                            </div>
                        </div>
                    </div>  
                    <div class="tabs-pagination">
                        <div class="cities-pagination swiper-pagination"></div>   
                    </div>
                </div> 
                <div class="quick-tab" *ngIf="tab == 'prices'">
                    <div class="swiper prices-swiper links-swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let item of prices">
                                <a href="#" 
                                routerLink="/{{lang}}/used-cars/{{priceLink(item)}}" 
                                [queryParams]="{MIN_PRICE: item.min, MAX_PRICE: item.max}"
                                class="link-slide-item">
                                   {{item.display}}
                                </a>
                            </div>
                        </div>
                    </div>  
                    <div class="tabs-pagination">
                        <div class="prices-pagination swiper-pagination"></div>   
                    </div>
                </div>   
                <div class="quick-tab" *ngIf="tab == 'years'">
                    <div class="swiper years-swiper links-swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let item of years">
                                <a href="#" *ngIf="item.type == 'exact'"
                                routerLink="/{{lang}}/used-cars/{{yearLink(item)}}" 
                                [queryParams]="{MIN_YEAR: item.value, MAX_YEAR: item.value}"
                                class="link-slide-item">
                                   {{item.display}}
                                </a>
                                <a href="#" *ngIf="item.type != 'exact'"
                                routerLink="/{{lang}}/used-cars/" 
                                [queryParams]="{minyear: 1960, maxyear: item.value}"
                                class="link-slide-item">
                                   {{item.display}}
                                </a>
                            </div>
                        </div>
                    </div>  
                    <div class="tabs-pagination">
                        <div class="years-pagination swiper-pagination"></div>   
                    </div>
                </div>  
                <div class="quick-tab" *ngIf="tab == 'bodyTypes'">
                    <div class="swiper bodyTypes-swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let item of bodyTypes">
                                <a href="#" 
                                routerLink="/{{lang}}/used-cars/{{bodyTypeLink(item)}}" 
                                [queryParams]="{BODY_TYPE: item.LookItemID}"
                                class="btype-slide-item">
                                   <svg width="90" height="90">       
                                    <image 
                                    [attr.xlink:href]="'../../../assets/images/body-types/' + item.LookCode + '.svg'"
                                    [attr.src]="'../../../assets/images/body-types/' + item.LookCode + '.svg'"
                                    width="90" height="90"/>    
                                   </svg>
                                   <span>{{item['LookItem_' + lang]}}</span>
                                </a>
                            </div>
                        </div>
                    </div>  
                    <div class="tabs-pagination">
                        <div class="bodyTypes-pagination swiper-pagination"></div>   
                    </div>
                </div>        
            </div>
        </div>
    </div>   
</div>