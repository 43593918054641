<header>
    <div class="test-note" *ngIf="!isProduction">TESTING</div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="top-header">
                    <a href="#" routerLink="/{{lang}}/home" class="logo">
                        <img src="../../../assets/images/logo-2022.png" alt="GoCars" />
                    </a>
                    <div class="top-nav m-l-auto">
                        <a href="#" routerLink="/{{lang}}/sell-car" class="sell-car">
                            <span class="wb">{{utils.getString('sell_your_car')}}</span>
                            <span class="mb">{{utils.getString('sell_your_car_mob')}}</span>
                        </a>
                        <a href="#" routerLink="/{{lang}}/used-cars" class="buy-car m-l-20">
                            <span class="wb">{{utils.getString('buy_car')}}</span>
                            <span class="mb">{{utils.getString('buy_car_mob')}}</span>
                        </a>
                        <span class="top-sep"></span>
                        <a href="{{utils.changeUrl()}}" *ngIf="favCount == 0" (click)="changeLanguage()"
                            class="lang-link">
                            {{utils.getString('lang')}}
                        </a>
                        <a href="#" routerLink="/{{lang}}/favourites" *ngIf="favCount > 0"
                            class="fav-heart layout-{{lang}}">
                            <i class="fal fa-heart"></i>
                            <span class="fav-counter layout-{{lang}}" *ngIf="favCount > 0">{{favCount}}</span>
                        </a>
                        <span class="top-sep"></span>
                        <button aria-label="Login or sign up" class="my-account layout-{{lang}}" *ngIf="!isLoggedIn"
                            (click)="showLoginPopup()">
                            {{utils.getString('login')}}
                        </button>
                        <a href="#" routerLink="/{{lang}}/account/dashboard" *ngIf="isLoggedIn"
                            class="profile-link my-account layout-{{lang}}">
                            <img src="../../../assets/images/user-photo.webp" class="profile-photo"
                                alt="GoCars Profile">
                            <span class="user-notiifcations layout-{{lang}}"
                                *ngIf="notificatonsCount > 0 && isLoggedIn">{{notificatonsCount}}</span>
                        </a>
                        <div class="toggle-mobile-menu m-l-20 layout-{{lang}}" (click)="toggleMoreServices()">
                            <i class="fal fa-bars"></i>
                            <div class="more-services layout-{{lang}}">
                                <a href="#" routerLink="/{{lang}}/sell-car">
                                    <i class="fal fa-car"></i>
                                    <span>{{utils.getString('sell_your_car')}}</span>
                                </a>
                                <a href="#" routerLink="/{{lang}}/used-cars">
                                    <i class="fal fa-cars"></i>
                                    <span>{{utils.getString('buy_car')}}</span>
                                </a>
                                <a href="#" routerLink="/{{lang}}/favourites">
                                    <i class="fal fa-heart"></i>
                                    <span>{{utils.getString('ac_favourites')}}</span>
                                </a>
                                <a href="#" routerLink="/{{lang}}/hire-an-expert">
                                    <i class="fal fa-car-mechanic"></i>
                                    <span>{{utils.getString('hire_an_expert')}}</span>
                                </a>
                                <a href="#" routerLink="/{{lang}}/buy-spare-parts">
                                    <i class="fal fa-tire"></i>
                                    <span>{{utils.getString('buy_spare_parts')}}</span>
                                </a>
                                <a href="#" routerLink="/{{lang}}/page/contact-us">
                                    <i class="fal fa-phone"></i>
                                    <span>{{utils.getString('contact_us')}}</span>
                                </a>
                                <a href="#" routerLink="/{{lang}}/luxury-cars-for-rent">
                                    <i class="fal fa-car-alt"></i>
                                    <span>{{utils.getString('lux')}}</span>
                                </a>
                                <a href="#" routerLink="/{{lang}}/blog">
                                    <i class="fal fa-newspaper"></i>
                                    <span>{{utils.getString('blog')}}</span>
                                </a>
                                <a href="{{utils.changeUrl()}}" (click)="changeLanguage()" class="more-services-lang">
                                    <i class="fal fa-globe"></i>
                                    <span>{{utils.getString('lang')}}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>