<section id="articlePage" *ngIf="article.ArticleID">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="car-breadcrumbs">
                    <a href="#" routerLink="/{{lang}}/home">
                        {{utils.getString('cars_home')}}
                    </a>
                    <i class="fal" [class.fa-chevron-right]="lang == 'en'" [class.fa-chevron-left]="lang == 'ar'"></i>
                    <a href="#" routerLink="/{{lang}}/blog">
                        {{utils.getString('blog')}}
                    </a>
                    <i class="fal" [class.fa-chevron-right]="lang == 'en'" [class.fa-chevron-left]="lang == 'ar'"></i>
                    <a href="#" routerLink="/{{lang}}/blog" [queryParams]="{id: article.BlogCatID}">
                        {{article['CatName_' + lang]}}
                    </a>
                </div>
                <h1 class="top-cars-title">{{article['ArticleTitle_' + lang]}}</h1>
                <div class="article-photo">
                    <img src="{{utils.getRegularIdWithSize(article.ArticlePhoto, 1920, 1080)}}" alt="{{article['ArticleTitle_' + lang]}}" />
                </div>
                <!-- <div class="row" *ngIf="categories.length > 0">
                    <div class="col-md-6 col-12" *ngFor="let article of articles">
                        <app-blog-item [article]="article"></app-blog-item>
                    </div>                    
                </div> -->
            </div>
            <div class="col-lg-7 col-12">
                <div class="article-actions">
                    <div class="article-date m-r-15">
                        <div>
                            {{article['CatName_' + lang]}}
                        </div>
                        {{article.PublishDate | date:'longDate'}} {{article.PublishDate | date:'shortTime'}}
                    </div>
                    <div class="fav-share-actions m-l-auto">
                        <a href="{{article.shareLink}}" target="_blank" class="share-car">
                            <i class="fal fa-share-square m-r-5"></i>
                            <span class="fs-label" *ngIf="lang == 'en'">
                                <span class="lrg">Share</span>
                                <span class="sml">on WhatsApp</span>
                            </span>
                            <span class="fs-label" *ngIf="lang == 'ar'">
                                <span class="lrg">مشاركة</span>
                                <span class="sml">على واتساب</span>
                            </span>
                        </a>
                    </div>                   
                </div>
                <div class="article-body clang-{{lang}}" [innerHTML]="htmlContent"></div>
            </div>
            <div class="col-lg-5 col-12" *ngIf="related.length > 0">
                <div class="read-more">{{utils.getString('blog_more')}}</div>
                <app-blog-item *ngFor="let item of related" [article]="item"></app-blog-item>
            </div>
        </div>
    </div>
</section>