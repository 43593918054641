<div class="container" *ngIf="isBrowser && isLoaded">
    <div class="row">
        <div class="col-12">
            <h1 class="web-page-title">
                <span *ngIf="lang == 'en' && car.CarID == -1">Add Car</span>
                <span *ngIf="lang == 'en' && car.CarID != -1">Update Car</span>
                <span *ngIf="lang == 'ar' && car.CarID == -1">إضافة سيارة</span>
                <span *ngIf="lang == 'ar' && car.CarID != -1">تعديل سيارة</span>
            </h1>
        </div>
    </div>
    <div class="row" *ngIf="options.makes && options.makes.length > 0">
        <div class="col-12">
            <div class="manage-car">
                <div class="order-form">
                    <div class="order-step">                     
                        <div *ngIf="car.CarID != -1">
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <div class="car-status">
                                        <i class="fas fa-circle state-{{car.AdStatus}}"></i>
                                        <span *ngIf="car.AdStatus == 0 && lang == 'en'">Waiting for Approval</span>
                                        <span *ngIf="car.AdStatus == 1 && lang == 'en'">Approved</span>
                                        <span *ngIf="car.AdStatus == 3 && lang == 'en'">Rejected</span>
                                        <span *ngIf="car.AdStatus == 0 && lang == 'ar'">بإنتظار الموافقة</span>
                                        <span *ngIf="car.AdStatus == 1 && lang == 'ar'">موافق عليه</span>
                                        <span *ngIf="car.AdStatus == 3 && lang == 'ar'">مرفوض</span>
                                    </div> 
                                </div>
                                <div class="col-md-6 col-12">
                                    <div *ngIf="!car.IsPaid" class="pay-car">
                                        <a href="{{utils.paymentUrl(car.PaymentToken)}}">{{utils.getString('pay_order')}}</a>
                                    </div>
                                </div>
                            </div> 
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <div class="gc-checkbox">
                                        <input type="checkbox" id="IsPublished" [(ngModel)]="car.IsPublished" />              
                                        <label class="gc-checklabel m-l-10" for="IsPublished">{{utils.getString('published')}}</label>                                
                                    </div> 
                                </div>
                                <div class="col-md-6 col-12">
                                    <div class="gc-checkbox">
                                        <input type="checkbox" id="IsSold" [(ngModel)]="car.IsSold" />              
                                        <label class="gc-checklabel m-l-10" for="IsSold">{{utils.getString('sold_car')}}</label>                                
                                    </div> 
                                </div>
                            </div> 
                        </div>
                        <div class="gc-field" *ngIf="car.SellBy == 2">
                          <label class="gc-label">{{utils.getString('nup_mobile_to_display')}}</label>
                          <div class="gc-input">
                            <span class="gc-input-group m-r-10">
                              <i class="fal fa-mobile"></i>
                            </span>
                            <input type="text" placeholder="" [(ngModel)]="car.MobileToDisplay" />
                          </div>
                        </div>
                        <div class="gc-field">
                          <label class="gc-label">{{utils.getString('spare_car')}}</label>
                          <div class="veh-input">
                            <app-makes-input [modelsOnly]="true" [value]="car.MMID"
                             [ui-class]="'used-cars-search'"
                            (onMakeChanged)="onMakeChanged($event)"></app-makes-input>
                          </div>
                      </div>                         
                        <!-- <div class="gc-field">
                            <label class="gc-label">{{utils.getString('makes')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="fal fa-car"></i>
                              </span>
                                <ng-multiselect-dropdown [placeholder]="utils.getString('please_select')" [settings]="makeOptions"
                                [data]="options.makes" [(ngModel)]="car.MakeObj" (onSelect)="selectMake($event)"
                                (onDeSelect)="selectMake($event)" [disabled]="car.CarID != -1">
                                </ng-multiselect-dropdown>              
                            </div>
                        </div>  
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('models')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="fal fa-car-alt"></i>
                              </span>
                                <ng-multiselect-dropdown [placeholder]="utils.getString('please_select')" [settings]="modelOptions"
                                [data]="filtered_models" [(ngModel)]="car.ModelObj" (onSelect)="selectModel($event)"
                                (onDeSelect)="selectModel($event)" [disabled]="car.CarID != -1">
                                </ng-multiselect-dropdown>              
                            </div>
                        </div>    -->
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('trim')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="fal fa-car-side"></i>
                              </span>
                              <input type="text" placeholder="" [(ngModel)]="car.Class" />
                            </div>
                        </div>
                        <div class="gc-field">
                          <label class="gc-label">{{utils.getString('nup_color')}}</label>
                          <div class="gc-input">
                            <span class="gc-input-group m-r-10">
                              <i class="fal fa-tint"></i>
                            </span>
                            <select [(ngModel)]="car.Color">  
                              <option [value]="-999">-</option>                            
                              <option [value]="item.value" *ngFor="let item of options.colors">{{item['display_' + lang]}}</option>                                
                            </select>           
                          </div>
                        </div> 
                        <div class="gc-field">
                          <label class="gc-label">{{utils.getString('fl_need_maintenance')}}</label>
                          <div class="gc-input">
                            <span class="gc-input-group m-r-10">
                              <i class="fal fa-car-crash"></i>
                            </span>
                            <select [(ngModel)]="car.NeedMaintenance" *ngIf="lang == 'en'">
                              <option [value]="0">No</option>                              
                              <option [value]="1">Yes</option>                              
                            </select>    
                            <select [(ngModel)]="car.NeedMaintenance" *ngIf="lang == 'ar'">
                              <option [value]="0">لا</option>                              
                              <option [value]="1">نعم</option>                              
                            </select>           
                          </div>
                      </div> 
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('year')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="fal fa-calendar-alt"></i>
                            </span>
                              <input type="number" placeholder="" [(ngModel)]="car.CYear" />
                            </div>
                        </div> 
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('fl_milage_price')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="fal fa-steering-wheel"></i>
                            </span>
                              <input type="number" placeholder="" [(ngModel)]="car.Milage" />
                            </div>
                        </div> 
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('price')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="fal fa-money-bill-wave-alt"></i>
                            </span>
                              <input type="number" placeholder="" [(ngModel)]="car.Price" />
                            </div>
                        </div> 
                        <!-- <div class="gc-field">
                          <label class="gc-label">{{utils.getString('nup_price_rate')}}</label>
                          <div class="gc-input">
                            <span class="gc-input-group m-r-10">
                              <i class="fal fa-tag"></i>
                            </span>
                            <select [(ngModel)]="car.PriceRate">                              
                              <option [value]="item.value" *ngFor="let item of options.priceRates">{{item['display_' + lang]}}</option>                                
                            </select>           
                          </div>
                        </div>  -->
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('nup_negotiable')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="fal fa-tags"></i>
                              </span>
                              <select [(ngModel)]="car.Negotiable" *ngIf="lang == 'en'">
                                <option [value]="0">No</option>                              
                                <option [value]="1">Yes</option>                              
                              </select>    
                              <select [(ngModel)]="car.Negotiable" *ngIf="lang == 'ar'">
                                <option [value]="0">لا</option>                              
                                <option [value]="1">نعم</option>                              
                              </select>           
                            </div>
                        </div> 
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('fl_body_type')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="bi bi-card-text"></i>
                              </span>
                              <select [(ngModel)]="car.BodyType">
                                <option [value]="-999">-</option>
                                <option [value]="item.value" *ngFor="let item of options.bodyTypes">{{item['display_' + lang]}}</option>                                
                              </select>           
                            </div>
                        </div> 
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('fl_specs_type')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="bi bi-card-text"></i>
                              </span>
                              <select [(ngModel)]="car.Specs">
                                <option [value]="-999">-</option>
                                <option [value]="item.value" *ngFor="let item of options.specs">{{item['display_' + lang]}}</option>                                
                              </select>           
                            </div>
                        </div>                           
                        <div class="gc-field" *ngIf="car.Specs == 2 || car.VIN">
                            <label class="gc-label">{{utils.getString('vin')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="fal fa-car-side"></i>
                              </span>
                              <input type="text" placeholder="" [(ngModel)]="car.VIN" />
                            </div>
                        </div>
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('fl_transmission')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="bi bi-card-text"></i>
                              </span>
                              <select [(ngModel)]="car.Transmission">
                                <option [value]="-999">-</option>
                                <option [value]="item.value" *ngFor="let item of options.transmission">{{item['display_' + lang]}}</option>                                
                              </select>           
                            </div>
                        </div> 
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('fl_fuel')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="bi bi-card-text"></i>
                              </span>
                              <select [(ngModel)]="car.Fuel">
                                <option [value]="-999">-</option>
                                <option [value]="item.value" *ngFor="let item of options.fuel">{{item['display_' + lang]}}</option>                                
                              </select>           
                            </div>
                        </div> 
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('warranty')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="bi bi-card-text"></i>
                              </span>
                              <select [(ngModel)]="car.Warranty">
                                <option [value]="-999">-</option>
                                <option [value]="item.value" *ngFor="let item of options.warranty">{{item['display_' + lang]}}</option>                                
                              </select>           
                            </div>
                        </div> 
                        <div class="gc-field">
                          <label class="gc-label">{{utils.getString('nup_no_of_keys')}}</label>
                          <div class="gc-input">
                            <span class="gc-input-group m-r-10">
                              <i class="fal fa-key"></i>
                          </span>
                            <input type="number" placeholder="" [(ngModel)]="car.NoOfKeys" />
                          </div>
                        </div> 
                        <div class="row" *ngIf="!car.IsPaid && car.SellBy == 1">
                            <div class="col-12">
                                <div class="when_inspect">
                                    {{utils.getString('when_inspect')}}
                                </div>
                            </div>
                            <div class="col-lg-6 col-12">
                                <div class="gc-field">
                                    <label class="gc-label">{{utils.getString('select_date')}}</label>
                                    <div class="gc-input">
                                      <input matInput [matDatepicker]="picker1" (click)="picker1.open()" 
                                      [(ngModel)]="car.AppointmentDate" readonly="readonly" [min]="minDate" [max]="maxDate">                      
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                       <mat-datepicker #picker1></mat-datepicker>         
                                    </div>
                                </div>  
                            </div>
                            <div class="col-lg-6 col-12">
                                <div class="gc-field">
                                    <label class="gc-label">{{utils.getString('select_time')}}</label>                            
                                    <div class="gc-input">
                                        <span class="gc-input-group m-r-10">
                                            <i class="fal fa-clock"></i>
                                        </span>
                                        <select [(ngModel)]="car.AppointmentTime">
                                            <option value=""></option>
                                            <option value="{{time.value}}" *ngFor="let time of timeslots">{{time.display}}</option>
                                        </select>       
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-12">
                                <div class="gc-field">
                                    <label class="gc-label">{{utils.getString('city')}}</label>
                                    <div class="gc-input">
                                      <span class="gc-input-group m-r-10">
                                        <i class="fal fa-city"></i>
                                      </span>
                                        <ng-multiselect-dropdown [placeholder]="utils.getString('please_select')" [settings]="cityOptions"
                                        [data]="cities" [(ngModel)]="car.City" (onSelect)="selectCity($event)"
                                        (onDeSelect)="selectCity($event)">
                                        </ng-multiselect-dropdown>              
                                    </div>
                                </div>   
                            </div>
                            <div class="col-lg-6 col-12">
                                <div class="gc-field">
                                    <label class="gc-label">{{utils.getString('area')}}</label>
                                    <div class="gc-input">
                                      <span class="gc-input-group m-r-10">
                                        <i class="fal fa-map-marked"></i>
                                      </span>
                                        <ng-multiselect-dropdown [placeholder]="utils.getString('please_select')" [settings]="areaOptions"
                                        [data]="areas" [(ngModel)]="car.Area" (onSelect)="selectArea($event)"
                                        (onDeSelect)="selectArea($event)">
                                        </ng-multiselect-dropdown>              
                                    </div>
                                </div>
                            </div>
                        </div>  
                        <!-- <div class="gc-field" *ngIf="car.SellBy == 2 || (car.SellBy == 1 && car.CarID != -1)">
                            <label class="gc-label">{{utils.getString('main_photo')}}</label>
                            <div class="gc-input">
                              <input type="file" accept="image/*" [disabled]="isLoading" (change)="uploadMainPhoto($event)" />         
                            </div>
                        </div>  -->
                        <!-- <label class="gc-hint" *ngIf="car.SellBy == 2 || (car.SellBy == 1 && car.CarID != -1)">{{utils.getString('main_photo_recommended')}}</label>
                        <div class="main-photo" *ngIf="car.MainPhoto && car.SellBy == 2 || (car.SellBy == 1 && car.CarID != -1)">
                            <img src="{{utils.getLargeCarPhoto(car.MainPhoto)}}" alt="Go Cars" />
                        </div> -->
                        <div class="gc-field" *ngIf="car.SellBy == 2 || (car.SellBy == 1 && car.CarID != -1)">
                            <label class="gc-label">{{utils.getString('additional_photos')}} ({{utils.getString('additional_photos_min')}})</label>
                            <input id="uploader" type="file" accept="image/*" multiple [disabled]="isLoading" (change)="uploadMorePhoto($event)" />         
                        </div>                                                 
                        <!-- <div class="more-photos-list" *ngIf="car.SellBy == 2 || (car.SellBy == 1 && car.CarID != -1)">
                            <div class="extra-photo" *ngFor="let photo of uploadedPhotos">
                                <img src="{{utils.getLargeCarPhoto(photo)}}" alt="Go Cars" />
                                <button class="remove-image layout-{{lang}}" (click)="removeImage(photo)"><i class="far fa-trash-alt"></i></button>
                            </div>
                        </div> -->
                        <div class="uploaded-sortables" *ngIf="car.SellBy == 2 || (car.SellBy == 1 && car.CarID != -1)">
                          <app-multi-upload [items]="uploadedPhotos"></app-multi-upload>
                        </div>
                        <div class="featured" *ngIf="lang == 'en' && car.CarID == -1">
                          <h1>Need a featured ad?</h1>
                          <p>
                            Get a featured for only <b>{{options.fees[0].FeaturedAdPrice}} {{utils.getString('cur')}}</b>
                            <span>
                              <i class="fal fa-check"></i> Your ad will show on top
                            </span>
                            <span>
                              <i class="fal fa-check"></i> Sell faster and better
                            </span>
                            <span>
                              <i class="fal fa-check"></i> Get more potential buyers
                            </span>
                          </p>
                          <div class="gc-checkbox">
                            <input type="checkbox" id="IsFeatured" [(ngModel)]="car.IsFeatured" />              
                            <label class="gc-checklabel m-l-10" for="IsFeatured">Yes, I need a featured ad</label>                                
                          </div> 
                        </div>
                        <div class="featured" *ngIf="lang == 'ar' && car.CarID == -1">
                          <h1>هل تريد إعلان مميز؟</h1>
                          <p>
                            أحصل على إعلان مميز مقابل <b>{{options.fees[0].FeaturedAdPrice}} {{utils.getString('cur')}}</b> فقط
                            <span>
                              <i class="fal fa-check"></i> إعلانك سيظهر أولا
                            </span>
                            <span>
                              <i class="fal fa-check"></i> بيع أسرع وبشكل أفضل
                            </span>
                            <span>
                              <i class="fal fa-check"></i> أحصل على المزيد من المشترين المحتملين
                            </span>
                          </p>
                          <div class="gc-checkbox">
                            <input type="checkbox" id="IsFeatured" [(ngModel)]="car.IsFeatured" />              
                            <label class="gc-checklabel m-l-10" for="IsFeatured">نعم, أريد إعلان مميز</label>                                
                          </div> 
                        </div>
                        <div class="payment-summary" *ngIf="lang == 'en' && car.CarID == -1 && totalToPay() > 0">
                          TOTAL TO PAY: <b>{{totalToPay()}} {{utils.getString('cur')}}</b>
                        </div>
                        <div class="payment-summary" *ngIf="lang == 'ar' && car.CarID == -1 && totalToPay() > 0">
                          أجمالي المبلغ: <b>{{totalToPay()}} {{utils.getString('cur')}}</b>
                        </div>
                        <div class="manage-actions">
                          <button class="cancel-action m-r-10" (click)="cancelCar()" [disabled]="isLoading">
                            {{utils.getString('cancel')}}
                          </button>
                          <button class="save-action" (click)="submitCar()" [disabled]="isLoading">
                            <span *ngIf="lang == 'en' && car.CarID == -1">SUBMIT YOUR AD</span>
                            <span *ngIf="lang == 'en' && car.CarID != -1">SAVE CHANGES</span>
                            <span *ngIf="lang == 'ar' && car.CarID == -1">حفظ الإعلان</span>
                            <span *ngIf="lang == 'ar' && car.CarID != -1">حفظ التغييرات</span>
                          </button>
                        </div>
                    </div>                    
                </div>               
            </div>
        </div>
    </div>
</div>