import { Injectable } from '@angular/core';
import { config } from '../config';
import { Api } from '../http/Api';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  controller:string = 'Shared';

  constructor(private api: Api) { }

  getWebPage(id) {
    return this.api.get(`${this.controller}/meta?id=${id}`);
  }

  getMakesModels() {
    return this.api.get(`${this.controller}/get-makes-and-models`);
  }

  getModels() {
    return this.api.get(`${this.controller}/get-models`);
  }

  getUsedFilters() {
    return this.api.get(`${this.controller}/get-cars-filters`);
  }

  getHomeFilters() {
    return this.api.get(`${this.controller}/get-home-filters`);
  }

  getFooterLinks() {
    return this.api.get(`${this.controller}/get-footer-links`);
  }

  getSection(id) {
    return this.api.get(`${this.controller}/get-section?id=${id}`);
  }

  getCountryLocations() {
    return this.api.get(`${this.controller}/get-country-locations`);
  }  

  getFees() {
    return this.api.get(`${this.controller}/get-fees`);
  }  

  upload(file){
    return this.api.customUpload(file);
  }
}
