import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-car-financing',
  templateUrl: './car-financing.component.html',
  styleUrls: ['./car-financing.component.less']
})
export class CarFinancingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
