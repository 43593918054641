<a href="#" routerLink="/{{lang}}/account/buy-orders">
    {{utils.getString('back')}}
</a>
<div class="acc-title-wrapper">
    <h3 class="acc-title">{{utils.getString('buy_online')}}</h3>
</div>
<div class="acc-list-view">
    <div class="acc-card" *ngFor="let item of orders">
        <div class="card-img-wrapper">
            <img class="card-img m-r-15" src="{{utils.getLargeCarPhoto(item.MainPhoto)}}" alt="Go Cars" />
            <div class="cim-info">
                <div class="ac-card-title">
                    <div class="card-title-wrapper">
                        <div class="the-c-title m-r-10">
                            <span class="m-r-5">{{item['AdTitle_' + lang]}}</span>
                        </div>
                    </div>
                </div>        
                <div class="ac-card-desc">  
                    <div class="car-id-status">
                        <div class="ac-card-id m-r-auto">ID #{{item.CarID}}</div>
                    </div>           
                    <div>{{item.CYear}} - {{item.Milage | number}} {{utils.getString('km')}}</div>       
                </div>
                <div class="ac-card-payment">
                    <div class="order-price" *ngIf="lang == 'en'">
                        Paid 
                        {{item.TotalPaid}}
                        of
                        {{item.Price | number}} {{utils.getString('cur')}}
                    </div>
                    <div class="order-price" *ngIf="lang == 'ar'">
                        تم دفع 
                        {{item.TotalPaid}}
                        من
                        {{item.Price | number}} {{utils.getString('cur')}}
                    </div>
                    <div class="order-payment m-l-auto">               
                        <span *ngIf="item.NoOfPayments == item.PaymentsDone" class="paid-order">{{utils.getString('paid')}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="payments-summary" *ngIf="orders.length == 1">
    <div class="payment-counter" *ngIf="orders[0].NoOfPayments != orders[0].PaymentsDone">
        <span *ngIf="lang == 'en'">
            You need to make ({{orders[0].NoOfPayments - orders[0].PaymentsDone}}) payments to complete your purchase order
        </span>
        <span *ngIf="lang == 'ar'">
            يجب عليك إتمام ({{orders[0].NoOfPayments - orders[0].PaymentsDone}}) دفعات لإكمال طلب الشراء الخاص بك
        </span>
    </div>
</div>
<div class="purchase-transactions">
    <div class="purchase-paynemt" *ngFor="let item of payments; let i = index">
        <div class="payment-reference">
            <span *ngIf="lang == 'en'">Payment {{i+1}} / {{orders[0].NoOfPayments}}</span>
            <span *ngIf="lang == 'ar'">الدفعة {{i+1}} / {{orders[0].NoOfPayments}}</span>
        </div>
        <div class="payment-booter">
            <span class="order-price m-r-auto">
                {{item.Amount | number}} {{utils.getString('cur')}}
            </span>
            <span *ngIf="item.IsPaid" class="paid-order">{{utils.getString('paid')}}</span>
            <a *ngIf="!item.IsPaid" href="{{utils.paymentUrl(item.PaymentToken)}}" class="pay-order">{{utils.getString('pay_order')}}</a>
        </div>
    </div>
</div>