import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../classes/utils';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';
import { config } from '../../config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {

  lang:string = '';
  isBrowser:boolean;
  settings:any = {};
  year:number = 2022;
  makes = [];
  models = [];
  bodyTypes = [];
  cities = [];
  prices = [];
  years = [];
  siteCountry:string = '';
  siteCountry_ar:string = '';

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private metaTagService: Meta,
    private pageTitle: Title,
    private service: SharedService,
    private sanitizer: DomSanitizer) {
      this.siteCountry = config.siteCountry;
      this.siteCountry_ar = config.siteCountry_ar;
      this.isBrowser = this.utils.isBrowser();
      this.router.events.subscribe((url: NavigationEnd) => {
        if (url && url.urlAfterRedirects) {
          this.lang = this.utils.getLanguage();       
        }
        return;
      });   
   }

  ngOnInit(): void {
    if(this.isBrowser){
      this.year = new Date().getFullYear();      
    }   
    this.service.getFooterLinks().then(resp => {
      this.makes = resp.data.makes;
      this.models = resp.data.models;
      this.bodyTypes = resp.data.bodyTypes;
      this.cities = resp.data.cities;
      this.settings = resp.data.settings[0];
      
      this.prices.push({min: 15, max: 25,value: 25000, display: '25k'});
      this.prices.push({min: 25, max: 35,value: 35000, display: '35k'});
      this.prices.push({min: 35, max: 45,value: 45000, display: '45k'});
      this.prices.push({min: 45, max: 55,value: 55000, display: '55k'});
      this.prices.push({min: 65, max: 75,value: 75000, display: '75k'});
      this.prices.push({min: 85, max: 100,value: 100000, display: '100k'});

      let endYear:number = new Date().getFullYear() - 2;
      let startYear:number = endYear - 7;
      for(var i = startYear; i <= endYear; i++){
        this.years.push({value: i, display: i, type: 'exact'});
      }
    });
  }

  used(txt){
    if(this.lang == 'en'){
      return `Used ${txt} for sale in ${this.siteCountry}`;
    } else {
      return `سيارات ${txt} مستعملة للبيع في ${this.siteCountry_ar}`;
    }
  }

  usedPrice(txt){
    if(this.lang == 'en'){
      return `Used Cars under ${txt} ${this.utils.getString('cur')} for sale in ${this.siteCountry}`;
    } else {
      return `سيارات مستعملة أقل من ${txt} ${this.utils.getString('cur')} للبيع في ${this.siteCountry_ar}`;
    }
  }

  makeLink(obj){
    return `${this.utils.getStrUrl(obj.MakeName_en)}-for-sale-in-${this.siteCountry}`;
  }

  modelLink(obj){
    return `${this.utils.getStrUrl(obj.mmname_en)}-for-sale-in-${this.siteCountry}`;
  }

  cityLink(obj){
    return `cars-for-sale-in-${this.utils.getStrUrl(obj.CityName_en)}`;
  }

  sellInCityLink(obj){
    return `sell-any-car-in-${this.utils.getStrUrl(obj.CityName_en)}`;
  }

  bodyTypeLink(obj){
    return `${this.utils.getStrUrl(obj.LookItem_en)}-for-sale-in-${this.siteCountry}`;
  }

  yearLink(obj){
    return `${obj.value}-cars-for-sale-in-${this.siteCountry}`;
  }

  priceLink(obj){
    return `below-${obj.value}-for-sale-in-${this.siteCountry}`;
  }
}
