import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kzFilter'
})
export class KzFilterPipe implements PipeTransform {

  transform(items: any[], filter: Object): any {
    if (!items || !filter) {
        return items;
    }

    var props = Object.keys(filter);
    for(var i = 0; i < props.length; i++){
      items = items.filter(item => item[props[i]] == filter[props[i]])
    }

    return items;
  }
}
