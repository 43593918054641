import { Injectable } from '@angular/core';
import { config } from '../config';
import { Api } from '../http/Api';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  controller:string = 'Profile';

  constructor(private api: Api) { }

  sendOTP(to){
    return this.api.get(`verify/send-otp?to=${to}`);
  }

  verifyOTP(mobileNumber, otp){
    return this.api.get(`verify/verify-otp?mobileNumber=${mobileNumber}&otp=${otp}`);
  }
 
  register(user){
    return this.api.post(this.controller + `/register`, user);
  }

  update(user){
    return this.api.post(this.controller + `/update`, user);
  }

  deleteAccount(){
    return this.api.post(this.controller + `/delete-account`);
  }

  changelang(lang){
    return this.api.post(this.controller + `/change-lang?lang=${lang}`);
  }

  getProfile(){
    return this.api.get(this.controller + `/get-profile`);
  }

  getExpertOrders() {
    return this.api.get(`${this.controller}/get-expert-orders`);
  }

  getMyCars() {
    return this.api.get(`${this.controller}/get-my-cars`);
  }

  getBuyCars() {
    return this.api.get(`${this.controller}/get-buy-cars`);
  }

  getBuyPayments(id:number) {
    return this.api.get(`${this.controller}/get-buy-payments?id=${id}`);
  }

  getReservedCars() {
    return this.api.get(`${this.controller}/get-reserved-cars`);
  }

  getOfferedCars() {
    return this.api.get(`${this.controller}/get-offered-cars`);
  }

  getSparePartsOrders() {
    return this.api.get(`${this.controller}/get-spare-parts-orders`);
  }

  getNotificationsCount() {
    return this.api.get(`${this.controller}/get-notifications-count`);
  }  

  getNotifications() {
    return this.api.get(`${this.controller}/get-notifications`);
  } 
  
  readAllNotifications() {
    return this.api.get(`${this.controller}/read-all-stored-notifications`);
  }  

  readNotification(pushId) {
    return this.api.get(`${this.controller}/read-stored-notifications?pushId=${pushId}`);
  }    

  getDashboard() {
    return this.api.get(`${this.controller}/get-dashboard`);
  } 
}
