import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../../classes/utils';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less']
})
export class ProfileComponent implements OnInit {

  lang:string = '';
  isBrowser:boolean;
  isLoggedIn:boolean;
  phoneCode:string = '971';
  profile:any = {};
  isLoading:boolean = true;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private userService: UserService) {
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();   
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;
        if(!_isLoggedIn){
          this.lang = this.utils.getLanguage();     
          this.router.navigate([`/${this.lang}/home`]);
        }
      });
   }

  ngOnInit(): void {
    if(this.isBrowser) this.getData();
  }

  getData(){
    this.userService.getProfile().then(data => {
      if(data.length == 1){
        this.profile = data[0];
        if(this.isBrowser){
          localStorage.first_name = this.profile.FirstName;
          localStorage.last_name = this.profile.LastName;    
        }
        this.isLoading = false;
      } else {
        if(this.isBrowser) this.utils.doLogout();
        this.router.navigate([`/${this.lang}/home`]);
      }       
    });
  }

  save(){        
    if(this.profile.FirstName && this.profile.LastName && this.profile.EmailAddress){
      let regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (regex.test(this.profile.EmailAddress)) {      
        let user = {
          PreferredLanguage: this.profile.PreferredLanguage,
          EmailAddress: this.profile.EmailAddress,
          FirstName: this.profile.FirstName,
          LastName: this.profile.LastName,
        }
        this.isLoading = true;
        this.userService.update(user).then(data => {
          let profile = data[0];  
          this.isLoading = false;       
          if(this.isBrowser){
            localStorage.first_name = this.profile.FirstName;
            localStorage.last_name = this.profile.LastName;    
          }
          this.utils.success();
        });
      } else {
        this.utils.alert(this.utils.getString('invalidEmail'), 'error');
      }
    } else {
      this.utils.alert(this.utils.getString('requiredRegistrationFields'), 'error');
    }
  }

  logout(){
    if(this.isBrowser){
      if(confirm(this.utils.getString('confirm_logout'))) this.utils.doLogout();
    }
  }
}
