<div class="lux-item">
    <div class="lux-photo-slider">
        <div class="swiper lux-swiper-{{car.LuxCarID}}">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <img src="{{utils.getRegularFile(car.Photo1)}}" alt="{{car['LuxTitle_' + lang]}}" />
                </div>
                <div class="swiper-slide">
                    <img src="{{utils.getRegularFile(car.Photo2)}}" alt="{{car['LuxTitle_' + lang]}}" />
                </div>
                <div class="swiper-slide">
                    <img src="{{utils.getRegularFile(car.Photo3)}}" alt="{{car['LuxTitle_' + lang]}}" />
                </div>
            </div>
        </div>  
        <div class="lux-slider-pagination">
            <div class="lux-pagination-{{car.LuxCarID}} swiper-pagination"></div>   
        </div>  
    </div>
    <div class="lux-info">
        <div class="the-title">{{car['LuxTitle_' + lang]}}</div>
        <div class="lux-specx">
            <span>{{car.CYear}}</span>
            <i class="fas fa-circle"></i>
            <span>{{car['Fuel_' + lang]}}</span>
            <i class="fas fa-circle"></i>
            <span>{{car['Transmission_' + lang]}}</span>
        </div>
        <div class="lux-price">
            <div class="starting">{{utils.getString('lux_price')}}</div>
            <div class="lux-the-price">
                <span class="m-r-5" *ngIf="lang == 'en'">{{utils.getString('cur')}} {{car.StartingPrice | number}}</span>
                <span class="m-r-5" *ngIf="lang == 'ar'">{{car.StartingPrice | number}} {{utils.getString('cur')}}</span>
                <span class="per-day">{{utils.getString('lux_per_day')}}</span>
            </div>
        </div>
        <div class="lux-book">
            <a href="{{whatsappLink()}}" target="_blank" class="send-msg">
                <i class="fab fa-whatsapp"></i><span>{{utils.getString('lux_msg')}}</span>
            </a>
            <a href="tel:{{car.ContactNumber}}" target="_blank" class="lux-call m-l-auto">
                <i class="fal fa-mobile-alt"></i><span>{{utils.getString('lux_call')}}</span>
            </a>
        </div>
    </div>
</div>