<div class="site site-lang-{{lang}} os-{{os}}" [class.ssr-not-loaded]="!isBrowser">
  <app-header></app-header>
  <div class="website">  
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>  
  <div id="loginModal" class="kz-modal" *ngIf="showLogin && isBrowser">
    <div class="kz-modal-overlay"></div>
    <div class="kz-modal-dialog">
      <div class="kz-modal-header">
        <button *ngIf="step != 'mobile'" (click)="step = 'mobile'">
          <i class="fal fa-arrow-left" *ngIf="lang == 'en'"></i>
          <i class="fal fa-arrow-right" *ngIf="lang == 'ar'"></i>
        </button>
        <button class="kz-close-modal m-l-auto" (click)="showLogin = false"><i class="fal fa-times"></i></button>
      </div>
      <div class="kz-modal-body">
        <div class="kz-modal-title">{{utils.getString('login_or_signup')}}</div>
        <div *ngIf="step == 'mobile'">
          <div class="gc-field">
            <div class="gc-input">
              <!-- <span class="gc-input-group m-r-10">
                <i class="fal fa-mobile"></i><span class="m-l-5">+{{phoneCode}}</span>
              </span> -->
              <select class="phone-codes" [(ngModel)]="phoneCode">
                <option *ngFor="let item of countriesList" value="{{item.PhoneCode}}">{{item.CountryName_en}} (+{{item.PhoneCode}})</option>
              </select>
            </div>
          </div>  
          <div class="gc-field">
            <label class="gc-label">{{utils.getString('your_mobile')}}</label>
            <div class="gc-input">
              <!-- <span class="gc-input-group m-r-10">
                <i class="fal fa-mobile"></i><span class="m-l-5">+{{phoneCode}}</span>
              </span> -->
              <input type="tel" maxlength="10" placeholder="5xxxxxxxx" [(ngModel)]="mobileNumber" />
            </div>
          </div>  
          <button class="login-action" (click)="sendOTP()" [disabled]="mobileNumber.length < 9 || isLoginLoading">{{utils.getString('send_otp')}}</button>
        </div>
        <div *ngIf="step == 'otp'">
          <div class="otp-hint">
            <div class="hint-label">{{utils.getString('otp')}}</div>
            <div class="otp-display-mobile">{{mobileToDisplayOTP}}</div>
          </div>
          <app-otp (otpChanged)="otpInput = $event"></app-otp> 
          <button class="login-action" (click)="verifyOTP()" [disabled]="isLoginLoading || otpInput.length !== 6">{{utils.getString('verify_otp')}}</button>
          <div class="reset-message" *ngIf="timer > 0">{{getTimerString()}}</div>
          <div class="resend-code" *ngIf="timer == 0">
            <button class="resend-button" (click)="sendOTP()">{{utils.getString('resend')}}</button>
          </div>
        </div>
        <div *ngIf="step == 'reg'">
          <div class="gc-field">
            <label class="gc-label">{{utils.getString('your_fname')}}</label>
            <div class="gc-input">
              <span class="gc-input-group m-r-10">
                <i class="fal fa-user"></i>
              </span>
              <input type="text" placeholder="" [(ngModel)]="firstName" />
            </div>
          </div> 
          <div class="gc-field">
            <label class="gc-label">{{utils.getString('your_lname')}}</label>
            <div class="gc-input">
              <span class="gc-input-group m-r-10">
                <i class="fal fa-user"></i>
              </span>
              <input type="text" placeholder="" [(ngModel)]="lastName" />
            </div>
          </div> 
          <div class="gc-field">
            <label class="gc-label">{{utils.getString('your_email')}}</label>
            <div class="gc-input">
              <span class="gc-input-group m-r-10">
                <i class="fal fa-envelope"></i>
              </span>
              <input type="text" placeholder="" [(ngModel)]="email" />
            </div>
          </div>  
          <button class="login-action" (click)="signUp()" [disabled]="mobileNumber.length < 9 || isLoginLoading">{{utils.getString('signup')}}</button>
        </div>
        <div class="login-terms" *ngIf="lang == 'en'">
          By signing up I agree to the <a href="#" target="_blank" routerLink="/{{lang}}/page/terms-and-conditions">Terms and Conditions</a> and <a href="#" target="_blank" routerLink="/{{lang}}/page/privacy-policy">Privacy Policy</a>
        </div>  
        <div class="login-terms" *ngIf="lang == 'ar'">
          بالتسجيل أنا أوافق على <a href="#" target="_blank" routerLink="/{{lang}}/page/terms-and-conditions">الشروط والأحكام</a> و <a href="#" target="_blank" routerLink="/{{lang}}/page/privacy-policy">سياسة الخصوصية</a>
        </div>      
      </div>
    </div>
  </div>
</div>