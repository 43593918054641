import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../classes/utils';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';
import { CarsService } from '../../services/cars.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { config } from '../../config';

@Component({
  selector: 'app-hire-an-expert',
  templateUrl: './hire-an-expert.component.html',
  styleUrls: ['./hire-an-expert.component.less']
})
export class HireAnExpertComponent implements OnInit {

  lang:string = '';
  isBrowser:boolean;
  webPage:any = {};  
  isLoggedIn:boolean;
  htmlContent:any;
  section = [];
  isLoading:boolean = false;
  fees:any = {};
  minDate:Date;
  order:any = {
    NoOfBookings: 1,
    CityID: null,
    AreaID: null,
    b1_date: null,
    b1_time: '',
    b2_date: null,
    b2_time: '',
    b3_date: null,
    b3_time: ''
  };
  cityOptions:IDropdownSettings = {};
  areaOptions:IDropdownSettings = {};
  cities:any[] = [];
  all_areas:any[] = [];
  areas:any[] = [];
  timeslots = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private carsService: CarsService,
    private metaTagService: Meta,
    private pageTitle: Title,
    private service: SharedService,
    private sanitizer: DomSanitizer) {
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();   
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;
      });       
   }

  ngOnInit(): void {
    this.getMetaTags('hire-an-expert');
    this.minDate = new Date();
    this.minDate.setDate( this.minDate.getDate() + 2 );
    this.service.getSection('hire-an-expert').then(resp => {
      this.section = resp.data.section;
     });
     this.getData();
  }

  async getData(){
    this.isLoading = true;
    this.fees = await this.getFees();
    this.getCitiesAndAreas();
  }

  getFees():Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.service.getFees().then(resp => {        
        resolve(resp.data.fees[0]);
      });
    });
  }

  getCitiesAndAreas(){
    this.service.getCountryLocations().then(resp => {
      this.isLoading = false;
      this.cities = resp.data.cities;
      this.all_areas = resp.data.areas;
      this.timeslots = resp.data.timeslots;
      this.cityOptions = {
        singleSelection: true,
        closeDropDownOnSelection: true,
        idField: 'CityID',
        textField: `CityName_${this.lang}`,
        itemsShowLimit: 1,
        allowSearchFilter: true,
        limitSelection: 2
      };
      this.areaOptions = {
        singleSelection: true,
        closeDropDownOnSelection: true,
        idField: 'AreaID',
        textField: `AreaName_${this.lang}`,
        itemsShowLimit: 1,
        allowSearchFilter: true,
        limitSelection: 2
      };
    });   
  }
  selectCity(event){
    if(this.order.City.length == 1){
      this.order.CityID = this.order.City[0].CityID;    
    } else {
      this.order.City = [event];
      this.order.CityID = this.order.City[0].CityID;    
    }    
    this.order.Area = [];
    this.order.AreaID = null;
    this.areas = this.all_areas.filter(function(el){
      return el.CityID == event.CityID
    });
  }
  selectArea(event){
    if(this.order.Area.length == 1){
      this.order.AreaID = this.order.Area[0].AreaID;    
    } else {
      this.order.Area = [event];
      this.order.AreaID = this.order.Area[0].AreaID;    
    }    
  }

  getMetaTags(pageId) {
    this.service.getWebPage(pageId).then(data => {
      if(data.data.page.length > 0){
        this.webPage = data.data.page[0];        
        this.pageTitle.setTitle(this.webPage.pgTitle);  
        this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.webPage.PageContent);                
         this.metaTagService.addTags([
           { name: 'keywords', content: this.webPage.ggKeywords },
           { name: 'description', content: this.webPage.ggDescription },
           { name: 'og:title', content: this.webPage.ogTitle },
           { name: 'og:description', content: this.webPage.ogDescription },
           { name: 'og:site_name', content: config.meta.siteName },
           { name: 'og:type', content: 'Website' },
           { name: 'og:image', content: (this.webPage.ogImage ? this.utils.getImage(this.webPage.ogImage, 1200, 630) : config.meta.og_image ) },
           { name: 'og:url', content: config.meta.siteUrl + this.router.url },
         ]);         
      } else {
        this.pageTitle.setTitle(config.meta.siteName);
      }      
    });     
  }

  submitOrder(){
    if(this.isBrowser){
      let validForm:boolean = true;
      if(!this.order.CityID) validForm = false;
      if(!this.order.AreaID) validForm = false;
      if(!this.order.b1_date) validForm = false;
      if(!this.order.b1_time) validForm = false;
      if(this.order.no_of_bookings > 1 && !this.order.b2_date) validForm = false;
      if(this.order.no_of_bookings > 1 && !this.order.b2_time) validForm = false;
      if(this.order.no_of_bookings > 1 && !this.order.b3_date) validForm = false;
      if(this.order.no_of_bookings > 1 && !this.order.b3_date) validForm = false;
      if(validForm){
        if(this.isLoggedIn){
          this.isLoading = true;
          if(this.order.NoOfBookings == 1){
            this.order.b2_date = this.order.b1_date;
            this.order.b3_date = this.order.b1_date;
            this.order.b2_time = this.order.b1_time;
            this.order.b3_time = this.order.b1_time;          
          }
          this.carsService.hireAnExpert(this.order).then(resp => {
            if(resp){
              if(this.isBrowser){
                let paymentLink = this.utils.paymentUrl(resp);              
                location.href = paymentLink;
              }            
            } else {
              this.utils.alert(this.utils.getString('tryagain'), 'error');   
              this.isLoading = false;
            }
          });          
        } else {
          window['showLoginPopup']();
          this.isLoading = false;
        }        
      } else {
        this.utils.alert(this.utils.getString('requiredFields'), 'error');
        this.isLoading = false;
      }
    }
  }
}
