<div class="container" *ngIf="webPage.PageTitle">
    <div class="row">
        <div class="col-12">
            <h1 class="web-page-title">{{webPage.PageTitle}}</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5 col-12">
            <div class="web-page-content clang-{{lang}}" *ngIf="webPage.PageContent" [innerHTML]="htmlContent"></div>           
            <div class="bullets-section">
                <div class="bullets-option" *ngFor="let item of section">
                    <div class="bullet-icon m-r-10">
                        <img src="{{utils.getImage(item.SectionIcon, 72, 72)}}" alt="GoCars" />
                    </div>
                    <div class="bullet-info">
                        <div class="bullet-title">{{item['SectionTitle_' + lang]}}</div>
                        <div class="bullet-subtitle">{{item['SectionSubTitle_' + lang]}}</div>   
                    </div>                 
                </div>
            </div>
        </div>
        <div class="col-md-7 col-12">
            <div class="order-form">
                <div class="gc-field">
                    <label class="gc-label">{{utils.getString('no_of_bookings')}}</label>
                    <div class="gc-input">
                      <span class="gc-input-group m-r-10">
                        <i class="fal fa-calendar-check"></i>
                      </span>
                      <select [(ngModel)]="order.NoOfBookings">
                        <option [value]="1">{{utils.getString('one_visit')}} ({{fees.ExpertFees1Apt}} {{utils.getString('cur')}})</option>
                        <option [value]="3">{{utils.getString('three_visits')}} ({{fees.ExpertFees3Apts}} {{utils.getString('cur')}})</option>
                      </select>           
                    </div>
                </div>   
                <div class="row">
                    <div class="col-6">
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('first_visit')}}</label>
                            <div class="gc-input">
                              <input matInput [matDatepicker]="picker1" (click)="picker1.open()" 
                              [(ngModel)]="order.b1_date" readonly="readonly" [min]="minDate">                      
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                               <mat-datepicker #picker1></mat-datepicker>         
                            </div>
                        </div>  
                    </div>
                    <div class="col-6">
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('timeslot')}}</label>                            
                            <div class="gc-input">
                                <span class="gc-input-group m-r-10">
                                    <i class="fal fa-clock"></i>
                                </span>
                                <select [(ngModel)]="order.b1_time">
                                    <option value=""></option>
                                    <option value="{{time.value}}" *ngFor="let time of timeslots">{{time.display}}</option>
                                </select>       
                            </div>
                        </div>  
                    </div>
                </div>
                <div class="row" *ngIf="order.NoOfBookings != 1">
                    <div class="col-6">
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('second_visit')}}</label>
                            <div class="gc-input">
                              <input matInput [matDatepicker]="picker2" (click)="picker2.open()" 
                              [(ngModel)]="order.b2_date" readonly="readonly" [min]="minDate">                      
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                               <mat-datepicker #picker2></mat-datepicker>         
                            </div>
                        </div>  
                    </div>
                    <div class="col-6">
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('timeslot')}}</label>                            
                            <div class="gc-input">
                                <span class="gc-input-group m-r-10">
                                    <i class="fal fa-clock"></i>
                                </span>
                                <select [(ngModel)]="order.b2_time">
                                    <option value=""></option>
                                    <option value="{{time.value}}" *ngFor="let time of timeslots">{{time.display}}</option>
                                </select>       
                            </div>
                        </div>  
                    </div>
                </div>
                <div class="row" *ngIf="order.NoOfBookings != 1">
                    <div class="col-6">
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('third_visit')}}</label>
                            <div class="gc-input">
                              <input matInput [matDatepicker]="picker3" (click)="picker3.open()" 
                              [(ngModel)]="order.b3_date" readonly="readonly" [min]="minDate">                      
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                               <mat-datepicker #picker3></mat-datepicker>         
                            </div>
                        </div>  
                    </div>
                    <div class="col-6">
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('timeslot')}}</label>                            
                            <div class="gc-input">
                                <span class="gc-input-group m-r-10">
                                    <i class="fal fa-clock"></i>
                                </span>
                                <select [(ngModel)]="order.b3_time">
                                    <option value=""></option>
                                    <option value="{{time.value}}" *ngFor="let time of timeslots">{{time.display}}</option>
                                </select>       
                            </div>
                        </div>  
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-12">
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('city')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="fal fa-city"></i>
                              </span>
                                <ng-multiselect-dropdown [placeholder]="utils.getString('please_select')" [settings]="cityOptions"
                                [data]="cities" [(ngModel)]="order.City" (onSelect)="selectCity($event)"
                                (onDeSelect)="selectCity($event)">
                                </ng-multiselect-dropdown>              
                            </div>
                        </div>   
                    </div>
                    <div class="col-lg-6 col-12">
                        <div class="gc-field">
                            <label class="gc-label">{{utils.getString('area')}}</label>
                            <div class="gc-input">
                              <span class="gc-input-group m-r-10">
                                <i class="fal fa-map-marked"></i>
                              </span>
                                <ng-multiselect-dropdown [placeholder]="utils.getString('please_select')" [settings]="areaOptions"
                                [data]="areas" [(ngModel)]="order.Area" (onSelect)="selectArea($event)"
                                (onDeSelect)="selectArea($event)">
                                </ng-multiselect-dropdown>              
                            </div>
                        </div>
                    </div>
                </div>                                  
                <button aria-label="Hire an Expert" class="save-action" (click)="submitOrder()" [disabled]="isLoading">{{utils.getString('pay_now')}}</button>
            </div>
        </div>
    </div>
</div>