import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Utils } from '../../../classes/utils';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-notificationss',
  templateUrl: './notificationss.component.html',
  styleUrls: ['./notificationss.component.less']
})
export class NotificationssComponent implements OnInit {

  lang:string = '';
  isBrowser:boolean;
  isLoggedIn:boolean;
  notifications = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private userService: UserService) {
      this.isBrowser = this.utils.isBrowser();
      this.lang = this.utils.getLanguage();   
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;
        if(!_isLoggedIn){
          this.lang = this.utils.getLanguage();     
          this.router.navigate([`/${this.lang}/home`]);
        }
      });
   }

  ngOnInit(): void {
    if(this.isBrowser) this.getData();
  }

  getData(){
    this.userService.getNotifications().then(resp => {
      this.notifications = resp;
    });
  }

  open(item){
    this.userService.readNotification(item.PushID);
    if(this.isBrowser) window['getNotificationsCount']();
    switch(item.screen){
      case 'spare-part-orders':        
        this.router.navigate([`/${this.lang}/account/spare-parts-orders`], {queryParams: {id: item.id}});
        break;
      case 'car':        
        this.router.navigate([`/${this.lang}/used-car/${item.id}`]);
        break;
      case 'rejected':        
        this.router.navigate([`/${this.lang}/account/my-cars`]);
        break;      
      default: 
        break;
    };
  }
}
