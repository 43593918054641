import { Component, OnInit } from '@angular/core';
import { Utils } from '../../classes/utils';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Swiper, EffectFade, Autoplay, Navigation, Pagination, Grid } from 'swiper';
import { config } from '../../config';

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.less']
})
export class QuickSearchComponent implements OnInit {

  lang:string = '';
  isBrowser:boolean;
  isLoggedIn:boolean;
  makes = [];
  models = [];
  bodyTypes = [];
  cities = [];
  prices = [];
  years = [];
  tab:string = 'makes';
  siteCountry:string = '';

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private service: SharedService) {
      this.siteCountry = config.siteCountry;
      this.isBrowser = this.utils.isBrowser();
      this.utils.isLoggedIn().subscribe(_isLoggedIn => {
        this.isLoggedIn = _isLoggedIn;
      });
      this.router.events.subscribe((url: NavigationEnd) => {
        if (url && url.urlAfterRedirects) {
          this.lang = this.utils.getLanguage();       
        }
        return;
      });     
   }

  ngOnInit(): void {
    this.service.getHomeFilters().then(resp => {
      this.makes = resp.data.makes;
      this.models = resp.data.models;
      this.bodyTypes = resp.data.bodyTypes;
      this.cities = resp.data.cities;
      
      this.prices.push({min: 0, max: 15, value: 15000, display: (this.lang == 'en' ? 'Below 15k' : 'أقل من 15k')});
      this.prices.push({min: 15, max: 25,value: 25000, display: '15k - 25k'});
      this.prices.push({min: 25, max: 35,value: 35000, display: '25k - 35k'});
      this.prices.push({min: 35, max: 45,value: 45000, display: '35k - 45k'});
      this.prices.push({min: 45, max: 55,value: 55000, display: '45k - 55k'});
      this.prices.push({min: 55, max: 65,value: 65000, display: '55k - 65k'});
      this.prices.push({min: 65, max: 75,value: 75000, display: '65k - 75k'});
      this.prices.push({min: 75, max: 85,value: 85000, display: '75k - 85k'});
      this.prices.push({min: 85, max: 100,value: 100000, display: '85k - 100k'});
      this.prices.push({min: 100, max: 150,value: 150000, display: '100k - 150k'});
      this.prices.push({min: 150, max: 200,value: 200000, display: '150k - 200k'});
      this.prices.push({min: 200, max: 200,value: 250000, display: '200k+'});

      let endYear:number = new Date().getFullYear() - 1;
      let startYear:number = endYear - 7;
      this.years.push({value: (startYear - 1), display: (this.lang == 'en' ? 'Before ' + (startYear - 1) : 'قبل ' + (startYear - 1)), type: 'below'});
      for(var i = startYear; i <= endYear; i++){
        this.years.push({value: i, display: i, type: 'exact'});
      }

      this.viewTab('makes');
    });
  }

  viewTab(tab){
    this.tab = tab;
    if(this.isBrowser){
      if(tab == 'makes'){
        setTimeout(() => {
          this.makesSwiper();
          setTimeout(() => {
            this.makesSwiper();
          }, 100);
        }, 100);   
      } else if(tab == 'models'){
        setTimeout(() => {
          this.modelsSwiper();
          setTimeout(() => {
            this.modelsSwiper();
          }, 100);
        }, 100);   
      } else if(tab == 'cities'){
        setTimeout(() => {
          this.citiesSwiper();
          setTimeout(() => {
            this.citiesSwiper();
          }, 100);
        }, 100);   
      } else if(tab == 'prices'){
        setTimeout(() => {
          this.pricesSwiper();
          setTimeout(() => {
            this.pricesSwiper();
          }, 100);
        }, 100);   
      } else if(tab == 'years'){
        setTimeout(() => {
          this.yearsSwiper();
          setTimeout(() => {
            this.yearsSwiper();
          }, 100);
        }, 100);   
      } else if(tab == 'bodyTypes'){
        setTimeout(() => {
          this.bodyTypesSwiper();
          setTimeout(() => {
            this.bodyTypesSwiper();
          }, 100);
        }, 100);   
      }

      let self = this;
      window.addEventListener('resize', function () {
        setTimeout(() => {
          if(tab == 'makes') self.makesSwiper();
          if(tab == 'models') self.modelsSwiper();
          if(tab == 'cities') self.citiesSwiper();
          if(tab == 'prices') self.pricesSwiper();
          if(tab == 'years') self.yearsSwiper();
          if(tab == 'bodyTypes') self.bodyTypesSwiper();
        }, 100);      
      }); 
    }    
  }

  _makesSwiper:any;
  makesSwiper(){    
    Swiper.use([Grid]);
    Swiper.use([Pagination]);
    if(this._makesSwiper){
      try{
        this._makesSwiper.destroy();
      } catch {}
    }
    this._makesSwiper = new Swiper('.makes-swiper', {
        spaceBetween: 40,
        slidesPerView: 4,
        slidesPerGroup: 4,
        grid: {
          rows: 2,
        },  
        breakpoints: {
          320: {
            slidesPerView: 2, 
            slidesPerGroup: 2,           
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          1024: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
        },
        pagination: {
          el: '.makes-pagination',
          type: 'bullets',
          clickable: true
        },
    });      
  }

  _modelsSwiper:any;
  modelsSwiper(){    
    Swiper.use([Grid]);
    Swiper.use([Pagination]);
    if(this._modelsSwiper){
      try{
        this._modelsSwiper.destroy();
      } catch {}
    }
    this._modelsSwiper = new Swiper('.models-swiper', {
      spaceBetween: 40,
      slidesPerView: 4,
      slidesPerGroup: 4,
      grid: {
        rows: 3,
      },  
      breakpoints: {
        320: {
          slidesPerView: 2, 
          slidesPerGroup: 2,           
        },
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        1024: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
      },
      pagination: {
        el: '.models-pagination',
        type: 'bullets',
        clickable: true
      },
    });      
  }

  _citiesSwiper:any;
  citiesSwiper(){    
    Swiper.use([Grid]);
    Swiper.use([Pagination]);
    if(this._citiesSwiper){
      try{
        this._citiesSwiper.destroy();
      } catch {}
    }
    this._citiesSwiper = new Swiper('.cities-swiper', {
      spaceBetween: 40,
      slidesPerView: 4,
      slidesPerGroup: 4,
      grid: {
        rows: 3,
      },  
      breakpoints: {
        320: {
          slidesPerView: 2,  
          slidesPerGroup: 2,          
        },
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        1024: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
      },
      pagination: {
        el: '.cities-pagination',
        type: 'bullets',
        clickable: true
      },
    });      
  }

  _pricesSwiper:any;
  pricesSwiper(){    
    Swiper.use([Grid]);
    Swiper.use([Pagination]);
    if(this._pricesSwiper){
      try{
        this._pricesSwiper.destroy();
      } catch {}
    }
    this._pricesSwiper = new Swiper('.prices-swiper', {
      spaceBetween: 40,
      slidesPerView: 4,
      slidesPerGroup: 4,
      grid: {
        rows: 3,
      },  
      breakpoints: {
        320: {
          slidesPerView: 2,  
          slidesPerGroup: 2,          
        },
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        1024: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
      },
      pagination: {
        el: '.prices-pagination',
        type: 'bullets',
        clickable: true
      },
    });      
  }

  _yearsSwiper:any;
  yearsSwiper(){    
    Swiper.use([Grid]);
    Swiper.use([Pagination]);
    if(this._yearsSwiper){
      try{
        this._yearsSwiper.destroy();
      } catch {}
    }
    this._yearsSwiper = new Swiper('.years-swiper', {
      spaceBetween: 40,
      slidesPerView: 4,
      slidesPerGroup: 4,
      grid: {
        rows: 3,
      },  
      breakpoints: {
        320: {
          slidesPerView: 2,   
          slidesPerGroup: 2,         
        },
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        1024: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
      },
      pagination: {
        el: '.years-pagination',
        type: 'bullets',
        clickable: true
      },
    });      
  }

  _bodyTypesSwiper:any;
  bodyTypesSwiper(){    
    Swiper.use([Grid]);
    Swiper.use([Pagination]);
    if(this._bodyTypesSwiper){
      try{
        this._bodyTypesSwiper.destroy();
      } catch {}
    }
    this._bodyTypesSwiper = new Swiper('.bodyTypes-swiper', {
        spaceBetween: 40,
        slidesPerView: 3,
        slidesPerGroup: 3,
        grid: {
          rows: 3,
        },  
        breakpoints: {
          320: {
            slidesPerView: 2,   
            slidesPerGroup: 2,         
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
        pagination: {
          el: '.bodyTypes-pagination',
          type: 'bullets',
          clickable: true
        },
    });      
  }

  makeLink(obj){
    return `${this.utils.getStrUrl(obj.MakeName_en)}-for-sale-in-${this.siteCountry}`;
  }

  modelLink(obj){
    return `${this.utils.getStrUrl(obj.mmname_en)}-for-sale-in-${this.siteCountry}`;
  }

  cityLink(obj){
    return `cars-for-sale-in-${this.utils.getStrUrl(obj.CityName_en)}`;
  }

  bodyTypeLink(obj){
    return `${this.utils.getStrUrl(obj.LookItem_en)}-for-sale-in-${this.siteCountry}`;
  }

  yearLink(obj){
    return `${obj.value}-cars-for-sale-in-${this.siteCountry}`;
  }

  priceLink(obj){
    return `below-${obj.value}-for-sale-in-${this.siteCountry}`;
  }
}
