<div class="preparing-car" *ngIf="!accountType">
    <i class="far fa-circle-notch fa-spin"></i>
</div>
<div class="container" *ngIf="webPage.PageTitle && accountType">
    <div class="row">
        <div class="col-12">
            <h1 class="web-page-title">{{webPage.PageTitle}}</h1>
        </div>
    </div>
    <div class="row" *ngIf="!isLoading">
        <div class="col-lg-5 col-12">
            <div class="web-page-content clang-{{lang}}" *ngIf="webPage.PageContent" [innerHTML]="htmlContent"></div>           
            <div class="bullets-section">
                <div class="bullets-option" *ngFor="let item of section; let i = index">
                    <div class="bullet-icon m-r-10">
                        <img src="{{utils.getImage(item.SectionIcon, 72, 72)}}" alt="GoCars" />
                    </div>
                    <div class="bullet-info">
                        <div class="bullet-title">{{item['SectionTitle_' + lang]}}</div>
                        <div class="bullet-subtitle">{{item['SectionSubTitle_' + lang]}}</div>   
                    </div>                 
                </div>
            </div>
        </div>  
        <div class="col-lg-7 col-12" *ngIf="fees">
            <div class="selling-options-title" *ngIf="lang == 'en'">How do you want to sell your car?</div>
            <div class="selling-options-title" *ngIf="lang == 'ar'">كيف تريد بيع سيارتك؟</div>
            <div class="selling-options">
                <div class="selling-option m-r-15" (click)="sellBy = 1" [class.active]="sellBy == 1">
                    <div class="selling-title">
                        <span *ngIf="lang == 'en'">Sell my car for me</span>
                        <span *ngIf="lang == 'ar'">قم ببيع سيارتي</span>
                    </div>
                    <div class="selling-subtitle">
                        <span *ngIf="lang == 'en'">GoCars will inspect your car and help you sell it for more</span>
                        <span *ngIf="lang == 'ar'">سيقوم GoCars بفحص سيارتك ومساعدتك في بيعها بأكثر</span>
                    </div>
                    <div class="selling-expiry">
                        {{utils.getString('ad_no_expire')}}
                    </div>
                    <div class="selling-price">{{fees.GoCarsSell}} {{utils.getString('cur')}}</div>
                    <div class="selling-hint">
                        <span *ngIf="lang == 'en'">{{fees.GoCarsSellPerc}}% of the selling price, max: {{fees.GoCarsSellPercMax}} {{utils.getString('cur')}}</span>
                        <span *ngIf="lang == 'ar'">{{fees.GoCarsSellPerc}}% عمولة من سعر البيع, بحد أقصى: {{fees.GoCarsSellPercMax}} {{utils.getString('cur')}}</span>
                    </div>
                </div>
                <div class="selling-option" (click)="sellBy = 2" [class.active]="sellBy == 2">
                    <div class="selling-title">
                        <span *ngIf="lang == 'en'">I want to sell myself</span>
                        <span *ngIf="lang == 'ar'">أريد البيع بنفسي</span>
                    </div>
                    <div class="selling-subtitle">
                        <span *ngIf="lang == 'en'">Set your price, get offers and sell your car</span>
                        <span *ngIf="lang == 'ar'">حدد السعر, أحصل على العروض وبيع سيارتك</span>
                    </div>
                    <div class="selling-expiry">
                        {{utils.getString('ad_will_expire')}}
                    </div>
                    <div class="selling-price">{{fees.UserSellAlone}} {{utils.getString('cur')}}</div>
                    <div class="selling-hint">
                        <span *ngIf="lang == 'en'">No commission or hidden fees</span>
                        <span *ngIf="lang == 'ar'">لا توجد عمولة أو رسوم خفية</span>
                    </div>
                </div>
            </div>
            <button class="save-action" (click)="proceed()" [disabled]="isLoading">{{utils.getString('continue')}}</button>
        </div>     
    </div>
</div>